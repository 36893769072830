import React, {useEffect, useState} from "react"
import {Whisper, Tooltip, Popover, List} from "rsuite"
import styled, {css} from "styled-components"
import TableClientSort from "../../../components/Table/TableClientSort"
import {formatDateApi, compareTwoArrays} from "../../../utils"
import {DEFAULT_PAYMENT_MISC_STATUS_UNEXPORTED, DEFAULT_PAYMENT_MISC_STATUS_EXPORTED, DESC_SORT} from "../../../const";


export default ({
    data = [],
    service,
    loading = false,
    filter = {},
    height = null,
    selectedItems,
    setSelected,
    currencyList,
    clearSelectedData,
    setClearSelectedData,
    ...props
}) => {

    var tableRef = null;

    const [miscPaymentList, onChangeMiscPaymentList] = useState([]);

    const unexportedStatus = DEFAULT_PAYMENT_MISC_STATUS_UNEXPORTED;
    const exportedStatus = DEFAULT_PAYMENT_MISC_STATUS_EXPORTED;

    const uncheckedExportedData = data.map((item) => {
        const isExported = item.status !== unexportedStatus;
        return {
            ...item,
            checkPrevent: isExported,
            cantChoosenByAll: isExported
        }
    });

    useEffect(() => {
        if (!compareTwoArrays(data, miscPaymentList)) {
            onChangeMiscPaymentList(uncheckedExportedData);
            setClearSelectedData(true);
        }
    }, [data]);

    //commis_percent / conv_cur_key
    useEffect(() => {
        const selectedData = data.filter((item) => selectedItems.list.includes(item.id));
        const selectedOnlyExportedData = selectedData.filter((item) => item.status === exportedStatus);
        // const selectedDataHasConflicts = selectedData
        if (selectedOnlyExportedData.length) {
            const disabledExportedData = data.map((item) => {
                const first = selectedOnlyExportedData[0];
                const isExported = item.status !== unexportedStatus;
                const hasDifferentCommisAndCurrency = (first.commis_percent !== item.commis_percent 
                    || first.conv_cur_key !== item.conv_cur_key)
                    && isExported;

                return {
                    ...item,
                    checkPrevent: hasDifferentCommisAndCurrency,
                    cantChoosenByAll: isExported,
                    disabled: hasDifferentCommisAndCurrency
                }
            });
            onChangeMiscPaymentList(disabledExportedData);
        } else {
            onChangeMiscPaymentList(uncheckedExportedData);
        }
    }, [selectedItems]);

    useEffect(() => {
        if (clearSelectedData) {
            setClearSelectedData(false);
        }
    }, [clearSelectedData]);

    const statusLabels = ["Pending approval", "Canceled", "Approved", "Rejected", "Completed"];

    const columns = [
        {
            label: "Name",
            dataKey: "account_name",
            value: ((rowData) => (
                <span className="table-centered__cell">
                    {rowData["account_name"]} / {rowData["account_manager_name"] || "no manager"}
                </span>
            )),
            sortable: true,
            flexGrow: 2,
            minWidth: 280,
        },
        {
            label: "Created at",
            dataKey: "date",
            value: ({date}) => (
                <span className="table-centered__cell">
                    {formatDateApi(date || '')}
                </span>
            ),
            sortable: true,
            flexGrow: 1
        },
        {
            label: "Ready for payment",
            name: "available_for_payment",
            dataKey: "available_for_payment",
            value: ({available_for_payment, currency_name}) => (
                <span className="table-centered__cell">
                    {available_for_payment}{currency_name ? ' ' + currency_name : ''}
                </span>
            ),
            sortable: true,
            flexGrow: 1
        },
        {
            label: "Sum dialer",
            dataKey: "amount",
            value: ({amount, currency_name}) => (
                <span className="table-centered__cell">
                    {amount}{currency_name ? ' ' + currency_name : ''}
                </span>
            ),
            sortable: true,
            flexGrow: 1,
            minWidth: 100
        },
        {
            label: "Sum payment",
            dataKey: "amount_conv_commis",
            value: ({amount_conv_commis, conv_currency_name}) => (
                <span className="table-centered__cell">
                    {amount_conv_commis}{conv_currency_name ? ' ' + conv_currency_name : ''}
                </span>
            ),
            sortable: true,
            flexGrow: 1,
            minWidth: 120
        },
        {
            label: "Details",
            dataKey: "details",
            value: (rowData) => {
                return (
                    <Whisper
                        enterable
                        placement="auto"
                        speaker={
                            <Tooltip>{rowData["payment_detail_info"]}</Tooltip>
                        }
                    >
                        <span className="table-centered__cell">{rowData["payment_detail_info"]}</span>
                    </Whisper>
                )
            },
            sortable: true,
            flexGrow: 2,
            minWidth: 260
        },
        {
            label: "Status",
            dataKey: "status",
            value: ({status, conversion_list}) => {
                const lastExportedData = conversion_list && conversion_list.length ? conversion_list[conversion_list.length - 1] : {}; 
                const notExported = status !== 2 || !conversion_list;
                const statusName = statusLabels[status];
                const sortedConversionList = conversion_list && conversion_list.length ?
                    conversion_list.map((conversion) => {
                        return {
                            ...conversion,
                            exported_at: new Date(conversion.exported_at),
                            date: formatDateApi(conversion.exported_at)
                        }
                    }).sort((a, b) => {
                        return b.exported_at.getTime() - a.exported_at.getTime();
                    }) : [];

                const fullExportedList = <WhisperList direction="column" size="sm">
                    {sortedConversionList.map((conversion, index) => {
                        return <List.Item key={index}>Exported by: {conversion.account_user_name}<br/>at: {conversion.date}</List.Item>
                    })}
                </WhisperList>;

                return (<Whisper
                    enterable
                    placement="bottomStart"
                    speaker={
                        <StyledPopover>{fullExportedList}</StyledPopover>
                    }
                >
                    {notExported 
                    ? <span className="table-centered__cell">{statusName}</span>
                    : <div className="table-three-staged__cell">
                        <div className="table-threestaged__cell-first">{statusName}</div>
                        <div className="table-three-staged__cell-second">Exported by: {lastExportedData.account_user_name}</div>
                        <div className="table-three-staged__cell-third">at: {formatDateApi(lastExportedData.exported_at || '')}</div>
                    </div>}
                </Whisper>);
            },
            sortable: true,
            flexGrow: 1,
            width: 160
        }
    ];

    return (
        <StyledTableClientSort
            ref={ref => tableRef = ref}
            shouldUpdateScroll={true}
            extraHeight={height || 700}
            data={miscPaymentList}
            loading={loading}
            virtualized={true}
            rowHeight={54}
            defaultSortColumn="date"
            defaultSortType={DESC_SORT}
            {...{
                columns,
                ...props
            }}
            isselected
            partialSelectedAll
            cantChoosenByAllLabel="Only exportable"
            clearSelected={clearSelectedData}
            setSelected={(selected) => {
                if (selected && Object.keys(selected).length) {
                    const selectedList = selected.list && miscPaymentList.filter(item => selected.list.includes(item.id));
                    const isSelectedAll = selectedList.length === miscPaymentList.length && selectedList.length > 1;
                    
                    selected.all = isSelectedAll;
                    
                    if (!compareTwoArrays(selectedItems.list, selected.list)) {
                        setSelected(selected);
                    }
                }
            }}
        />
    )
};


const StyledTableClientSort = styled(TableClientSort)`
    && {
        .table-centered__cell {
            line-height: 54px;
        }

        .rs-table-cell-content {
            padding: 0 0 0 10px !important;
        }
        
        .rs-table-row-header .rs-table-cell-content {
            line-height: 40px;
        }

        .table-three-staged__cell {
            // line-height: 1.42857143;
            
            .table-three-staged__cell-third {
                font-size: 12px;
                opacity: 0.75;
            }
        

            .table-three-staged__cell-second {
                font-size: 12px;
                opacity: 0.75;
            }
            
            .table-three-staged__cell-single {
                padding-top: 7px;
            }
        }
    }
`;

const WhisperList = styled(List)`
    && {
        box-shadow: none;
        border-radius: 3px;
    }

    && .rs-list-item {
        border: none;
        box-shadow: none;
    }

    && .rs-list-item-sm {
        padding: 0 8px 3px 8px;
    }

    && .rs-list-item:nth-child(even) {
        background-color: var(--color-bg);
    }
`;

const StyledPopover = styled(Popover)`
    && {
        padding: 0;
        margin-top: -1px !important;
    }

    && .rs-popover-arrow {
        padding-left: 10px;
    }
`;