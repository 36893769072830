import React, {useEffect, useMemo, useState, useRef} from "react";
import {
    ACCOUNT_MANAGERS_GET_INFORMATORS_API,
    SMS_SUPPLIER_GAN_GET_LIST_METHOD,
    INCORPORATED_NUMBER_LIST_API,
    INCORPORATED_GROUP_LIST_API,
    SMS_SUPPLIER_GAN_GROUP_GET_LIST_METHOD,
    SMS_ALLOCATION_GAN_MASS_BY_MANAGER_API,
    SMS_SUPPLIER_GAN_GROUP_UPLOAD_CSV_EXISTS_METHOD
} from "const/apiMethods"
import {Icon} from "rsuite";
import {getFileResponse} from "api/loginRoutes";
import {servicePick, downloadDataAsFile} from "utils";
import {checkPermissionsFor} from "store/storeHelpers";

import {ButtonPrimary} from "components/base/BaseButton";
import {FlexGrid, FlexGridItem} from "components/base/FlexGrid";

import { DEFAULT_PER_PAGE_SMALL,  } from "const";

import GroupTable from "./GroupTable";
import GroupFilter from "./GroupFilter";
import ModalEditGroup from "./ModalEditGroup";
import ModalRemoveGroup from "./ModalRemoveGroup";
import ModalUploadResult from "./ModalUploadResult";
import ModalUploadNumbers from "./ModalUploadNumbers";
import Allocate from "./Allocate";
import IncorporatedNumbersGroupDownload from "./IncorporatedNumbersGroupDownload";
import styled from "styled-components";


export default (
    {
        data,
        loading,
        filter,
        rowKey,
        trunks,
        service,
        ivrList,
        suppliers,
        setFilter,
        isRatemod,
        isSupervisorSms,
        serviceIsCorrect,
        numbersFilter,
        currentGroup,
        permissions,
        supplierList,
        supplierListFiltered,
        destinationList,
        subdestinationList,
        paymentTermsList,
        currencyList,
        getTrunks,
        getSuppliers,
        getSupplierGroups,
        getSupplierNumbers,
        getIncorporatedNumbersList,
        getIncorporatedNumbersGroups,
        getIncorporatedNumbersSuppliers,
        getAccountManagersInformators,
        clearIncorporatedNumberList,
        setCurrentGroup,
        showReasonHandler,
        getGanSuppliersCommonList,
    }
) => {

    const groupTableRef = useRef(null);
    const [loadableGroups, setLoadableGroups] = useState([]);

    const [modalRemoveShow, setModalRemoveShow] = useState(false);

    const [modalUploadShow, setModalUploadShow] = useState(false);
    const [modalUploadValue, setModalUploadValue] = useState({test_number_option: "first"});

    const [modalEditShow, setModalEditShow] = useState(false);
    const [modalEditValue, setModalEditValue] = useState({});

    const [modalResultShow, setModalResultShow] = useState(false);
    const [modalResultData, setModalResultData] = useState(null);

    const [selectedGroups, setSelectedGroups] = useState([]);

    const voiceService = service;

    useEffect(() => {
        if (serviceIsCorrect) {
            getGanSuppliersCommonList(service);
        }

        if (!voiceService) {
            getTrunks();
        }
    }, [service]);


    useEffect(() => {
        if (serviceIsCorrect) {
            if (service) {
                getIncorporatedNumbersGroups(filter);
            } else {
                const currentFilter = Object.assign({}, filter);
                const supplierId = currentFilter?.supplier_id || null;
                delete currentFilter.supplier_id;
                getSupplierGroups(null, supplierId, currentFilter);
            }
        }
    }, [filter, service, serviceIsCorrect]);


    useEffect(() => {
        groupTableRef.current.clear();
    }, [filter]);


    useEffect(() => {
        if (!currentGroup && data?.length) {
            const firstElement = data[0];

            setCurrentGroup(firstElement[rowKey]);
            return;
        }
        if (currentGroup && !data.find(item => rowKey in item && item[rowKey] === currentGroup)) {
            setCurrentGroup(null);
            clearIncorporatedNumberList();
            return;
        }
    }, [data, currentGroup]);


    const handleDownloadClick = (id) => {
        const method = servicePick(service, INCORPORATED_NUMBER_LIST_API, SMS_SUPPLIER_GAN_GET_LIST_METHOD);

        const downloadParams = servicePick(service, {
            ing_key: id
        }, {
            target: {
                "sms.supplier_gan_group_id": id
            }
        });

        setLoadableGroups(prev => [...prev, id]);
        getFileResponse(method, downloadParams)
            .then(r => {
                downloadDataAsFile(r, "global_access_numbers.csv");
            })
            .finally(() => {
                setLoadableGroups(prev => prev.filter(item => item !== id));
            })
    };


    const handleUploadClick = (data) => {
        if (data) {
            setModalUploadValue({
                ...data,
                rrc_key: undefined,
            });
        }
        setModalUploadShow(true);
    };


    const handleEditClick = (data) => {
        if (data) {
            setModalEditValue(data);
        }
        setModalEditShow(true);
    };


    const handleSuccess = () => {
        groupTableRef.current.clear();

        if (service) {
            getIncorporatedNumbersGroups(filter);
            getIncorporatedNumbersList(currentGroup, numbersFilter);
        } else {
            const currentFilter = Object.assign({}, filter);
            const supplierId = currentFilter?.supplier_id || null;
            delete currentFilter.supplier_id;
            getSupplierGroups(null, supplierId, currentFilter);
            getSupplierNumbers(currentGroup, numbersFilter, 1, DEFAULT_PER_PAGE_SMALL, undefined, service);
        }
    };


    const handleSelectItems = (selected) => {
        setSelectedGroups(selected);
    }


    const currentGroupData = useMemo(() => (
        data.find(item => rowKey in item && item[rowKey] === currentGroup)
    ), [data, currentGroup]);

    const extraParams = {
        [servicePick(service, "ing_key_list", "gan_group_key_list")]: selectedGroups.list,
    };

    const uploadMethod = servicePick(service, INCORPORATED_GROUP_LIST_API, SMS_SUPPLIER_GAN_GROUP_GET_LIST_METHOD);
    const uploadAllowed = permissions.includes(uploadMethod);
    const defaultUploadValue = service ? {rrc_key: 3} : {};

    const showAllocateNumbersButton = !isRatemod && (!isSupervisorSms || isSupervisorSms && checkPermissionsFor(SMS_ALLOCATION_GAN_MASS_BY_MANAGER_API));
    const showUploadButton = !isSupervisorSms && uploadAllowed || isSupervisorSms && checkPermissionsFor(SMS_SUPPLIER_GAN_GROUP_UPLOAD_CSV_EXISTS_METHOD);

    return (
        <>

            <FlexGrid align="middle">

                <StyledFlexGridItem>
                    <GroupFilter
                        {...{
                            filter,
                            service,
                            destinationList,
                            subdestinationList
                        }}
                        supplierList={supplierListFiltered}
                        onChangeFilter={setFilter}
                    />
                </StyledFlexGridItem>

                <FlexGridItem style={{marginLeft: "auto"}}>
                    <IncorporatedNumbersGroupDownload
                        service={service}
                    />

                    {showAllocateNumbersButton &&
                        <Allocate
                            buttonText={"Allocate numbers"}
                            disabled={!selectedGroups?.list || !selectedGroups.list.length}
                            style={{marginRight: servicePick(service, 10, 0)}}
                            extraParams={extraParams}
                            showReasonHandler={showReasonHandler}
                            onSuccess={handleSuccess}
                            groupList={data}
                            service={service}
                            rowKey={rowKey}
                        />
                    }
                    
                    {showUploadButton && 
                        <ButtonPrimary
                            style={{marginLeft: "10px"}}
                            onClick={() => {
                                setModalUploadShow(true);
                                setModalUploadValue(defaultUploadValue);
                            }}
                        >
                            <Icon icon="upload"/> <span>Upload numbers</span>
                        </ButtonPrimary>
                    }
                </FlexGridItem>

            </FlexGrid>

            <GroupTable 
                {...{
                    data,
                    rowKey,
                    service, 
                    loading, 
                    currentGroup,
                    paymentTermsList,
                    showUploadButton,
                    isSupervisorSms
                }}
                isselected={!isRatemod}
                loadableGroups={loadableGroups}
                ref={groupTableRef}
                row_key={rowKey}
                rowClassName={(rowData) => (rowData && rowData[rowKey] === currentGroup)
                    ? "row-active"
                    : "row-table"
                }

                onRowClick={(id) => {
                    if (!id) {
                        return;
                    }
                    setCurrentGroup(id);
                }}
                onSelectItems={handleSelectItems}
                onUploadClick={(data) => handleUploadClick(data)}
                onRemoveClick={() => setModalRemoveShow(true)}
                onDownloadClick={(id) => handleDownloadClick(id)}
                onEditClick={(data) => handleEditClick(data)}
            />

            <ModalEditGroup
                {...{
                    service,
                    ivrList,
                    isRatemod,
                    paymentTermsList,
                    currencyList,
                    currentGroup,
                    currentGroupData,
                    getAccountManagersInformators,
                }}
                show={modalEditShow}
                formValue={modalEditValue}
                setFormValue={setModalEditValue}
                onSuccess={handleSuccess}
                onClose={() => {
                    setModalEditValue({});
                    setModalEditShow(false);
                }}
            />

            {uploadAllowed && 
                <ModalUploadNumbers
                    {...{
                        rowKey,
                        service,
                        trunks,
                        suppliers,
                        getSuppliers,
                        supplierList,
                        subdestinationList,
                        currentGroupData,
                        showReasonHandler,
                        getIncorporatedNumbersSuppliers,
                    }}
                    show={modalUploadShow}
                    formValue={modalUploadValue}
                    groupList={data}
                    setFormValue={setModalUploadValue}
                    onSuccess={(r, isByFile) => {
                        setModalResultData({
                            ...r,
                            isByFile: isByFile || false
                        });
                        setModalResultShow(true);

                        if (service) {
                            getIncorporatedNumbersGroups(filter);
                        } else {
                            const currentFilter = Object.assign({}, filter);
                            const supplierId = currentFilter?.supplier_id || null;
                            delete currentFilter.supplier_id;
                            getSupplierGroups(null, supplierId, currentFilter);
                        }

                        checkPermissionsFor(ACCOUNT_MANAGERS_GET_INFORMATORS_API) && getAccountManagersInformators();
                        
                        if (modalUploadValue[rowKey]) {
                            if (service) {
                                getIncorporatedNumbersList(currentGroup, numbersFilter);
                            } else {
                                getSupplierNumbers(currentGroup, numbersFilter, 1, DEFAULT_PER_PAGE_SMALL, undefined, service);
                            }
                            
                        }
                    }}
                    onClose={() => {
                        setModalUploadValue({test_number_option: "first"});
                        setModalUploadShow(false);
                    }}
                />
            }

            <ModalRemoveGroup
                id={currentGroup}
                service={service}
                show={modalRemoveShow}
                onSuccess={() => {
                    if (service) {
                        getIncorporatedNumbersGroups(filter);
                    } else {
                        const currentFilter = Object.assign({}, filter);
                        const supplierId = currentFilter?.supplier_id || null;
                        delete currentFilter.supplier_id;
                        getSupplierGroups(null, supplierId, currentFilter);
                    }

                    checkPermissionsFor(ACCOUNT_MANAGERS_GET_INFORMATORS_API) && getAccountManagersInformators();
                }}
                onClose={() => setModalRemoveShow(false)}
                {...{
                    showReasonHandler,
                }}
            />

            <ModalUploadResult
                show={modalResultShow}
                data={modalResultData}
                onClose={() => setModalResultShow(false)}
                onExited={() => setModalResultData(null)}
            />
        </>
    )
};


const StyledFlexGridItem = styled(FlexGridItem)`
    && {
        width: 60%;
    }
`;