import React, {useContext, useEffect, useRef, useState, useMemo} from "react";
import {Form, Alert, FormControl, Message, SelectPicker} from "rsuite";
import {BaseButton} from "components/base/BaseButton";
import { Spacer } from "components/base/Spacer";
import styled from "styled-components";
import IvrListener from "components/IvrListener/IvrListener";
import {PermissionContext} from "../../../App";
import {IVR_SET_DEFAULT_LIST_API} from "const/apiMethods";
import TableClientSort from "components/Table/TableClientSort";
import {compareTwoArrays} from "utils/helpers";

import { FilterSectionFlexboxGrid, StyledItemFitWidth, StyledItemAutoMargin } from "components/base/FitContainers";
import ModalConfirm from "components/Modal/ModalConfirm";


export default (
    {
        ivrDefaultList,
        ivrDefaultLoading,
        ivrList,
        getIvrList,
        setDefaultIvrLoading,
        getDefaultIvrList,
        changeDefaultIvrList,
    }
) => {

    const permissions = useContext(PermissionContext);

    const tableRef = useRef(null);
    
    const [newIvrId, setNewIvrId] = useState(null);

    const [ivrStateList, setIvrStateList] = useState([]);
    const [showRemoveModalConfirm, setShowRemoveModalConfirm] = useState(false);
    const [ivrIdToRemove, setIvrIdToRemove] = useState(null);
    const [activeSession, setActiveSession] = useState(null);

    useEffect(() => {
        getIvrList();
        getDefaultIvrList();
    }, []);
    
    useEffect(() => {
        if (!compareTwoArrays(ivrStateList, ivrDefaultList)) {
            setIvrStateList(ivrDefaultList);
        }
    }, [ivrDefaultList]);

    const newIvrList = useMemo(() => {
        return ivrList.filter((value) => {
            return !ivrDefaultList.map(({id}) => id).includes(value.id);
        })
    }, [ivrList, ivrDefaultList]);
   
    const disabled = useMemo(() => {
        return !permissions.includes(IVR_SET_DEFAULT_LIST_API);
    }, [permissions]);

    const handleSubmit = () => {
        if (!newIvrId) {
            return;
        }

        setDefaultIvrLoading(true);

        const defaultIvrIdlist = ivrDefaultList.map(({id}) => id);

        changeDefaultIvrList({target: {ivr_id_list: [newIvrId, ...defaultIvrIdlist]}})
            .then((response) => {
                if (response.result) {
                    Alert.success("Default IVR were successfully added");
                    getDefaultIvrList();
                    setNewIvrId(null);
                }
            })
            .finally(() => {
                setDefaultIvrLoading(false);
            });

    };

    const handleRemove = (id) => {
        setDefaultIvrLoading(true);
        setIvrStateList((prevIvrStateList) => {
            const defaultIvrIdlist = prevIvrStateList.map((ivr) => ivr.id);
            const removedIvrIdList = defaultIvrIdlist.filter((ivrId) => ivrId !== id);
            
            changeDefaultIvrList({ target: { ivr_id_list: [...removedIvrIdList] } })
                .then((response) => {
                    if (response.result) {
                        Alert.success("Default IVR were successfully removed");
                        getDefaultIvrList();
                    }
                })
                .finally(() => {
                    setDefaultIvrLoading(false);
                });
    
            return prevIvrStateList.filter((ivr) => ivr.id !== id);
        });
    };

    const handleSessionChange = (newSession) => {
        if (activeSession && activeSession !== newSession) {
            activeSession.terminate();
        }

        setActiveSession(newSession);
    };

    const columns = useMemo(() => {
        const key = `${activeSession?.id}`;
        return [
            {
                label: "Name",
                dataKey: "name",
                minWidth: 320,
                flexGrow: 1
            },
            {
                key: key,
                label: "Play",
                dataKey: "id",
                value: ({id}) => {
                    return <StyledIvrListener>
                        <IvrListener
                            ivrId={id || null}
                            onSessionChange={handleSessionChange}
                        />
                    </StyledIvrListener>
                },
                align: "center",
                width: 60
            },
            {
                label: "",
                dataKey: "id",
                value: ({id}) => {
                    return <RemoveButton
                        className={"form-btn"}
                        buttonStyle="primary"
                        onClick={() => {
                            setShowRemoveModalConfirm(true);
                            setIvrIdToRemove(id);
                        }}
                    >
                        Remove
                    </RemoveButton>
                },
                align: "center",
                width: 100
            }
        ];
    }, [activeSession]);

    return (
        <>
            <Spacer size={30}/>

            <Message
                showIcon
                type="warning"
                description="This IVR will be played if no other is not applicable. Changing this IVR will affect a lot of traffic."
            />

            <Spacer size={20}/>
            
            <FilterSectionFlexboxGrid>
                <StyledItemFitWidth>
                    <StyledForm>
                        <FormControl
                            readOnly={disabled}
                            accepter={SelectPicker}
                            name={"ivr_id"}
                            data={newIvrList}
                            onChange={(id) => {
                                setNewIvrId(id);
                            }}
                            disabled={ivrDefaultLoading}
                            valueKey="id"
                            labelKey="name"
                            placeholder="Add new default IVR"
                            cleanable={false}
                        />
                        <BaseButton
                            buttonStyle="primary"
                            disabled={!newIvrId || ivrDefaultLoading}
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            Save
                        </BaseButton>
                    </StyledForm>
                 
                </StyledItemFitWidth>
                <StyledItemAutoMargin>
                </StyledItemAutoMargin>
            </FilterSectionFlexboxGrid>
          
            <TableClientSort
                ref={tableRef}
                shouldUpdateScroll={true}
                extraHeight={580}
                data={ivrDefaultList}
                loading={ivrDefaultLoading}
                rowHeight={54}
                columns={columns}
            />

            <ModalConfirm
                show={showRemoveModalConfirm}
                onClose={() => {
                    setShowRemoveModalConfirm(false);
                    setIvrIdToRemove(null);
                }}
                onSuccess={() => {
                    handleRemove(ivrIdToRemove);
                    setShowRemoveModalConfirm(false);
                    setIvrIdToRemove(null);
                }}
                title="Remove default IVR"
                width={320}
            >
                Are you sure?
            </ModalConfirm>
            {/* </StyledForm> */}
        </>
    )
};


const StyledForm = styled(Form)`
    display: flex;

    .rs-picker-toggle-wrapper {
        width: 100%;
    }

    .rs-form-control-wrapper {
        max-width: 300px;
        margin-right: 20px;
    }
    
    .form-btn {
        flex-shrink: 0;
        padding-left: 34px;
        padding-right: 34px;
    }
`;

const StyledIvrListener = styled.div`
    .rs-btn {
        width: 36px;
        height: 36px;
        border-radius: 36px;
        
        .rs-icon {
            font-size: 15px;
            line-height: 36px;
        }
    }
`;

const RemoveButton = styled(BaseButton)`
    && {
        background-color: var(--color-error);
        border-color: var(--color-error);
    }

    &&:hover, &&:focus, &&:active, &&:hover:active, &&:hover:focus {
        background-color:  #b62910;
        border-color: #b62910;
    }
`;