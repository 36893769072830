import React, {useState, useEffect} from "react";
import {Form, Schema, SelectPicker, CheckPicker} from "rsuite";
import FormHOC from "hoc/FilltersForm";
import ServicePlans from "components/Filters/ServicePlans";
import Text from "components/Form/Text";
import CheckboxFullControl from "components/Form/CheckboxFullControl";
import CustomField from "components/Form/CustomField";
import {OTP_SERVICE_PLAN_KEY} from "const";
import { BaseToggle } from "components/base/BaseToggle";
import {servicePlanNoTrunkFilter} from "utils/filters";
import { FilterSectionFlexboxGrid, StyledItemFitWidth } from "components/base/FitContainers";

import styled from "styled-components";
import { servicePick } from "utils";

const { StringType, NumberType } = Schema.Types;

const filtersModel = Schema.Model({
    str: StringType().maxLength(40, "Max characters 40"),
    str2: StringType().maxLength(40, "Max characters 40"),
    rate: NumberType().min(0, "0 is minimum"),
    min_numbers: NumberType().min(0, "0 is minimum"),
    min_atx_numbers: NumberType().min(0, "0 is minimum"),
    min_dialer_numbers:  NumberType().min(0, "0 is minimum")
});


export default FormHOC( ({
    service,
    onChange,
    extended,
    isMobile,

    supplierList,
    currency_list,
    defaultFormValue,

    setExtended
}) => {


    const [formValue, setFormValue] = useState(defaultFormValue);
    const [changedServicePlan, onChangeServicePlan] = useState(null);

    const servicePlanField = !extended ? <StyledItemFitWidth isMobile={isMobile} controlable={!isMobile} noWrap group mr={20}>
        <ServicePlans
            filtered
            cleanable
            extraPlaceholder="Type"
            accepter={CheckPicker}
            name="sp_key_list"
            onChange={(value) => {
                onChangeServicePlan(value)
            }}
            filterFunc={servicePlanNoTrunkFilter}
        />
    </StyledItemFitWidth> : <></>;

    useEffect(() => {
        setFormValue(defaultFormValue);
    }, [extended]);

    return (
        <FilterForm
            model={filtersModel}
            onChange={(value) => {
                onChange(value);
                setFormValue(value);
            }}
            formValue={formValue}
            formDefaultValue={defaultFormValue}
            layout="inline"
            style = {{marginBottom: "-10px"}}
        >
            <FilterSectionFlexboxGrid controlable={!isMobile}>
                <StyledItemFitWidth isMobile={isMobile} controlable={!isMobile} noWrap mr={10}>
                    <BaseToggle
                        style={{
                            marginTop: 4
                        }}
                        checkedChildren="Extended"
                        unCheckedChildren="Default"
                        defaultChecked={extended}
                        onChange={value => setExtended(value)}
                    />
                </StyledItemFitWidth>

                {servicePlanField}
    
                {!service ? <StyledItemFitWidth isMobile={isMobile} controlable={!isMobile} group mr={20}>
                    <CustomField
                        accepter={CheckPicker}
                        data={supplierList}
                        cleanable
                        searchable
                        labelKey="name"
                        valueKey="id"
                        name="supplier_id_list"
                        placeholder="Select supplier"
                        style={{
                            width: 220,
                            marginLeft: 10
                        }}
                    />
                </StyledItemFitWidth> : <></>}


                {!extended && <StyledItemFitWidth isMobile={isMobile} controlable={!isMobile} noWrap group mr={20}>
                    <CustomField
                        accepter={SelectPicker}
                        style={{
                            maxWidth: "80px",
                            minWidth: "30px"
                        }}
                        data={currency_list}
                        cleanable={false}
                        searchable={false}
                        labelKey="name"
                        valueKey="cur_key"
                        name="cur_key"
                        classPrefix="allocated"
                    />
                </StyledItemFitWidth>}

                {service && extended && <StyledItemFitWidth isMobile={isMobile} controlable={!isMobile} group mr={20}>
                    <Text
                        name="originator"
                        style={{
                            maxWidth: "320px",
                            minWidth: "260px",
                        }}
                        placeholder={`Originator`}
                    />
                </StyledItemFitWidth>}

                {!extended || (service && extended) ? <StyledItemFitWidth isMobile={isMobile} controlable={!isMobile} group mr={20}>
                    <Text
                        name={service && !extended ? "str" : "origin"}
                        style={{
                            maxWidth: "280px",
                            minWidth: "220px"
                        }}
                        placeholder={`Origin${changedServicePlan === OTP_SERVICE_PLAN_KEY ? ", Service" : ""} (min 3 characters)`}
                    />
                </StyledItemFitWidth> : <StyledItemFitWidth isMobile={isMobile} controlable={!isMobile} group mr={20}>
                    <Text
                        name="senderid"
                        style={{
                            maxWidth: "280px",
                            minWidth: "220px"
                        }}
                        placeholder="Sender ID (min 3 characters)"
                    />
                </StyledItemFitWidth>}

                <StyledItemFitWidth isMobile={isMobile} controlable={!isMobile} group mr={20}>
                    <Text
                        name={service && !extended ? "str2" : "destination"}
                        style={{
                            maxWidth: "320px",
                            minWidth: "220px"
                        }}
                        placeholder={`Destination${changedServicePlan === OTP_SERVICE_PLAN_KEY ? ", Service" : ""} (min 3 characters)`}
                    />
                </StyledItemFitWidth>
                {extended && <StyledItemFitWidth isMobile={isMobile} controlable={!isMobile} group mr={20}>
                    <CustomField
                        style={{
                            minWidth: 185
                        }}
                        name="min_numbers"
                        placeholder="Min numbers (default 10)"
                    />
                </StyledItemFitWidth>}
                {extended && <StyledItemFitWidth isMobile={isMobile} controlable={!isMobile} group mr={20}>
                    <CustomField
                        style={{
                            minWidth: 220
                        }}
                        name={servicePick(service, "min_atx_numbers", "min_dialer_numbers")}
                        placeholder="Min dialer numbers (default 0)"
                    />
                </StyledItemFitWidth>}

                {extended && <StyledItemFitWidth isMobile={isMobile} controlable={!isMobile} group mr={15}>
                    <CustomField
                        style={{
                            width: 145
                        }}
                        name="rate"
                        placeholder="Min rate (default 0)"
                    />
                </StyledItemFitWidth>}
                
                {extended && <StyledItemFitWidth isMobile={isMobile} controlable={!isMobile} group mr={20}>
                    <CustomField
                        accepter={CheckboxFullControl}
                        name="exclude_local_traffic"
                    >
                        Exclude local traffic
                    </CustomField>
                </StyledItemFitWidth>}

                {!extended && <StyledItemFitWidth isMobile={isMobile} controlable={!isMobile}>
                    <CustomField
                        accepter={CheckboxFullControl}
                        name="no_number"
                    >
                        Hide prefixes
                    </CustomField>
                </StyledItemFitWidth>}
            </FilterSectionFlexboxGrid>
        </FilterForm>
    )
}, filtersModel, 500);

const FilterForm = styled(Form)`
    && {
        display: flex;
        flex-direction: row;
    }
`;

const StyledField = styled.div`
    margin-right: 20px !important;
    display: inline-block;
`;

const ToggleField = styled.div`
    display: inline-block;
    margin-top: 5px;
    width: 100px;
`;