import React, {useState, useRef, useEffect} from 'react';
import {ButtonPrimary} from 'components/base/BaseButton';
import {apiFormData} from 'api/loginRoutes';
import styled from "styled-components";
import { Alert, Icon, Uploader } from 'rsuite';


export default ({
    onSuccess,
}) => {

    const uploaderRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const handleUploadFile = async (value) => {

        if (!value || !value.length) {
            return;
        }

        const fileKey = value[0].fileKey;

        // check file size
        const fileSize = value[0]?.blobFile?.size;
        if ( (fileSize / (1024*1024)) > 30) {
            Alert.error('The file is too large');
            return;
        }
        

        const dt = new DataTransfer();
        dt.items.add(value[0].blobFile);
        
        setLoading(true);
        await apiFormData('auth_number:upload', {files: dt.files}, true)
            .then(r => {
                if (!r) {
                    return;
                }

                setLoading(false);
                uploaderRef.current.handleRemoveFile(fileKey);

                if (r.validation_error) {
                    Alert.error(<>
                        <p><b>Error</b>: {r.validation_error}</p>
                        {r.line_number &&
                        <p>
                            {dt.files && `${dt.files[0].name}: `}
                            line <b>{r.line_number}</b><br/>
                            {r.line_str}
                        </p>
                        }
                    </>, 10000);
                    return;
                }

                if (r?.inserted_rows) {
                    Alert.success(`Success! ${r?.inserted_rows} rows were uploaded!`);
                    onSuccess && onSuccess();
                    return;
                }
            });

        return;
    };

    
    return (
        <>
            
            <StyledUploader
                ref={uploaderRef}
                accept={'text/csv'}
                multiple={false}
                removable={true}
                autoUpload={false}
                onChange={handleUploadFile}
            >

                <ButtonPrimary
                    loading={loading}
                    tooltipText="Use CSV file with columns number;service name;number type"
                >
                    <StyledIcon icon="file"/>
                    <span>Upload from file</span>
                </ButtonPrimary>
            </StyledUploader>
        </>
    )
}


const StyledIcon = styled(Icon)`
    margin-right: 5px;
`;


const StyledUploader = styled(Uploader)`
  
    button.rs-btn {
        background: #20BA88;
        border: none;
        color: #fff;
        box-shadow: 0 4px 4px rgba(0,0,0,0.16);
        padding: 12px 34px;
        border-radius: 5px;
    }

    .rs-uploader-file-item.rs-uploader-file-item-text {
        display: none;
    }

    &&&& {
        .rs-btn:focus,
        .rs-btn:hover,
        .rs-btn:focus:hover,
        .rs-btn:active:hover,
        .rs-btn:active:focus, 
        .rs-btn:active.focus {
            background-color: var(--color-secondary-hover);
            color: #ffffff;
        }
}
`;