import React, {Component} from 'react';
import {
    ButtonToolbar,
    Container,
    Content,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    Header,
    Icon,
    Nav,
    Navbar, 
    Panel, 
    Schema,
    Alert
} from "rsuite";
import {Link, withRouter} from 'react-router-dom';
import {notAuthApi} from "../../api/auth";
import history from "../../config/history";
import {APP_TYPE_ADMIN, APP_TYPE_DEFAULT, DEFAULT_ERROR_MESSAGE, DEFAULT_NOT_AUTH_PATH} from "../../const";
import VersionLabel from "../../components/VersionLabel/VersionLabel";
import styled from "styled-components";
import {BaseButton} from "../../components/base/BaseButton";
import Recaptcha from 'react-google-invisible-recaptcha';


const {StringType} = Schema.Types;
const MIN_PASSWORD_LENGTH = 8;
const MAX_EMAIL_LENGTH = 120;
const resetPasswordFormModel = Schema.Model({
        password: StringType()
            .pattern(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])[A-Za-z'\d#?!_@$%^&*-]{8,}$/,
                'Must contain at least one number and one uppercase and lowercase letter'
            )
            .minLength(MIN_PASSWORD_LENGTH, `Password must be at least ${MIN_PASSWORD_LENGTH} characters`)
            .isRequired('This field is required'),
        confirm_password: StringType()
            .isRequired('This field is required')
            .addRule((value, formValues) => (
                value === formValues.password
            ), 'Passwords must be same')
    }
);
export const requestFormModel = Schema.Model({
    email: StringType()
        .isRequired('Required')
        .maxLength(MAX_EMAIL_LENGTH, 'Too long email address')
        .isEmail('Please enter an email address'),
});


class ResetPassword extends Component {
    
    constructor(props) {
        super(props);
        
        this.formRef = null;
        this.recaptchaRef = null;
    
        this.state = {
            isTokenValid: false,
            formValues: {},
            captchaIsVisible: process?.env?.REACT_APP_IS_PRODUCTION 
                ? !!(+process?.env?.REACT_APP_IS_PRODUCTION)
                : false
        };
    }

    componentDidMount() {
        const token = this.props.match.params.token;
        document.body.classList.remove("rtl");

        if (token) {
            this.checkToken(token);
        }
    }

    componentWillUnmount() {
        this.resetRecaptcha();
    }

    checkToken = (token) => {
        notAuthApi('account_user__reset_password:check', {token}).then(() => {
            this.setState({isTokenValid: true});
        }).catch(() => {
            Alert.error('Token expired, please try to reset password again');
        });
    };

    handleSubmitResetRequest = () => {
        const formValues = this.formRef.getFormValue();

        if (!this.formRef.check()) {
            return;
        }

        this.executeRecaptcha();
    };


    handleResolveCaptcha = (token) => {

        if (!token && this.state.captchaIsVisible) {
            Alert.error("Bot verification failed")
            this.resetRecaptcha();
            return;
        }


        const formValues = this.formRef.getFormValue();

        notAuthApi('account_user__reset_password:request', {
            ...formValues, 
            site: 1,
            captcha_token: token
        })
            .then(response => {
                if (response !== undefined) {
                    Alert.success('Email was successfully sent! Check your SPAM folder');
                    history.push(DEFAULT_NOT_AUTH_PATH);
                }
            })
            .catch(() => {
                Alert.error('Incorrect email');
            })
            .finally(() => {
                this.resetRecaptcha();
            });
    }


    handleSubmitNewPassword = () => {
        const password = this.formRef.getFormValue().password;
        const token = this.props.match.params.token;

        if(this.formRef.check()){
            notAuthApi('account_user__reset_password:modify', {password, token}).then((response) => {
                Alert.success('Password successfully changed');
                history.push(DEFAULT_NOT_AUTH_PATH);
            }).catch(() => {
                Alert.error(DEFAULT_ERROR_MESSAGE);
            });
        }
    };


    executeRecaptcha = () => {
        if (!this.state.captchaIsVisible) {
            this.handleResolveCaptcha();
            return;
        }
        console.log(1);
        if (this.recaptchaRef?.execute) {
            this.recaptchaRef.execute();
        }
    }

    resetRecaptcha = () => {
        if (!this.state.captchaIsVisible) {
            return;
        }

        if (this.recaptchaRef?.reset) {
            this.recaptchaRef.reset();
        }
    }


    render() {
        const isTokenValid = this.state.isTokenValid;
        const captchaIsVisible = this.state.captchaIsVisible;

        return (
            <Container style={{height: '100vh'}}>
                <Header>
                    <Navbar appearance="inverse">
                        <Navbar.Header>
                        </Navbar.Header>
                        <Navbar.Body>
                            <Nav pullRight>
                                <StyledNavItem
                                    componentClass={Link}
                                    icon={<Icon icon="sign-in"/>}
                                    to="/login"
                                >
                                    Log in
                                </StyledNavItem>
                            </Nav>
                        </Navbar.Body>
                    </Navbar>
                </Header>
                <Content >
                    <FlexboxGrid justify="center" >
                        <FlexboxGrid.Item colspan={12} >
                            <Panel 
                                header={<h3>Reset Password</h3>}
                                bordered 
                                style={{marginTop: '15vh'}}
                            >

                                <>
                                    {!isTokenValid &&
                                        <Form fluid ref={ref => (this.formRef = ref)} model={requestFormModel}>
                                            <FormGroup>
                                                <ControlLabel>Please, enter an email address</ControlLabel>
                                                <FormControl autoFocus={true} name="email"/>
                                            </FormGroup>
                                            <FormGroup>
                                                <ButtonToolbar>
                                                    <StyledButton
                                                        type="submit"
                                                        onClick={this.handleSubmitResetRequest}
                                                    >
                                                        Reset
                                                    </StyledButton>
                                                </ButtonToolbar>
                                            </FormGroup>
                                        </Form>
                                    }

                                    {isTokenValid &&
                                        <Form fluid
                                            ref={ref => (this.formRef = ref)}
                                            model={resetPasswordFormModel}
                                            formValue = {this.state.formValues}
                                            onChange={(formValues) => this.setState({formValues})}
                                        >
                                            <FormGroup>
                                                <ControlLabel>Password</ControlLabel>
                                                <FormControl autoFocus={true} name="password" type="password"/>
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Confirm Password</ControlLabel>
                                                <FormControl name="confirm_password" type="password"/>
                                            </FormGroup>
                                            <FormGroup>
                                                <ButtonToolbar>
                                                    <StyledButton
                                                        type="submit"
                                                        onClick={this.handleSubmitNewPassword}
                                                    >
                                                        Reset Password
                                                    </StyledButton>
                                                </ButtonToolbar>
                                            </FormGroup>
                                        </Form>
                                    }
                                </>

                                {captchaIsVisible && <Recaptcha
                                    ref={ref => this.recaptchaRef = ref}
                                    sitekey={process.env.REACT_APP_SITE_KEY}
                                    onResolved={this.handleResolveCaptcha}
                                />}

                            </Panel>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Content>
                <VersionLabel/>
            </Container>
        );
    }
}

export default withRouter((ResetPassword));

const StyledButton = styled(BaseButton).attrs(() => ({
    buttonStyle: APP_TYPE_DEFAULT === APP_TYPE_ADMIN ? "secondary" : "primary"
}))`
    && {
        min-width: 0;
    }
`;

const StyledNavItem = styled(Nav.Item)`
    .rs-nav-item-content {
    
        &:hover,
        &:active,
        &:focus {
            background-color: #20b188 !important;
        }
    }
`;