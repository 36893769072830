import React from "react";
import {Form, FormControl, Input, InputGroup} from "rsuite";
import styled from "styled-components";
import {debounce} from "utils";

export default (
    {
        filter,
        setFilter
    }
) => {
    return (
        <Form
            formDefaultValue={filter}
            onChange={debounce((value) => setFilter(value), 700)}
        >
            <StyledField>
                <InputGroup>
                    <FormControl
                        name="str"
                        accepter={Input}
                        placeholder={"Service Name or IVR"}
                    />
                </InputGroup>
            </StyledField>
        </Form>
    )
}

const StyledField = styled.div`
    max-width: 300px;
    width: 100%;
`;