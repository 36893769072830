export const CDR_LIST_METHOD = 'cdr_full:get_list_admin';
export const CDR_LIST_SMS_METHOD = 'sms.mdr_full:get_list_admin'
export const SMS_MDR_SHORT_CLI_LOOKUP_METHOD = 'sms.mdr_short__cli_lookup';
export const TRAFFIC_REPORTS_METHOD = 'cdr_full:group_get_list_admin';
export const TRAFFIC_REPORTS_SMS_METHOD = 'sms.mdr_full:group_get_list_admin';
export const FAILED_MDR_REPORTS_METHOD= 'sms.mdr_short:group_get_list_admin';

export const MDR_FAILED_LIST_METHOD = 'sms.mdr_short:get_list_admin';

export const AUTH_NUMBERS_GET_LIST = 'auth_number:get_list';
export const AUTH_NUMBERS_ADD_UPLOAD = 'auth_number:add_upload';
export const AUTH_NUMBERS_ADD = 'auth_number:create';
export const AUTH_NUMBERS_EDIT = 'auth_number:modify';
export const AUTH_NUMBERS_DELETE = 'auth_number:remove';

export const SMS_AUTH_NUMBERS_GET_LIST = 'sms.auth_number:get_list';

export const AUTH_AUTH_NUMBER_TYPE_GET_LIST = 'auth_number:type_get_list';
export const AUTH_AUTH_NUMBER_TYPE_SET_IVR = "auth_number:type_set_ivr";
export const AUTH_AUTH_NUMBER_TYPE_CREATE_NEW = 'auth_number:type_create';

export const SMS_AUTH_AUTH_NUMBER_TYPE_GET_LIST = 'sms.auth_number:type_get_list';
export const SMS_AUTH_AUTH_NUMBER_TYPE_CREATE_NEW = 'sms.auth_number:type_create';

export const REFERENCES_ADMIN = 'references_admin';
export const REFERENCES_CLIENT = 'references_client';
export const REFERENCES_FOR_REPORTS_METHOD = 'references_admin_report';
export const REFERENCES_ALL_METHOD = 'references_all';

export const ACCOUNT_USER_MODIFY_BY_SELF = 'account_user:modify_by_self';
export const ACCOUNT_USER_MODIFY_BY_SELF_IN_MANAGER_ACCOUNT = 'account_user:modify_by_self_in_manager_account';
export const ACCOUNT_USER_MODIFY_PASSWORD_BY_SELF = 'account_user:modify_password_by_self';
export const SET_REALTIME_PAYMENT_STATUS = 'account_user:get_realtime_status';
export const SET_RULES_ACCEPT = 'account_user:rules_accept';

export const ACCOUNT_USER_GET_REALTIME_STATUS = 'account_user:get_realtime_status';

/*Service plan methods*/
export const SP_CHANGE_RATE_METHOD = 'service_plan_price:change_rate';
export const SP_PRICE_NEW_SUBDESTINATION_METHOD = 'service_plan_price:new_subdestination';

export const ACCOUNT_GET_LIST = "account:get_list";
export const ACCOUNT_CREATE_METHOD = 'account:create';
export const ACCOUNT_MODIFY_METHOD = 'account:modify';
export const ACCOUNT_CREATE_JOINED_METHOD = 'account:create_joined';
export const ACCOUNT_GET_GENERATE_NAME_LIST_METHOD = "account:generate_name_list";
export const SMS_ACCOUNT_CREATE_METHOD = 'sms.account:create';
export const SMS_ACCOUNT_CREATE_JOINED_METHOD = 'sms.account:create_joined';
export const ACCOUNT_SETTINGS_MODIFY_API = 'account_setting:modify';

// Account contact
export const ACCOUNT_CONTACT_CHECK_METHOD = "account_contact:check";
export const ACCOUNT_CONTACT_GET_LIST_METHOD = "account_contact:get_list";
export const ACCOUNT_CONTACT_MODIFY_BY_CLIENT_METHOD = "account_contact:modify_by_client";
export const ACCOUNT_CONTACT_MODIFY_REQUEST_METHOD = "account_contact:modify_request";

// SMS Suppliers API
export const SMS_SUPPLIER_GET_LIST_METHOD = 'sms.supplier:get_list'
export const SMS_SUPPLIER_GET_DROPDOWN_LIST_METHOD = 'sms.supplier:get_dropdown_list';
export const SMS_SUPPLIER_CREATE_METHOD = 'sms.supplier:create';
export const SMS_SUPPLIER_MODIFY_METHOD = 'sms.supplier:modify';
export const SMS_SUPPLIER_REMOVE_METHOD = 'sms.supplier:remove';
export const SMS_SUPPLIER_RESTORE_METHOD = 'sms.supplier:restore';

// SMS Suppliers Prefix API
export const SMS_SUPPLIER_PREFIX_GET_LIST_METHOD = 'sms.supplier_prefix:get_list';
export const SMS_SUPPLIER_PREFIX_CREATE_METHOD = 'sms.supplier_prefix:create';
export const SMS_SUPPLIER_PREFIX_CREATE_SUBDESTINATION_METHOD = 'sms.supplier_prefix:create_subdestination';
export const SMS_SUPPLIER_PREFIX_CREATE_SUBDESTINATION_LIST_METHOD = 'sms.supplier_prefix:create_subdestination_list';
export const SMS_SUPPLIER_PREFIX_CREATE_MCCMNC_METHOD = 'sms.supplier_prefix:create_mccmnc';
export const SMS_SUPPLIER_PREFIX_CREATE_MCCMNC_LIST_METHOD = 'sms.supplier_prefix:create_mccmnc_list';
export const SMS_SUPPLIER_PREFIX_CREATE_TEMPLATE_LIST_METHOD = 'sms.supplier_prefix:create_template_list';
export const SMS_SUPPLIER_PREFIX_MODIFY_LIST_METHOD = 'sms.supplier_prefix:modify_list';
export const SMS_SUPPLIER_PREFIX_REMOVE_LIST_METHOD = 'sms.supplier_prefix:remove_list';
export const SMS_SUPPLIER_PREFIX_UPLOAD_CSV_METHOD = 'sms.supplier_prefix:upload_csv';
export const SMS_SUPPLIER_PREFIX_SET_ACTIVE_METHOD = 'sms.supplier_prefix:set_active_list';

// SMS Supplier Price API
export const SMS_SUPPLIER_PRICE_GET_LIST_METHOD = 'sms.supplier_price:get_list';
export const SMS_SUPPLIER_PRICE_CREATE_METHOD = 'sms.supplier_price:create';
export const SMS_SUPPLIER_PRICE_MODIFY_LIST_METHOD = 'sms.supplier_price:modify_list';
export const SMS_SUPPLIER_PRICE_REMOVE_LIST_METHOD = 'sms.supplier_price:remove_list';
export const SMS_SUPPLIER_PRICE_UPLOAD_CSV_METHOD = 'sms.supplier_price:upload_csv';

// SMS Supplier Gan
export const SMS_SUPPLIER_GAN_GET_LIST_METHOD = 'sms.supplier_gan:get_list';
export const SMS_SUPPLIER_GAN_MODIFY_LIST_METHOD = 'sms.supplier_gan:modify_list';
export const SMS_SUPPLIER_GAN_REVOKE_LIST_METHOD = 'sms.supplier_gan:revoke_list';
export const SMS_SUPPLIER_GAN_REMOVE_LIST_METHOD = 'sms.supplier_gan:remove_list';
export const SMS_SUPPLIER_GAN_REMOVE_LIST_ALLOCATED_METHOD = 'sms.supplier_gan:remove_list_allocated';
export const SMS_SUPPLIER_GAN_SET_TEST_NUMBER_LIST_METHOD = 'sms.supplier_gan:set_test_number_list';
export const SMS_SUPPLIER_GAN_UNSET_TEST_NUMBER_LIST_METHOD = 'sms.supplier_gan:unset_test_number_list';
export const SMS_SUPPLIER_GAN_SET_BLOCK_ALLOCATION_LIST_METHOD = 'sms.supplier_gan:set_block_allocation_list';
export const SMS_SUPPLIER_GAN_UNSET_BLOCK_ALLOCATION_LIST_METHOD = 'sms.supplier_gan:unset_block_allocation_list';

// SMS Supplier Gan Group
export const SMS_SUPPLIER_GAN_GROUP_CREATE_METHOD = 'sms.supplier_gan_group:create';
export const SMS_SUPPLIER_GAN_GROUP_GET_LIST_METHOD = 'sms.supplier_gan_group:get_list';
export const SMS_SUPPLIER_GAN_GROUP_GROUP_PRICE_MODIFY_METHOD = 'sms.supplier_gan_group:group_price_modify';
export const SMS_SUPPLIER_GAN_GROUP_MODIFY_METHOD = 'sms.supplier_gan_group:modify';
export const SMS_SUPPLIER_GAN_GROUP_REMOVE_METHOD = 'sms.supplier_gan_group:remove';
export const SMS_SUPPLIER_GAN_GROUP_PRICE_MODIFY_METHOD = 'sms.supplier_gan_group:group_price_modify';
export const SMS_SUPPLIER_GAN_GROUP_REMOVE_ALLOCATED_METHOD = 'sms.supplier_gan_group:remove_allocated';
export const SMS_SUPPLIER_GAN_GROUP_GET_GROUP_LIST_METHOD = 'sms.supplier_gan_group:get_group_list';
export const SMS_SUPPLIER_GAN_GROUP_UPLOAD_CSV_EXISTS_METHOD = 'sms.supplier_gan_group:upload_csv_exists';
export const SMS_SUPPLIER_GAN_GROUP_UPLOAD_CSV_MULTY_METHOD = 'sms.supplier_gan_group:upload_csv_multy_group';
export const SMS_SUPPLIER_GAN_GROUP_UPLOAD_CSV_NEW_METHOD = 'sms.supplier_gan_group:upload_csv_new';
export const SMS_SUPPLIER_GAN_GROUP_GET_DEFAULT_RATE_LIST_METHOD = 'sms.supplier_gan_group:get_default_rate_list';
 

// SMS Supplier Plan API
export const SMS_SUPPLIER_PLAN_GET_LIST_METHOD = 'sms.supplier_plan:get_list';
export const SMS_SUPPLIER_PLAN_CREATE_METHOD = 'sms.supplier_plan:create';
export const SMS_SUPPLIER_PLAN_MODIFY_METHOD = 'sms.supplier_plan:modify';
export const SMS_SUPPLIER_PLAN_REMOVE_METHOD = 'sms.supplier_plan:remove';
export const SMS_SUPPLIER_PLAN_UPLOAD_CSV_METHOD = 'sms.supplier_plan:access_list_upload_csv';

// SMS Suppliers Trunk API
export const SMS_SUPPLIER_TRUNK_GET_LIST_METHOD = 'sms.supplier_trunk:get_list';
export const SMS_SUPPLIER_TRUNK_CREATE_METHOD = 'sms.supplier_trunk:create';
export const SMS_SUPPLIER_TRUNK_MODIFY_METHOD = 'sms.supplier_trunk:modify';
export const SMS_SUPPLIER_TRUNK_REMOVE_METHOD = 'sms.supplier_trunk:remove';
export const SMS_SUPPLIER_TRUNK_GET_DROPDOWN_LIST_METHOD = 'sms.supplier_trunk:get_dropdown_list';

// SMS Suppliers Trunk IP API
export const SMS_SUPPLIER_TRUNK_IP_GET_LIST_METHOD = 'sms.supplier_trunk:ip_get_list';
export const SMS_SUPPLIER_TRUNK_IP_CREATE_METHOD = 'sms.supplier_trunk:ip_create';
export const SMS_SUPPLIER_TRUNK_IP_MODIFY_METHOD = 'sms.supplier_trunk:ip_modify';
export const SMS_SUPPLIER_TRUNK_IP_REMOVE_METHOD = 'sms.supplier_trunk:ip_remove';

// SMS Suppliers Traffic Limit API
export const SMS_SUPPLIER_TRAFFICLIMIT_CREATE_METHOD = 'sms.traffic_limit:create';
export const SMS_SUPPLIER_TRAFFICLIMIT_GET_LIST_METHOD = 'sms.traffic_limit:get_list';
export const SMS_SUPPLIER_TRAFFICLIMIT_MODIFY_METHOD = 'sms.traffic_limit:modify';
export const SMS_SUPPLIER_TRAFFICLIMIT_REMOVE_METHOD = 'sms.traffic_limit:remove';

// Payments API
export const PAYMENT_REQUEST_LIST_API = 'payment_request:get_list';
export const PAYMENT_REQUEST_ALL_LIST_API = 'payment_request:all__get_list';
export const PAYMENT_LIST_API = 'payment__get_list_by_admin';
export const INVOICE_LIST_API = 'invoice__get_list_by_admin';
export const PAYMENT_REQUEST_CREATE_API = 'payment_request:create';
export const PAYMENT_REQUEST_CANCEL_API = 'payment_request:cancel';
export const PAYMENT_REQUEST_REJECT_API = "payment_request:reject";
export const PAYMENT_REQUEST_APPROVE_API = 'payment_request:approve';
export const PAYMENT_REQUEST_HAWALA_GET_LIST_API = 'payment_request__hawala_get_list';
export const PAYMENT_REQUEST_HAWALA_EXPORT_API = "payment_request:hawala__export";
export const PAYMENT_REQUEST_MISC_GET_LIST_API = 'payment_request:misc__get_list';
export const PAYMENT_REQUEST_MISC_EXPORT_API = 'payment_request:misc__export';

export const PAYMENT_LIST_DIALER_API = 'payment__get_list';
export const INVOICE_LIST_DIALER_API = 'invoice__get_list';

// Payment Details API
export const PAYMENT_DETAIL_LIST_API = 'payment_detail:get_list';
export const PAYMENT_DETAIL_GET_API = 'payment_detail:get';
export const PAYMENT_DETAIL_CREATE_API = 'payment_detail:create';
export const PAYMENT_DETAIL_MODIFY_API = 'payment_detail:modify';
export const PAYMENT_DETAIL_REMOVE_API = 'payment_detail:remove';
export const PAYMENT_DETAIL_CONFIRM_API = 'payment_detail:confirm';
export const PAYMENT_DETAIL_CRYPTO_LIST_API = 'payment_detail:crypto_get_list';
export const PAYMENT_DETAIL_CONFIRM_STATUS_API = 'payment_detail_confirm_status';

// Templates
export const TEMPLATE_GET = 'template:get';

// Hot Access List
export const HOT_ACCESS_LIST_API = 'access_list_hot__get_list';
export const HOT_DIALER_ACCESS_LIST_API = 'access_list_hot__get_list:account_price';
export const HOT_TEST_DIALER_ACCESS_LIST_API = 'access_list_hot__get_list:default_price';

// SMS Hot Access List
export const SMS_HOT_ACCESS_LIST_API = 'sms.access_list_hot__get_list';
export const SMS_HOT_DIALER_ACCESS_LIST_API = 'sms.access_list_hot__get_list:account_price';
export const SMS_HOT_TEST_DIALER_ACCESS_LIST_API = 'sms.access_list_hot__get_list:default_price';

// Access Exception List
export const ACCESS_EXCLUSION_LIST_API = 'access_list_exclusion:get_list';
export const ACCESS_EXCLUSION_CREATE_API = 'access_list_exclusion:create';
export const ACCESS_EXCLUSION_MODIFY_API = 'access_list_exclusion:modify';
export const ACCESS_EXCLUSION_REMOVE_API = 'access_list_exclusion:remove';

// SIM Traffic Ranges
export const SIM_TRAFFIC_RANGES_GET_LIST = 'price_range_priority:get_list';
export const SIM_TRAFFIC_RANGES_ADD_NEW = 'price_range_priority:create';
export const SIM_TRAFFIC_RANGES_GET_POSSIBLE_LIST = 'price_range_priority:subdestination_code_get_list';
export const SIM_TRAFFIC_RANGES_REMOVE = 'price_range_priority:remove';


// Blocked destinations
export const BLOCKED_DESTINATIONS_GET_LIST_API = 'allocation_block:get_list';
export const BLOCKED_DESTINATIONS_CREATE_API = 'allocation_block:create';
export const BLOCKED_DESTINATIONS_MODIFY_API = 'allocation_block:modify';
export const BLOCKED_DESTINATIONS_MODIFY_LIST_API = 'allocation_block:modify_list';
export const BLOCKED_DESTINATIONS_REMOVE_API = 'allocation_block:remove';

// Global setting
export const GLOBAL_SETTING_GET_LIST_API = 'global_setting:get_list';
export const GLOBAL_SETTING_CREATE_API = 'global_setting:create';
export const GLOBAL_SETTING_MODIFY_API = 'global_setting:modify';

// Google OTP Quarantined ranges
export const GOOGLE_OTP_QUARANTINED_GET_LIST_API = 'price_range_quarantine:get_list';
export const GOOGLE_OTP_QUARANTINED_REMOVE_NUMBERS_API = 'price_range_quarantine:remove';

// Google OTP Allocation
export const GOOGLE_OTP_ALLOCATION_GET_LIST_API = 'otp_allocation2:get_list';
export const GOOGLE_OTP_ALLOCATION_CREATE_API = 'otp_allocation2:create';
export const GOOGLE_OTP_ALLOCATION_MODIFY_API = 'otp_allocation2:modify';
export const GOOGLE_OTP_ALLOCATION_REMOVE_API = 'otp_allocation2:remove';

// Price ranges
export const PRICE_RANGE_GENERATE_BY_SDE_API = 'price_range__generate_by_sde';
export const SMS_PRICE_RANGE_GENERATE_BY_SDE_API = "sms.price_range__generate_by_sde";
export const PRICE_RANGE_GENERATE_BY_TEMPLATE_API = 'price_range__generate_by_templates';
export const SMS_PRICE_RANGE_GENERATE_BY_TEMPLATE_API = "sms.price_range__generate_by_templates";
export const PRICE_RANGE_GENERATE_BY_NUMBER_LIST_API = 'price_range__generate_by_number_list';

export const PRICE_RANGE_GET_LIST_WITH_ACCOUNT_TRUNK_NUMBER_LIST_API = "price_range__get_list_with_account_trunk_numbers_list";
export const SMS_PRICE_RANGE_GET_LIST_WITH_ACCOUNT_TRUNK_NUMBER_LIST_API = "sms.price_range__get_list_with_account_trunk_numbers_list";

export const PRICE_RANGE_DELETE_API = 'price_range__delete';
export const PRICE_RANGE_DELETE_NOT_ALLOCATED_API = 'price_range__delete_not_allocated';

export const SMS_PRICE_RANGE_NUMBER_REVOKE_LIST_API = "sms.price_range_number:revoke_list";
export const SMS_PRICE_RANGE_NUMBER_ALLOCATE_API = "sms.price_range_number:allocate";
export const SMS_PRICE_RANGE_NUMBER_SET_TEST_NUMBER_LIST_API = "sms.price_range_number:set_test_number_list";
export const SMS_PRICE_RANGE_NUMBER_UNSET_TEST_NUMBER_LIST_API = "sms.price_range_number:unset_test_number_list";
export const SMS_PRICE_RANGE_NUMBER_SET_BLOCK_ALLOCATION_LIST_API ="sms.price_range_number:set_block_allocation_list";
export const SMS_PRICE_RANGE_NUMBER_UNSET_BLOCK_ALLOCATION_LIST_API ="sms.price_range_number:unset_block_allocation_list";



// Action logs
export const ACTION_LOGS_GET_LIST_API = 'log_action:get_list';
export const ACTION_LOGS_GET_REFERENCES_API = 'log_action:references';

// AM Activities
export const AM_ACTIVITIES_LIST_API = 'log_action:statistic_by_account_user_manager';
export const AM_ACTIVITIES_DATE_LIST_API = 'log_action:statistic_by_account_user_manager_date';

// Account Managers
export const ACCOUNT_MANAGERS_LIST_API = 'account_manager:get_list';
export const ACCOUNT_MANAGERS_DROPDOWN_LIST_API = 'account_manager:get_dropdown_list';
export const ACCOUNT_MANAGERS_DROPDOWN_LIST_ALL_API = 'account_manager:get_dropdown_list_all';
export const ACCOUNT_MANAGERS_REFERENCES_API = 'account_manager:references';
export const ACCOUNT_MANAGERS_MODIFY_API = 'account_manager:modify';
export const ACCOUNT_MANAGERS_REMOVE_API = 'account_manager:remove';
export const ACCOUNT_MANAGERS_CREATE_API = 'account_manager:create';
export const ACCOUNT_MANAGERS_BIL_API = 'account_manager_bil__get_list';
export const ACCOUNT_MANAGERS_GET_INFORMATORS_API = 'account_manager:get_informators';

// Special limit terms
export const PRICE_RANGE_QUARANTINE_SETTING_GET_LIST_API = 'price_range_quarantine_setting:get_list';
export const PRICE_RANGE_QUARANTINE_SETTING_CREATE_API = 'price_range_quarantine_setting:create';
export const PRICE_RANGE_QUARANTINE_SETTING_MODIFY_API = 'price_range_quarantine_setting:modify';
export const PRICE_RANGE_QUARANTINE_SETTING_REMOVE_API = 'price_range_quarantine_setting:remove';

// Account note
export const ACCOUNT_NOTE_GET_LIST_API = 'account_note:get_list';
export const ACCOUNT_NOTE_CREATE_API = 'account_note:create';
export const ACCOUNT_NOTE_DEACTIVATE_API = 'account_note:deactivate';

export const REGISTRATION_CREATE_API = 'registration:create';
export const REGISTRATION_GET_LIST_API = 'registration:get_list';
export const REGISTRATION_GET_COUNT_API = 'registration:get_count';
export const REGISTRATION_APPROVE_API = 'registration:approve';
export const REGISTRATION_REJECT_API = 'registration:reject';
export const REGISTRATION_ACCOUNT_API = 'registration:account';

// auth
export const ACCOUNT_USER_LOGIN_MASTER_API = 'account_user_login_master';

// postback logs
export const POSTBACK_LOGS_GET_LIST_API = 'sms.postback:get_list';
export const POSTBACK_LOGS_RESEND_LIST_API = 'sms.postback:resend_list';

// client auth
export const ACCOUNT_GET_DIALER_API = 'account:get_join';
export const ACCOUNT_GET_TEST_DIALER_API = 'account:get_join_by_test_user';
export const ACCOUNT_ALLOCATION_BAN_CLEAN_API = 'account:allocation_ban__clean';
export const ACCOUNT_USER_GET_SESSION_DIALER_API = 'account_user:get_session';

// client auth numbers
export const AUTH_NUMBERS_GET_LIST_DIALER_API = "auth_number:get_list_client";

// client access list filters
export const ACCESS_LIST_FILTERS_GET_NOTIFICATION_DIALER_API = "access_list_notification:get";
export const ACCESS_LIST_FILTERS_MODIFY_NOTIFICATION_DIALER_API = "access_list_notification:modify";
export const ACCESS_LIST_FILTERS_GET_LIST_DIALER_API = "access_list_filter:get_list";
export const ACCESS_LIST_FILTERS_CREATE_DIALER_API = "access_list_filter:create";
export const ACCESS_LIST_FILTERS_MODIFY_DIALER_API = "access_list_filter:modify";
export const ACCESS_LIST_FILTERS_REMOVE_DIALER_API = "access_list_filter:remove";


// client allocation
export const ALLOCATION_PRICE_RANGE_DIALER_API = 'allocation:price_range';
export const ALLOCATION_NUMBER_LIST_DIALER_API = 'allocation:number_list_by_account_user';
export const ALLOCATION_TEMPLATE_DIALER_API = 'allocation:template_by_account_user';
export const ALLOCATION_GOOGLE_OTP_DIALER_API = 'allocation:google_otp_by_account_user';
export const ALLOCATION_SUBDESTINATION_DIALER_API = 'allocation:subdestination_by_account_user';
export const ALLOCATION_INCORPORATED_DIALER_API = 'allocation:incorporated';
export const ALLOCATION_INCORPORATED_MASS_API = 'allocation:incorporated_mass_by_manager';

// SMS client allocation
export const SMS_ALLOCATION_PRICE_RANGE_DIALER_API = 'sms.allocation:price_range';
export const SMS_ALLOCATION_NUMBER_LIST_DIALER_API = 'sms.allocation:number_list_by_account_user';
export const SMS_ALLOCATION_TEMPLATE_DIALER_API = 'sms.allocation:template_by_account_user';
export const SMS_ALLOCATION_GOOGLE_OTP_DIALER_API = 'sms.allocation:google_otp_by_account_user';
export const SMS_ALLOCATION_SUBDESTINATION_DIALER_API = 'sms.allocation:subdestination_by_account_user';
export const SMS_ALLOCATION_INCORPORATED_DIALER_API = 'sms.allocation:incorporated';
export const SMS_ALLOCATION_INCORPORATED_MASS_API = 'sms.allocation:incorporated_mass_by_manager';


// admin allocation
export const ALLOCATION_TEMPLATE_API = 'allocation:template';
export const ALLOCATION_SUBDESTINATION_API = 'allocation:subdestination';
export const ALLOCATION_MASS_API = 'allocation:mass';
export const ALLOCATION_NUMBER_LIST_API = 'allocation:number_list';
export const ALLOCATION_TEMPLATE_LIST_API = 'allocation:template_list';
export const ALLOCATION_NUMBER_SEQUENCE_API ='allocation:number_sequence';
export const ALLOCATION_GOOGLE_OTP_API = 'allocation:google_otp';

export const ALLOCATION_INCORPORATED_BY_MANAGER_API = 'allocation:incorporated_by_manager';
export const ALLOCATION_PRICE_RANGE_NUMBER_LIST_API = 'allocation:price_range_number_list';


// SMS admin allocation
export const SMS_ALLOCATION_TEMPLATE_API = 'sms.allocation:template';
export const SMS_ALLOCATION_SUBDESTINATION_API = 'sms.allocation:subdestination';
export const SMS_ALLOCATION_MASS_API = 'sms.allocation:mass';
export const SMS_ALLOCATION_NUMBER_LIST_API = 'sms.allocation:number_list';
export const SMS_ALLOCATION_TEMPLATE_LIST_API = 'sms.allocation:template_list';
export const SMS_ALLOCATION_NUMBER_SEQUENCE_API ='sms.allocation:number_sequence';
export const SMS_ALLOCATION_GOOGLE_OTP_API = 'sms.allocation:google_otp';

// SMS admin allocation GAN
export const SMS_ALLOCATION_GAN_API = 'sms.allocation:gan';
export const SMS_ALLOCATION_GAN_BY_MANAGER_API = 'sms.allocation:gan_by_manager';
export const SMS_ALLOCATION_GAN_MASS_BY_MANAGER_API = 'sms.allocation:gan_mass_by_manager';


export const SMS_ALLOCATION_INCORPORATED_BY_MANAGER_API = 'sms.allocation:incorporated_by_manager';
export const SMS_ALLOCATION_PRICE_RANGE_NUMBER_LIST_API = 'sms.allocation:price_range_number_list';

export const methodByService = {
    'template': {
        [true]: ALLOCATION_TEMPLATE_API,
        [false]: SMS_ALLOCATION_TEMPLATE_API
    },
    'subdestination': {
        [true]: ALLOCATION_SUBDESTINATION_API,
        [false]: SMS_ALLOCATION_SUBDESTINATION_API
    },
    'mass' : {
        [true]: ALLOCATION_MASS_API,
        [false]: SMS_ALLOCATION_MASS_API
    },
    'number_list' : {
        [true]: ALLOCATION_NUMBER_LIST_API,
        [false]: SMS_ALLOCATION_NUMBER_LIST_API
    },
    'template_list' : {
        [true]: ALLOCATION_TEMPLATE_LIST_API,
        [false]: SMS_ALLOCATION_TEMPLATE_LIST_API
    },
    'number_sequence' : {
        [true]: ALLOCATION_NUMBER_SEQUENCE_API,
        [false]: SMS_ALLOCATION_NUMBER_SEQUENCE_API
    },
    'google_otp' : {
        [true]: ALLOCATION_GOOGLE_OTP_API,
        [false]: SMS_ALLOCATION_GOOGLE_OTP_API
    },
    'incorporated_by_manager': {
        [true]: ALLOCATION_INCORPORATED_BY_MANAGER_API,
        [false]: SMS_ALLOCATION_GAN_BY_MANAGER_API
    },
    'price_range_number_list': {
        [true]: ALLOCATION_PRICE_RANGE_NUMBER_LIST_API,
        [false]: SMS_ALLOCATION_PRICE_RANGE_NUMBER_LIST_API
    }
}

// test calls
export const TEST_CALLS_LIST_DIALER_API = 'cdr_full:test_get_list';

// SMS test calls
export const SMS_TEST_CALLS_LIST_DIALER_API = 'sms.mdr_full:test_get_list';

// live calls
export const LIVE_CALLS_LIST_DIALER_API = 'live_call:get_list_by_account_user';

// cdr
export const CDR_LIST_DIALER_API = 'cdr_full:get_list';
export const SMS_MDR_LIST_DIALER_API = 'sms.mdr_full:get_list';
export const TRAFFIC_STAT_LIST_DIALER_API = 'cdr_full:group_get_list';
export const SMS_TRAFFIC_STAT_LIST_DIALER_API = 'sms.mdr_full:group_get_list';

// price
export const RATECARD_LIST_ACCOUNT_DIALER = 'ratecard_standart:get_account_price';
export const RATECARD_LIST_OTP_ACCOUNT_DIALER = 'ratecard_otp:get_account_price';
export const RATECARD_LIST_INCORPORATED_ACCOUNT_DIALER = 'ratecard_incorporated:get_account_price';
export const RATECARD_LIST_DEFAULT_DIALER = 'ratecard_standart:get_default_price';
export const RATECARD_LIST_OTP_DEFAULT_DIALER = 'ratecard_otp:get_default_price';
export const RATECARD_LIST_INCORPORATED_DEFAULT_DIALER = 'ratecard_incorporated:get_default_price';

// access
export const ACCESS_LIST_ACCOUNT_PRICE_DIALER_API = 'access_list__get_list:account_price';
export const ACCESS_LIST_OTP_ACCOUNT_PRICE_DIALER_API = 'access_list_otp__get_list:account_price';
export const ACCESS_LIST_DEFAULT_PRICE_DIALER_API = 'access_list__get_list:default_price';
export const ACCESS_LIST_OTP_DEFAULT_PRICE_DIALER_API = 'access_list_otp__get_list:default_price';

// access filter
export const ACCESS_LIST_FILTER_GET_LIST_API = 'access_list_filter:get_list';

// cli
export const CLI_LIST_DIALER_API = 'cdr_short__cli_lookup';
export const CLI_LIST_OTP_DIALER_API = 'cdr_short__cli_lookup_otp';

// SMS cli
export const SMS_CLI_LIST_DIALER_API = 'sms.mdr_short__cli_lookup';
export const SMS_CLI_LIST_OTP_DIALER_API = 'sms.mdr_short__cli_lookup_otp';

// api page
export const API_PAGE_LIST_DIALER_API = 'account_apikey:get_list';
export const API_GENERATE_NEW_DIALER_API = 'account_apikey:generate';

// IVR
export const IVR_SOUND_LIST_API = 'ivr_sound:get_list'; // файл загружается через multipart/form-data обычный json передается через секцию json
export const IVR_SOUND_CREATE_API = 'ivr_sound:create';
export const IVR_SOUND_MODIFY_API = 'ivr_sound:modify'; // файл загружается через multipart/form-data обычный json передается через секцию json
export const IVR_SOUND_REMOVE_API = 'ivr_sound:remove';

export const IVR_LIST_API = 'ivr:get_list';
export const IVR_CREATE_API = 'ivr:create';
export const IVR_MODIFY_API = 'ivr:modify';
export const IVR_REMOVE_API = 'ivr:remove';

export const IVR_ACTION_TYPE_LIST_API = 'ivr:get_ivr_action_type_list'; // типы действий

export const IVR_CLI_LIST_API = 'ivr_cli:get_list';
export const IVR_CLI_CREATE_MODIFY_API = 'ivr_cli:create_modify';
export const IVR_CLI_CREATE_API = 'ivr_cli:create';
export const IVR_CLI_MODIFY_API = 'ivr_cli:modify';
export const IVR_CLI_REMOVE_API = 'ivr_cli:remove';

export const IVR_DEFAULT_GET_LIST_API = "ivr:get_default_list";
export const IVR_SET_DEFAULT_LIST_API = "ivr:set_default_list";
export const IVR_RECALC_ALL_API = "ivr__recalc_all";
export const IVR_LISTEN_API = "ivr:listen";


// Manager Account Users (Admin role only)
export const MANAGER_ACCOUNT_USER_GET_LIST = 'account_user:get_list_in_manager_account';
export const MANAGER_ACCOUNT_USER_GET_DROPDOWN_LIST = 'account_user:get_dropdown_list_in_manager_account';
export const MANAGER_ACCOUNT_USER_CREATE = 'account_user:create_in_manager_account';
export const MANAGER_ACCOUNT_USER_MODIFY = 'account_user:modify_in_manager_account';
export const MANAGER_ACCOUNT_USER_REMOVE = 'account_user:remove_in_manager_account';

// SMS Routing
export const ROUTING_GET_LIST_API = 'sms.routing:get_list';
export const ROUTING_CREATE_API = 'sms.routing:create';
export const ROUTING_MODIFY_API = 'sms.routing:modify';
export const ROUTING_REMOVE_API = 'sms.routing:remove';

// Self Allocation
export const ACCOUNT_SERVICE_PLAN_LIST_API = 'account_service_plan:get_list';
export const ACCOUNT_SERVICE_PLAN_SET_LIST_API = 'account_service_plan:set_list';
export const ACCOUNT_SERVICE_PLAN_DEACTIVATE_TRUNK_LIST = 'account_service_plan:deactivate_trunk_list';

// Account Changes
export const ACCOUNT_CHANGES_LIST_API = 'account_changes:get_list';

// News
export const NEWS_LIST_API = 'news:get_list';
export const NEWS_LIST_DIALER_API = 'news:get_list_dialer';
export const NEWS_CREATE_API = 'news:create';
export const NEWS_MODIFY_API = 'news:modify';
export const NEWS_REMOVE_API = 'news:remove';
export const NEWS_SET_READ_API = 'news:set_read';
export const NEWS_NEW_COUNT_LAST_API = 'news:get_new_count_last';

// News Dialer Groups
export const DIALER_GROUPS_LIST_API = 'news_agroup:get_list';
export const DIALER_GROUPS_CREATE_API = 'news_agroup:create';
export const DIALER_GROUPS_MODIFY_API = 'news_agroup:modify';
export const DIALER_GROUPS_ACCOUNTS_ADD_API = 'news_agroup:add_account_list';
export const DIALER_GROUPS_ACCOUNTS_LIST_API = 'news_agroup:account_get_list';
export const DIALER_GROUPS_ACCOUNTS_REMOVE_API = 'news_agroup:remove_account_list';

export const TRUNK_LIST_FOR_ACCOUNTS_METHOD = 'trunk_list_for_accounts';
export const SMS_TRUNK_LIST_FOR_ACCOUNTS_METHOD = 'sms.trunk_list_for_accounts';

// Global access numbers
export const INCORPORATED_GROUP_LIST_API = 'incorporated:get_group_list';
export const INCORPORATED_GROUP_MODIFY_API = 'incorporated:group_modify';
export const INCORPORATED_GROUP_PRICE_MODIFY_API = 'incorporated:group_price_modify';
export const INCORPORATED_GROUP_UPLOAD_EXIST_API = 'incorporated:group_upload_exists';
export const INCORPORATED_GROUP_UPLOAD_NEW_API = 'incorporated:group_upload_new';
export const INCORPORATED_GROUP_MULTI_UPLOAD_CSV_API = 'incorporated:multi_upload_csv';
export const INCORPORATED_GROUP_REMOVE_API = 'incorporated:group_remove';
export const INCORPORATED_GROUP_REMOVE_ALLOCATED_API = 'incorporated:group_remove_allocated';
export const INCORPORATED_GROUP_DEFAULT_RATE_LIST_API = 'incorporated:group_get_default_rate_list';
export const INCORPORATED_SUPPLIER_LIST_API = 'incorporated:get_supplier_list';

// Incorporated actions
export const INCORPORATED_REVOKE_API = 'incorporated_number:revoke';
export const INCORPORATED_SET_TEST_API = 'incorporated_number:set_test_number';
export const INCORPORATED_UNSET_TEST_API = 'incorporated_number:unset_test_number';
export const INCORPORATED_SET_BLOCK_API = 'incorporated_number:set_block_allocation';
export const INCORPORATED_UNSET_BLOCK_API = 'incorporated_number:unset_block_allocation';
export const INCORPORATED_REMOVE_API = 'incorporated_number:remove';


// Incorporated actions list
export const INCORPORATED_NUMBER_LIST_API = 'incorporated_number:get_list';
export const INCORPORATED_SET_BLOCK_LIST_API = 'incorporated:set_block_allocation_list';
export const INCORPORATED_UNSET_BLOCK_LIST_API = 'incorporated:unset_block_allocation_list';
export const INCORPORATED_SET_TEST_LIST_API = 'incorporated:set_test_number_list';
export const INCORPORATED_UNSET_TEST_LIST_API = 'incorporated:unset_test_number_list';

// Price admin
export const SERVICE_PLAN_PRICE_UPLOAD_CSV_API = 'service_plan_price:upload_csv';
export const ACCOUNT_PRICE_GET_LIST_API = 'account_price:get_list';
export const ACCOUNT_PRICE_CREATE_API = 'account_price:create';
export const ACCOUNT_PRICE_MODIFY_API = 'account_price:modify';
export const ACCOUNT_PRICE_REMOVE_API = 'account_price:remove';
export const ACCOUNT_PRICE_PRICE_UPLOAD_CSV_API = 'account_price:upload_csv';


// Mass revoke
export const REVOKE_MASS_LIST_API = 'revoke_mass:get_list';
export const REVOKE_MASS_REVOKE_API = 'revoke_mass:revoke';
export const REVOKE_MASS_REVOKED_LIST_API = 'revoke_mass:revoked_trunk_number_get_list';

export const SMS_REVOKE_MASS_REVOKE_API = 'sms.revoke_mass:revoke';

export const SMS_REVOKE_MASS_LIST_API = 'sms.revoke_mass:get_list';

// Global Access Numbers Suppliers
export const GAN_SUPPLIERS_LIST_API = 'supplier:get_list';
export const GAN_SUPPLIERS_COMMON_LIST_API = 'supplier:get_dropdown_list';
export const GAN_SUPPLIERS_MODIFY_API = 'supplier:modify';
export const GAN_SUPPLIERS_ACCESS_LIST_UPLOAD_API = 'supplier:access_list_upload';


// Traffic Limits
export const TRAFFIC_LIMITS_LIST_API = 'trafficlimit:get_list';
export const TRAFFIC_LIMITS_CREATE_API = 'trafficlimit:create';
export const TRAFFIC_LIMITS_MODIFY_API = 'trafficlimit:modify';
export const TRAFFIC_LIMITS_REMOVE_API = 'trafficlimit:remove';


// Trunk
export const TRUNK_LIST_API = 'trunk:get_list';
export const TRUNK_LIST_SMS_API = 'sms.trunk:get_list';
export const TRUNK_CREATE_API = 'trunk:create';
export const TRUNK_CREATE_SMS_API = 'sms.trunk:create';
export const TRUNK_MODIFY_API = 'trunk:modify';
export const TRUNK_MODIFY_SMS_API = 'sms.trunk:modify';
export const TRUNK_REMOVE_API= "trunk:remove";
export const SMS_TRUNK_REMOVE_API = "sms.trunk:remove";
export const SMS_TRUNK_REVOKE_ALL_NUMBERS_API = "sms.trunk:revoke_all_numbers";


// Accesses
export const ACCESS_LIST_GET_LIST_API = 'access_list__get_list';
export const ACCESS_LIST_EXTENDED_GET_LIST_API = "access_list_extended__get_list";

// Manual Accesses
export const MANUAL_ACCESS_LIST_GET_LIST_API = 'access_list_manual:get_list';
export const MANUAL_ACCESS_LIST_CREATE_API = 'access_list_manual:create';
export const MANUAL_ACCESS_LIST_CANCEL_API = 'access_list_manual:cancel';
export const MANUAL_ACCESS_LIST_REMOVE_API = 'access_list_manual:remove';

// SMS Manual Accesses
export const SMS_MANUAL_ACCESS_LIST_GET_LIST_API = 'sms.access_list_manual:get_list';
export const SMS_MANUAL_ACCESS_LIST_CREATE_API = 'sms.access_list_manual:create';
export const SMS_MANUAL_ACCESS_LIST_CANCEL_API = 'sms.access_list_manual:cancel';
export const SMS_MANUAL_ACCESS_LIST_REMOVE_API = 'sms.access_list_manual:remove';

// Trunk Number
export const TRUNK_NUMBER_GET_LIST_API = 'trunk_number:get_list';
export const SMS_TRUNK_NUMBER_CHANGE_RATE_API = "sms.trunk_number__filter:change_rate";
export const SMS_TRUNK_NUMBER_REVOKE_API = "sms.trunk_number__filter:revoke";
export const SMS_TRUNK_NUMBER_MOVE_TO_TRUNK_API = "sms.trunk_number__filter:move_to_trunk";
// E164
export const E164_GET_LIST_API = 'e164:get_list';

// E212
export const E212_GET_LIST_API = 'sms.e212_e164:get_list';
export const E212_OPERATOR_GET_LIST_API = 'sms.e212_e164:operator_get_list';

// ----- SMS -----

// SMS accesses
export const SMS_ACCESS_LIST_GET_LIST_API = 'sms.access_list__get_list';
export const SMS_ACCESS_LIST_DEFAULT_PRICE_DIALER_API = 'sms.access_list__get_list:default_price';
export const SMS_ACCESS_LIST_ACCOUNT_PRICE_DIALER_API = 'sms.access_list__get_list:account_price';
export const SMS_ACCESS_LIST_EXTENDED_GET_LIST_API = "sms.access_list_extended__get_list";


// SMS Access Exception List
export const SMS_ACCESS_EXCLUSION_LIST_API = 'sms.access_list_exclusion:get_list';
export const SMS_ACCESS_EXCLUSION_CREATE_API = 'sms.access_list_exclusion:create';
export const SMS_ACCESS_EXCLUSION_MODIFY_API = 'sms.access_list_exclusion:modify';
export const SMS_ACCESS_EXCLUSION_REMOVE_API = 'sms.access_list_exclusion:remove';


// SMS Blocked destinations
export const SMS_BLOCKED_DESTINATIONS_GET_LIST_API = 'sms.allocation_block:get_list';
export const SMS_BLOCKED_DESTINATIONS_CREATE_API = 'sms.allocation_block:create';
export const SMS_BLOCKED_DESTINATIONS_MODIFY_API = 'sms.allocation_block:modify';
export const SMS_BLOCKED_DESTINATIONS_MODIFY_LIST_API = 'sms.allocation_block:modify_list';
export const SMS_BLOCKED_DESTINATIONS_REMOVE_API = 'sms.allocation_block:remove';

// SMS Traffic Limits
export const SMS_TRAFFIC_LIMITS_LIST_API = 'sms.traffic_limit:get_list';
export const SMS_TRAFFIC_LIMITS_CREATE_API = 'sms.traffic_limit:create';
export const SMS_TRAFFIC_LIMITS_MODIFY_API = 'sms.traffic_limit:modify';
export const SMS_TRAFFIC_LIMITS_REMOVE_API = 'sms.traffic_limit:remove';

// Rules
export const RULE_API_GET_LIST = 'rule:get_list';
export const RULE_API_CREATE = 'rule:create';
export const RULE_API_MODIFY = 'rule:modify';
export const RULE_API_REMOVE = 'rule:remove';


// SMS OTP Rules
export const OTP_RULE_API_GET_LIST = 'sms.otp_rule:get_list';
export const OTP_RULE_API_CREATE = 'sms.otp_rule:create';
export const OTP_RULE_API_MODIFY = 'sms.otp_rule:modify';
export const OTP_RULE_API_REMOVE = 'sms.otp_rule:remove';

// SMS OTP SEARCH
export const SMS_SEARCH_GET_LIST = 'sms.otp_rule:search';

// SMS PRICE
export const SMS_RATECARD_LIST_ACCOUNT_DIALER = 'sms.ratecard_standart:get_account_price';
export const SMS_RATECARD_LIST_INCORPORATED_ACCOUNT_DIALER = 'sms.ratecard_incorporated:get_account_price';
export const SMS_RATECARD_LIST_DEFAULT_DIALER = 'sms.ratecard_standart:get_default_price';
export const SMS_RATECARD_LIST_INCORPORATED_DEFAULT_DIALER = 'sms.ratecard_incorporated:get_default_price';


// SUB ACCOUNTS
export const SUBACC_CREATE_RESELLER_HOST_METHOD = "subacc_reseller:host_create";
export const SUBACC_GET_RESELLER_HOST_LIST_METHOD = "subacc_reseller:host_list";
export const SUBACC_MODIFY_RESELLER_HOST_METHOD = "subacc_reseller:host_modify";

export const SUBACC_SET_RESELLER_METHOD = "subacc_reseller:set";
export const SUBACC_UNSET_RESELLER_METHOD = "subacc_reseller:unset";

export const SUBACC_GET_ACCESS_LIST_METHOD = "subacc_reseller:access_list";

export const SUBACC_GET_SUB_ACCOUNT_LIST_METHOD = "subacc_reseller:subacc_list";
export const SUBACC_CREATE_SUB_ACCOUNT_METHOD = "subacc_reseller:subacc_create";
export const SUBACC_MODIFY_SUB_ACCOUNT_METHOD = "subacc_subacc:modify";
export const SUBACC_REMOVE_SUB_ACCOUNT_METHOD = "subacc_subacc:remove";
export const SUBACC_RESTORE_SUB_ACCOUNT_METHOD = "subacc_subacc:restore";

export const SUBACC_GET_RESELLER_TEMPLATE_LIST_METHOD = "subacc_reseller:template_list";

export const SUBACC_GET_RESELLER_SUBACC_CDR_LIST_METHOD = "subacc_reseller_subacc:cdr_list";
export const SUBACC_GET_RESELLER_SUBACC_CDR_REPORT_LIST_METHOD = "subacc_reseller_subacc:cdr_report_list";
export const SUBACC_GET_RESELLER_SUBACC_INVOICE_LIST_METHOD = "subacc_reseller_subacc:invoice_list";
export const SUBACC_GET_RESELLER_SUBACC_NUMBER_LIST_METHOD = "subacc_reseller_subacc:number_list";
export const SUBACC_GET_RESELLER_SUBACC_PAYMENT_LIST_METHOD = "subacc_reseller_subacc:payment_list";

export const SUBACC_GET_SUBACC_METHOD = "subacc_subacc:get";
export const SUBACC_ALLOCATE_SUB_ACCOUNT_METHOD = "subacc_subacc:allocate";
export const SUBACC_CREATE_SUB_ACCOUNT_PAYMENT_METHOD = "subacc_subacc:payment_create";
export const SUBACC_REMOVE_SUB_ACCOUNT_PAYMENT_METHOD = "subacc_subacc:payment_remove";
export const SUBACC_GET_NUMBER_LIST_METHOD = "subacc_subacc:number_list";
export const SUBACC_CHANGE_RATE_NUMBER_LIST_METHOD = "subacc_subacc:change_rate";
export const SUBACC_REVOKE_NUMBER_LIST_METHOD = "subacc_subacc:revoke";
export const SUBACC_EXPORT_NUMBER_LIST_METHOD = "subacc_subacc:export";

export const SUBACC_GET_USER_LIST_METHOD = "subacc_subacc:user_list"
export const SUBACC_CREATE_USER_METHOD = "subacc_subacc:user_create";
export const SUBACC_MODIFY_USER_METHOD = "subacc_user:modify";
export const SUBACC_LOGIN_USER_METHOD =  "subacc_user:login";
export const SUBACC_REMOVE_USER_METHOD = "subacc_user:remove";

export const SUBACC_TRUNK_MODIFY_METHOD = "subacc_trunk:modify";
export const SUBACC_TRUNK_GET_NUMBER_LIST_METHOD = "subacc_trunk:number_list";
export const SUBACC_TRUNK_ALLOCATE_METHOD = "subacc_trunk:allocate";
export const SUBACC_TRUNK_CHANGE_RATE_METHOD = "subacc_trunk:change_rate";
export const SUBACC_TRUNK_REVOKE_METHOD = "subacc_trunk:revoke";

export const SUBACC_HOST_GET_LIST_METHOD = "subacc_reseller:host_list";
export const SUBACC_HOST_CREATE_METHOD = "subacc_reseller:host_create";
export const SUBACC_HOST_MODIFY_METHOD = "subacc_reseller:host_modify";