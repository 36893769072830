import React from "react";
import {Spacer} from "../../../../components/base/Spacer";
import styled from "styled-components";
import {Button} from "rsuite";
import {Link} from 'react-router-dom';

export default (
    {
        activeIvr,
        serviceList,
        accountList,
        setActiveTab,
    }
) => {

    return (
        <StyledFooter>
            <Spacer size={20} />
            {serviceList &&
            <p>
                Used for Services:
                <StyledButtonCli
                    appearance="link"
                    onClick={() => {
                        localStorage.setItem("active-ivr-name", activeIvr.name);
                        setActiveTab("special-cli-ivrs");
                    }}
                >
                    {serviceList.join(", ")}
                </StyledButtonCli>
            </p>
            }
            <Spacer size={10} />
            {accountList &&
            <p>
                Used for dialers: 
                <AccountListStyled>{accountList.map((account, idx) => {
                    const accountId = account.id;
                    const accountName = account.name;
                    return <>
                        <StyledLink
                            appearance="link"
                            to={`/accounts/view/${accountId}`}
                        >
                            {accountName}
                        </StyledLink>
                        {idx !== accountList.length - 1 ? ", " : ""}
                    </>
                })}</AccountListStyled>
            </p>
            }
        </StyledFooter>
    )
};


const StyledFooter = styled.div`
    margin-top: auto;
`;

const AccountListStyled = styled.span`
    && {
        margin-left: 5px;
        max-width: 600px;
        text-align: left;
        white-space: normal;
        vertical-align: top;
        display: inline-block;
    }
`;

const StyledButtonCli = styled(Button)`
    &&& {
        text-align: left;
        white-space: normal;
        color: var(--color-brand1);
        max-width: 400px;
        vertical-align: top;
        padding: 0 10px;
    }
`;

const StyledLink = styled(Link)`
    &&& {
        color: var(--color-brand1);
    }
`;