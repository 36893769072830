import {
    createTrafficLimit,
    getTrafficLimitsList,
    modifyTrafficLimit,
    removeTrafficLimit,
    clearAccumulateData,
    createSupplierTrafficLimit,
    modifySupplierTrafficLimit,
    removeSupplierTrafficLimit,
    getSupplierTrafficLimitsList,
    getAccumulateDataFromRequestedList
} from "actions/account_traffic_limits";
import {
    getSupplierAccountTrunk
} from "actions/accounts";
import {
    getSupplierGroups,
    getSupplierPlanList,
    getSupplierPrefixesDropdown
} from "actions/suppliers";
import {
    getE212DropdownList
} from "actions/e212";
import {getReferencesForReports} from "actions/references";
import {connect} from "react-redux";
import FormHOC from "hoc/FilltersForm";
import TrafficLimits from "./TrafficLimits";


const mapStateToProps = ({auth, e212, accounts, suppliers, references, roles, account_traffic_limits}) => ({
    account: accounts.accountView,
    call_duration_max: account_traffic_limits.call_duration_max,
    data: account_traffic_limits.items,
    accData: account_traffic_limits.acc_items,
    allocationBanExpiredDate: account_traffic_limits.allocation_ban_expired_at,
    loading: account_traffic_limits.loading,
    mccList: references.mcc_list,
    service: auth.service,
    subdestinationList: references.subdestination_list,
    destinationList: references.destination_list,
    worldzoneList: references.worldzone_list,
    servicePlanList: references.service_plan_list,
    countryList: references.country_list,

    roleList: roles.role_list,

    transitTrunkList: accounts.accountSupplierTransitTrunks,
    suppliersPrefixList: suppliers.suppliersPrefixList,
    suppliersGroupList: suppliers.suppliersGroupList,
    suppliersPlanList: suppliers.suppliersPlanList,
    e212List: e212.list,

});

const mapDispatchToProps = {
    getSupplierTrafficLimitsList,
    getAccumulateDataFromRequestedList,
    getSupplierPrefixesDropdown,
    getReferencesForReports,
    getSupplierAccountTrunk,
    getTrafficLimitsList,
    getE212DropdownList,
    getSupplierPlanList,
    getSupplierGroups,
    createTrafficLimit,
    modifyTrafficLimit,
    removeTrafficLimit,
    clearAccumulateData,
    createSupplierTrafficLimit,
    modifySupplierTrafficLimit,
    removeSupplierTrafficLimit
};

export default connect(mapStateToProps, mapDispatchToProps)(FormHOC(TrafficLimits));