import React, {useMemo, useEffect, useState} from "react";
import styled, {css} from "styled-components";
import Modal from "components/Modal";
import {Input, InputNumber, Checkbox, Schema} from "rsuite";
import {useWindowWidth} from "hooks";
import FormCheckbox from "components/Form/FormCheckbox";
import TableEditCellsNew from "components/Table/TableEditCells/TableEditCellsNew";
import TextareaAutosize from "react-textarea-autosize";

const {StringType, NumberType} = Schema.Types;

export default ({
    onClose,
    isGanTrunks,
    isTransitTrunks,
    isSupervisorSms,
    currentSupplier,
    suppliersPlanList,
    // createSupplierPlan,
    // modifySupplierPlan,
    // removeSupplierPlan,
    getSupplierPlanList,
    paymentTermsList,
    ...props
}) => {
    let loadingTimer = null;

    const windowWidth = useWindowWidth();

    const supplierType = isTransitTrunks ? "transit" : isGanTrunks ? "gan" : null;

    const filteredSuppliersPlanList = suppliersPlanList.filter(plan => !supplierType || plan.type === supplierType);

    const tarifficCheckObj = useMemo(() => {
        return suppliersPlanList.reduce((result, item) => {
            result[item.id] = {tariffication_all: item.tariffication_all};
            return result;
        }, {})
    }, [suppliersPlanList]);

    const [editKeys, setEditKeys] = useState([]);
    
    const [createExtraData, setCreateExtraData] = useState({tariffication_all: false});
    const [supplierPlanState, setSupplierPlanState] = useState(tarifficCheckObj);

    useEffect(() => {
        if (!supplierPlanState || supplierPlanState && !Object.keys(supplierPlanState).length) {
            setSupplierPlanState(tarifficCheckObj);
        }
    }, [tarifficCheckObj]);

    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});

    const discountModels = {
        // ...paymentTermsList.reduce((result, paymentTerm) => {
  
        //     result[`discount_${paymentTerm.name}`] = NumberType()
        //         .min(0, "Minimum 0")
        //         .max(100, "Maximum 100")
        //         .isRequired("This field is required");
            
        //     return result;
        // }, {})
    };

    useEffect(() => {
        clearTimeout(loadingTimer);

        loadingTimer = setTimeout( () => {
            setLoading(false);
        }, 400);
    }, [suppliersPlanList]);

    const nameModel = {
        name: StringType()
            .minLength(3, "Minimum 3")
            .maxLength(40, "Maximum 40")
            .isRequired("This field is required")
    };

    const commentAdminModel = {
        comment_admin: StringType()
            .minLength(3, "Minimum 3")
            .maxLength(8190, "Maximum 8190")
    };

    const commentDialerModel = {
        comment_dialer: StringType()
            .minLength(3, "Minimum 3")
            .maxLength(8190, "Maximum 8190")
    };

    const formModel = Schema.Model({
        name: StringType()
            .minLength(3, "Minimum 3")
            .maxLength(40, "Maximum 40")
            .isRequired("This field is required"),
        comment_admin: StringType()
            .minLength(3, "Minimum 3")
            .maxLength(8190, "Maximum 8190"),
        comment_dialer: StringType()
            .minLength(3, "Minimum 3")
            .maxLength(8190, "Maximum 8190")
        // ...(isGanTrunks ? discountModels : [])
    });

    const columns = useMemo(() => [
        {
            id: "name",
            name: "Trunk Type",
            dataKey: "name",
            placeholder: "Trunk Type",
            accepter: Input,
            model: Schema.Model(nameModel),

            flexGrow: 1
        },
        {
            id: "comment_admin",
            name: "Admin comment",
            dataKey: "comment_admin",
            placeholder: "Admin comment",
            accepter: FieldTextArea,
            model: Schema.Model(commentAdminModel),
            fixTextareaValue: true,
            flexGrow: 2,
        },
        {
            id: "comment_dialer",
            name: "Dialer comment",
            dataKey: "comment_dialer",
            placeholder: "Dialer comment",
            accepter: FieldTextArea,
            model: Schema.Model(commentDialerModel),
            fixTextareaValue: true,
            flexGrow: 2,
        },
        ...(isGanTrunks ? paymentTermsList.map(paymentTerm => {
            return {
                id: `discount_${paymentTerm.name}`,
                name: `Discount for ${paymentTerm.name}`,
                dataKey: `discount_${paymentTerm.name}`,
                placeholder: "Discount",
    
                // minWidth: 300,
                width: 120,
                flexGrow: 1,
                accepter: InputNumber,
                model: Schema.Model({[`discount_${paymentTerm.name}`]: discountModels[`discount_${paymentTerm.name}`]}),
    
                defaultValue: ({discount_list = []}) => {
                    const currentDiscount = discount_list.find(value => value.pt_key === paymentTerm.pt_key);
                    return currentDiscount ? parseInt(currentDiscount.discount*100.0) : null;
                },
                value: ({discount_list = []}) => {
                    const currentDiscount = discount_list.find(value => value.pt_key === paymentTerm.pt_key);
                    return currentDiscount ? `${parseInt(currentDiscount.discount*100)}%` : "";
                },
            }
        }) : []),
        {
            id: "tariffication_all",
            name: "Bill all incoming SMS",
            dataKey: "tariffication_all",
            // showLabelOnCreate: true,
            // label: "Tariffication",

            customField: true,
            accepter: Checkbox,

            onChange: (_, checked) => {
                setCreateExtraData({tariffication_all: checked});
            },

            value: ({id}) => {
                const notEditing = !editKeys.find(key => key === id);
                const currentSupplierPlan = filteredSuppliersPlanList.find(item => item.id === id);
                const currentSupplierPlanState = supplierPlanState[id];

                const isChecked = currentSupplierPlanState ? currentSupplierPlanState?.tariffication_all : currentSupplierPlan?.tariffication_all

                return (
                    <StyledCheckbox 
                        styledIntend={notEditing}
                        disabled={notEditing}
                        defaultChecked={isChecked}
                        checked={isChecked}
                        onChange={(_, checked) => {
                            const supplierPlanStateClone = {...supplierPlanState};

                            supplierPlanStateClone[id] = {...supplierPlanStateClone[id], tariffication_all: checked};
                            setSupplierPlanState(supplierPlanStateClone);
                        }}
                    />
                )
            },
            width: 125,
            align: "center"
        }
    ], [supplierPlanState, filteredSuppliersPlanList]);
    
    const rowIdKey = "id";

    return (
        <>
            <Modal
                {...{onClose}}
                show={true}
                disabled ={disabled}
                extraDisabled={Object.keys(error).length}
                title="Manage Trunk Type"
                footer={false}
                width={windowWidth >= 1260 ? 1260 : windowWidth}
            >
                <StyledTable
                    rowIdKey={rowIdKey}
                    loading={loading}
                    virtualized
                    data={filteredSuppliersPlanList}
                    height={480}
                    columns={columns}
                    headerHeight={40}
                    rowHeight={66}
                    autoHeight={false}
                    formModel={formModel}
                    addButtonWidth={124}
                    shouldUpdateScroll={true}
                    setEditKeys={setEditKeys}
                    extraData={supplierPlanState}
                    changeExtraData={setSupplierPlanState}
                    createExtraData={createExtraData}
                    formStyle={{
                        alignItems: "start"
                    }}
                    {...props}
                />
                
            </Modal>
        </>
    )
};


const FieldTextArea = ({name, data, ...props}) => (
    <div className="rs-form-control-wrapper">
        <FieldTextAreaAutoResize
            className="rs-input"
            name={name}
            {...props}
        />
    </div>
);


const StyledCheckbox = styled(Checkbox)`
    && {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    ${props => props.styledIntend && css`
        top: -7px;
    `}

    && .rs-checkbox-wrapper {
        // left: 24px;
    }
`;

const StyledTable = styled(TableEditCellsNew)`
    &&.rs-table.rs-table-hover {
        margin-top: 0;
        margin-bottom: 0;
    }
    .rs-table-body-row-wrapper .rs-table-cell-content .tableFilters_buttons {
        float: right;
    }
    .rs-table-body-row-wrapper .rs-table-cell-content .tableFilters_buttons > .rs-btn {
        width: 28px;
        height: 28px;
        padding: 4px 8px;
    }
    
    .rs-table-body-row-wrapper .rs-table-cell-content .tableFilters_buttons > .rs-btn {
        margin-right: 5px;
    }
    .rs-table-body-row-wrapper .rs-table-cell-content .tableFilters_buttons .rs-btn-lightblue {
        color: var(--color-light);
        background: var(--color-info);
    }
    
    .rs-error-message.rs-error-message-show {
    
        position: absolute;
        color: red;
        top: -4px;
        font-size: 10px;
        border-radius: 10px;
        bottom: auto !important;
        padding: 0 4px;
        right: 6px !important;
    }
    
    .rs-error-message.rs-error-message-show .rs-error-message-arrow {
        display: none;
    }
    .rs-error-message.rs-error-message-show .rs-error-message-inner {
        white-space: nowrap;
    }
`;

const FieldTextAreaAutoResize = styled(TextareaAutosize)`
    &&& {
        min-height: 100% !important;
        overflow: hidden;
        min-width: 250px;
        max-height: 56px;
        resize: none;
        overflow-y: auto;
        border: 1px solid #e5e5ea;
        border-top-color: rgb(229, 229, 234);
        border-right-color: rgb(229, 229, 234);
        border-bottom-color: rgb(229, 229, 234);
        border-left-color: rgb(229, 229, 234);
        border-top-color: rgb(229, 229, 234);
        border-right-color: rgb(229, 229, 234);
        border-bottom-color: rgb(229, 229, 234);
        border-left-color: rgb(229, 229, 234);
        transition: border-color ease-in-out 0.3s;
        border-radius: 6px;
        padding: 7px 11px;
        font-size: 14px;
        line-height: 1.42857143;
    } 

    &&&:focus-visible {
        border: 1px solid #1675e0;
        outline: none;
    }
    &.rs-input {
      max-height: 100%;
    }
`;