import React, {useRef, useMemo, useState} from "react";
import Modal from "components/Modal";
import { api } from "api/loginRoutes";
import TrunkForm from "./TrunkForm";
import {getServiceSmsPrefix, toStartDayUTCTimeNextDay, toStartDayUTCTime, compareObjects} from "utils";
import {Alert, Schema} from "rsuite";
import {useWindowWidth} from "hooks";
import TrunkModalWarning from "./TrunkModalWarning";
import {
    ACCOUNT_SERVICE_PLAN_LIST_API,
    TRUNK_MODIFY_API,
    TRUNK_MODIFY_SMS_API,
    TRUNK_CREATE_API,
    TRUNK_CREATE_SMS_API,
    SMS_SUPPLIER_TRUNK_CREATE_METHOD,
    SMS_SUPPLIER_TRUNK_MODIFY_METHOD,
    SMS_SUPPLIER_TRUNK_REMOVE_METHOD,
    SMS_SUPPLIER_TRUNK_IP_CREATE_METHOD,
    SMS_SUPPLIER_TRUNK_IP_MODIFY_METHOD,
    SMS_SUPPLIER_TRUNK_IP_REMOVE_METHOD
} from "const/apiMethods";

const {StringType} = Schema.Types;

const formSmsSupplierModel = Schema.Model({
    plan_id: StringType()
        .isRequired("Required")
});

const protocolTypes = [
    "smpp",
    "http/https",
    "smpp_mo"
];

export default ({
    onClose,
    service,
    trunksList,
    account_id,
    initialValues,
    ipOptionList,
    ivr_list,
    update,
    account,
    account_name,
    isGanTrunks,
    currentSupplier,
    isTransitTrunks,
    suppliersPlanList,
    rerouteStatusList,
    transitDefaultLimit,
    accountServicePlanList,

    createTrafficLimit,
    modifyTrafficLimit,
    removeTrafficLimit,
}) => {
    const isSupplierTrunk = (isTransitTrunks  || isGanTrunks);

    const initTrafficValue = initialValues?.trafic_limit_list || [];
    const defaultDayValue = {day: transitDefaultLimit || 4};
    const defaultHourValue = isTransitTrunks ? {} : {hour: 4};
    const initTrafficLimitList = initialValues ? initialValues.id ? initTrafficValue : [{
        ...defaultDayValue,
        ...defaultHourValue,
        senderid: null,
        key: "new"
    }] : [];

    const formTrafficLimitRef = initTrafficLimitList.reduce((result, trafficLimit) => {
        result[trafficLimit.key] = useRef(null);
        return result;
    }, {});

    const form = useRef(null);
    const dialerForm = useRef(null);
    const ivrForm = useRef(null);
    const nonDialerTrafficRunForm = useRef(null);

    const windowWidth = useWindowWidth();
    const [disabled, setDisabled] = useState(false);
    
    const [error, setError] = useState({});

    const [protocolType, setProtocolType] = useState(initialValues?.message_protocol || "smpp");

    const [modalWarningShow, setModalWarningShow] = useState(false);
    const [moreData, setMoreData] = useState({
        payout_model: initialValues?.payout_model,
        revenue_percent: initialValues?.revenue_percent,
        postback_url: initialValues?.postback_url
    });

    const [is_default, onChangeDefault] = useState( initialValues.is_default_term_point !== undefined
        ? initialValues.is_default_term_point
        : true
    );

    const [ipList, setIpList] = useState(initialValues && initialValues?.ip_list && JSON.parse(JSON.stringify(initialValues?.ip_list)) || []);
    const [httpIpList, setHttpIpList] = useState(initialValues && initialValues?.ip_white_list && JSON.parse(JSON.stringify(initialValues?.ip_white_list)) || []);
    
    const [moreOptionsFlag, setMoreOptionsFlag] = useState(!!initialValues?.payout_model || !!initialValues?.postback_url);

    const [trafficLimitKeyList, setTrafficLimitKeyList] = useState(initTrafficLimitList);

    const protocolTypeValues = useMemo(() => {
        return {
            http: protocolType === "http/https",
            smpp: protocolType === "smpp",
            smpp_mo: protocolType === "smpp_mo"
        }
    }, [protocolType]);

    const emptyValueList = useMemo(() => {
        return (protocolTypeValues.smpp || protocolTypeValues.smpp_mo)
            ? ipList.filter((row, idx) => (!row.ip || !row.login || !row.passwd))
            : httpIpList.filter((ip) => (!ip))
    }, [protocolType, ipList, httpIpList, protocolTypeValues]);

    const onPostbackTest = async () => {
        if (!form.current.check()) return;

        const formData = form.current.getFormValue();
        const method = getServiceSmsPrefix(service, "trunk:test_postback");

        const postbackUrl = moreData?.postback_url || formData.postback_method;
        const postbackMethod = moreData?.postback_method || formData.postback_method || "GET";

        if (postbackUrl && postbackMethod) {
            try {
                await api(method, {
                    target:{
                        account_id,
                        trunk_id: formData.id
                    },
                    postback_url: postbackUrl,
                    postback_method: postbackMethod
                }).then((response) => {
                    if (response.postback_url) {
                        Alert.success("Postback was queued");
                    } else {
                        Alert.error("Something wrong");
                    }
                });
            } catch (e) {
                console.log("Postback test error");
            }
        }
    };

    const onSubmit = async () => {
        const currentTrafficLimitKeyList = trafficLimitKeyList.map((x) => x);
        if (!form.current.check()) return;

        trafficLimitKeyList.forEach((trafficLimit, idx) => {
            const hasNotData = isTransitTrunks ? !trafficLimit?.day : !trafficLimit?.hour || !trafficLimit?.day;

            if (trafficLimit.key.toString().startsWith("new") && hasNotData) {
                currentTrafficLimitKeyList.splice(idx, 1);
    
                setTrafficLimitKeyList(currentTrafficLimitKeyList);
            }
        });

        if ((!is_default && !dialerForm.current.check()) || disabled) return;

        setDisabled(true);
        const modifyMethod = service ? TRUNK_MODIFY_API : 
            (isTransitTrunks || isGanTrunks) ? SMS_SUPPLIER_TRUNK_MODIFY_METHOD : TRUNK_MODIFY_SMS_API;
        const createMethod = service ? TRUNK_CREATE_API : 
            (isTransitTrunks || isGanTrunks) ? SMS_SUPPLIER_TRUNK_CREATE_METHOD : TRUNK_CREATE_SMS_API;
        const method = initialValues.id ? modifyMethod : createMethod;

        let _data = form.current.getFormValue();

        const todayDate = toStartDayUTCTime( new Date() );
        let startDate = _data.start_date 
            ? toStartDayUTCTime( new Date(_data.start_date) ) 
            : null;
        
        const isToday = startDate && (
            todayDate.getFullYear() === startDate.getFullYear() &&
            todayDate.getMonth() === startDate.getMonth() &&
            todayDate.getDate() === startDate.getDate()
        );

        const data = {
            name: _data.name,
            sp_key: _data.sp_key,
            prt_key: _data.prt_key || null,
            rrc_key: _data.rrc_key,

            start_date: !initialValues.id 
                ? (startDate && !isToday) ? startDate : null
                
                : isToday 
                    ?_data.start_date ? _data.start_date : null
                    : startDate,
            end_date: _data.end_date
                ? toStartDayUTCTimeNextDay( new Date(_data.end_date) ) 
                : null,

            active: !!_data.active,
            
            ...(!service && !isSupplierTrunk ? {full_message: !!_data.full_message} : {}),

            ...(_data.is_reseller ? {
                is_reseller: _data.is_reseller,
                subacc_rate_percent_month: _data.subacc_rate_percent_month / 100,
                subacc_rate_percent_week: _data.subacc_rate_percent_week / 100,
            } : {}),

            ...(isTransitTrunks && !initialValues.id ? {custom_ranges: _data.custom_ranges} : {}),

            is_default_term_point: _data.is_default_term_point,

            smpp_reroute_status_key: _data.smpp_reroute_status_key,
            delivery_delay_type: _data.delivery_delay_type,
            dlr_percent: _data.dlr_percent/100,
            bill_only_delivered: _data.bill_only_delivered,
            block_traffic_limit_excess: _data.block_traffic_limit_excess
        }; 

        if (is_default && ivrForm.current) {
            const formData = ivrForm.current.getFormValue();
            _data.ivr_id = formData.ivr_id;
        }

        if (isTransitTrunks && nonDialerTrafficRunForm.current) {
            const formData = nonDialerTrafficRunForm.current.getFormValue();
            data.organic_login = formData.organic_login || undefined;
            data.organic_password = formData.organic_password || undefined;
            data.organic_ip = formData.organic_ip || undefined;
            data.organic_port = parseInt(formData.organic_port) || undefined;
        }

        if (!is_default) {
            const formData = dialerForm.current.getFormValue();
            data.prt_key = formData.prt_key;
            data.ip = formData.ip;
            data.port = +formData.port;
            data.tprefix = formData.tprefix;
        }

        if (httpIpList.length) {
            data.ip_white_list = httpIpList;
        }

        if (!service) {
            const formData = moreData;
            if (isSupplierTrunk) {
                if (formData.postback_response_mime_type) data.postback_response_mime_type = formData.postback_response_mime_type;
                if (formData.postback_response_body) data.postback_response_body = formData.postback_response_body;
                
                data.message_protocol = protocolType;
            }
        }

        if (!service || moreOptionsFlag) {
            const formData = moreData;
            if (formData.postback_url || formData.postback_url === "") data.postback_url = formData.postback_url;
            if (formData.postback_method) data.postback_method = formData.postback_method;
            if (formData.payout_model) data.payout_model = parseInt(formData.payout_model);
            if (formData.revenue_percent) data.revenue_percent = parseFloat(parseFloat(formData.revenue_percent).toFixed(1));
        }

        if (data.tprefix) {
            for (let trunkData of trunksList) {

                if ( data.sp_key !== trunkData.sp_key) {
                    if ( (data.sp_key === 3 || trunkData.sp_key === 3) && data.tprefix === trunkData.tprefix ) {
                        setDisabled(false);
                        Alert.error(`Techprefix: ${data.tprefix} is not unique "${account_name}" trunk "${trunkData.name}"`, 5000);
                        return;
                    }
                }

            }
        }

        if (isGanTrunks || isTransitTrunks) {
            data.ip_list = initialValues?.ip_list;
            
            if (isGanTrunks) {
                data.type = "gan";
            } else if (isTransitTrunks) {
                data.type = "transit";
            }
        }

        const currentSpPlanList = await api(ACCOUNT_SERVICE_PLAN_LIST_API, {target: {account_id: account_id}})
            .then(({account_service_plan_list}) => account_service_plan_list);
        const currentSpPlan = currentSpPlanList.find(item => item.sp_key === data.sp_key);

        if ((currentSpPlan && !currentSpPlan.allowed) && data.active) {
            setModalWarningShow(true);
            setDisabled(false);
            return;
        }

        let isChanged = false;
        // debugger;
        try {
            await api(method, {
                target:{
                    account_id,
                    ...((isTransitTrunks || isGanTrunks) ? {"sms.supplier_plan_id": _data.plan_id} : {}),
                    ...(service ? {trunk_id: _data.id} : (isTransitTrunks || isGanTrunks) ? {"sms.supplier_trunk_id": _data.id} : {"sms.trunk_id": _data.id}),
                    ...(Object.keys(_data).includes("ivr_id") ? {ivr_id: _data.ivr_id} : {})
                },
                ...data,
                sp_key: data.sp_key // _data.id ? undefined : data.sp_key - непонятный костыль
            }).then( async (response) => {
                try {
                    if (response["sms.supplier_trunk"]) {
                        const initIpList = initialValues?.ip_list || [];
                        const supplierTrunkId = initialValues.id ? _data.id : response["sms.supplier_trunk"];

                        ipList.forEach(async (ipValue) => {
                            const initIpValue = initIpList.find(ip => ip.key === ipValue.key);
                            // s && Array.isArray(ipValue.options)
                            let optionBitMask;

                            if (!ipValue.key) {
                                isChanged = true;
                                optionBitMask = ipValue?.options ? ipValue.options.reduce((acc, value) => acc += value, 0) : 0;

                                await api(SMS_SUPPLIER_TRUNK_IP_CREATE_METHOD, {
                                    target:{
                                        "sms.supplier_trunk_id": supplierTrunkId
                                    },
                                    "sms.supplier_trunk_ip_key": ipValue.key,
                                    active: true,
                                    ...ipValue,
                                    ...(ipValue?.port ? {port: parseInt(ipValue.port)} : {}),
                                    options: optionBitMask
                                }).then(() => {
                                    Alert.success("Suppliers ip was created");
                                }).finally(() => {
                                    update();
                                });
                                
                            } else {
                                if (!compareObjects(initIpValue, ipValue)) {
                                    isChanged = true;
                                    optionBitMask = ipValue?.options ? ipValue.options.reduce((acc, value) => acc += value, 0) : 0;

                                    await api(SMS_SUPPLIER_TRUNK_IP_MODIFY_METHOD, {
                                        target:{
                                            "sms.supplier_trunk_id": supplierTrunkId
                                        },
                                        "sms.supplier_trunk_ip_key": ipValue.key,
                                        ...ipValue,
                                        ...(ipValue?.port ? {port: parseInt(ipValue.port)} : {}),
                                        options: optionBitMask
                                    }).then(() => {
                                        Alert.success("Suppliers ip was modified");
                                    }).finally(() => {
                                        update();
                                    });
                                }

                            }
            
                        });

                        initIpList.forEach(async (initIpValue) => {
                            const deletedIpValue = !ipList.find(ipValue => ipValue.key == initIpValue.key);
            
                            if (deletedIpValue) {
                                isChanged = true;
                                await api(SMS_SUPPLIER_TRUNK_IP_REMOVE_METHOD, {
                                    target:{
                                    "sms.supplier_trunk_id": supplierTrunkId
                                    },
                                    "sms.supplier_trunk_ip_key": initIpValue.key
                                }).then(() => {
                                    Alert.success("Suppliers ip was deleted")
                                }).finally(() => {
                                    update();
                                });
                            }
                        });

                        const initTrafficLimit = initialValues?.trafic_limit_list || [];

                        if (currentTrafficLimitKeyList.length) {
                            currentTrafficLimitKeyList.forEach(async (trafficLimit) => {
                                const currentInitialValue = initTrafficLimit.find(initTrafficLimit => {
                                    return initTrafficLimit.key === trafficLimit.key;
                                });

                                if (trafficLimit.key.toString().startsWith("new")) {
                                    isChanged = true;
                                    await createTrafficLimit({
                                        target: {
                                            "sms.supplier_trunk_id": supplierTrunkId
                                        },
                                        ...trafficLimit,
                                        hour: parseInt(trafficLimit.hour),
                                        day: parseInt(trafficLimit.day),
                                        type: "trunk"
                                    }).then(() => {
                                        Alert.success("Suppliers traffic limit was created");
                                    }).finally(() => {
                                        update(true);
                                    });
                                } else {
                                    if (!compareObjects(currentInitialValue, trafficLimit)) {
                                        isChanged = true;
                                        await modifyTrafficLimit({
                                            target: {
                                                "sms.supplier_id": currentSupplier?.supplier_id,
                                                // "sms.supplier_trunk_id": initialValues.id,
                                                "sms.traffic_limit_key_list": [trafficLimit.key]
                                            },
                                            hour: parseInt(trafficLimit.hour),
                                            day: parseInt(trafficLimit.day),
                                            type: "trunk"
                                        }).then(() => {
                                            Alert.success("Suppliers traffic limit was created");
                                        }).finally(() => {
                                            update(true);
                                        });
                                    }
                                }
                            });
                        } else {
                            if (!initialValues.id) {
                                await createTrafficLimit({
                                    target: {
                                        "sms.supplier_trunk_id": supplierTrunkId
                                    },
                                    senderid: null,
                                    
                                    ...defaultDayValue,
                                    ...defaultHourValue,
                                    
                                    type: "trunk"
                                }).finally(() => {
                                    update(true);
                                });
                            }
                        }

                        initTrafficLimit.forEach(async (initTrafficLimit) => {
                            const deletedInitTrafficLimit = !currentTrafficLimitKeyList.find(trafficLimit => trafficLimit.key == initTrafficLimit.key);

                            if (deletedInitTrafficLimit) {
                                await removeTrafficLimit({
                                    target: {
                                        "sms.supplier_id": currentSupplier?.supplier_id,
                                        "sms.traffic_limit_key_list": [initTrafficLimit.key]
                                    },
                                    type: "trunk"
                                }).finally(() => {
                                    update(true);
                                });
                            }
                        });
                    }
                } catch (e) {
                    console.log("Change trunk ip list error");
                }
            }).finally(() => {  
                onClose();

                if (!isChanged) {
                    update();
                }
            });
            
        } catch (e) {
            console.log("Change trunk error");
        }

        setDisabled(false);
    };

    const modalWidth = isSupplierTrunk ? 1220 : 720;

    const isEmpty = emptyValueList.length;


    const isFormHasError = useMemo(() => {
        return Object.keys(error).some((formKey) => {
            const formError = error[formKey];

            return Object.keys(formError).some((itemKey) => {
                if (Array.isArray(formError[itemKey])) {
                    return formError[itemKey].length;
                }

                return !!formError[itemKey];
            });
        });
    }, [error]);
    
    return (
        <>
            <Modal
                {...{onClose}}
                show={true}
                onSuccess={onSubmit}
                disabled ={disabled}
                extraDisabled={isFormHasError || !!isEmpty}
                title="Create/Edit Trunk"
                footer
                successText="Save"
                width={windowWidth >= modalWidth ? modalWidth : windowWidth}
            >
                  <TrunkForm
                      accountServicePlanList={accountServicePlanList}
                      account={account}
                      service={service}
                      ivr_list={ivr_list}
                      disabled = {disabled}
                      is_default = {is_default}
                      setDisabled={setDisabled}
                      updateFormRef  = {form}
                      updateFormRef2 = {dialerForm}
                      updateFormRef3 = {ivrForm}
                      updateFormRef4 = {nonDialerTrafficRunForm}
                      setMoreData = {setMoreData}
                      onChangeDefault = {onChangeDefault}
                      initialValues ={ initialValues}
                      newTrunk={!initialValues.id}
                      isMobile={windowWidth < 620}
                      {...{
                          error,
                          setError,
                          ipList, 
                          setIpList,
                          httpIpList,
                          ipOptionList,
                          setHttpIpList,
                          onPostbackTest,
                          moreOptionsFlag,
                          setMoreOptionsFlag,
                          suppliersPlanList,
                          protocolType,
                          setProtocolType,
                          isGanTrunks,
                          isTransitTrunks,
                          protocolTypes,
                          formTrafficLimitRef,
                          trafficLimitKeyList,
                          setTrafficLimitKeyList,
                          rerouteStatusList,
                          initTrafficLimitList,
                          protocolTypeValues
                      }}
                />
            </Modal>

            <TrunkModalWarning
                show={modalWarningShow}
                onClose={() => setModalWarningShow(false)}
            />
        </>
    )
};