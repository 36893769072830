import React from "react";
import TableClientSort from "components/Table/TableClientSort";
import {Table as _Table} from "rsuite";
import {BaseButton} from "components/base/BaseButton";
import styled from "styled-components";
import PaginationTotal from "components/base/Pagination/PaginationTotal";
import { Spacer } from "components/base/Spacer";

import "./LiveCalls.css"

const { Column, HeaderCell, Cell } = _Table;

const columns = [
    {
        label: "Account",
        dataKey: "acc_name_orig",
        value: ({acc_name_orig, rate_orig}) => {
            return (
                <div className="table-two-staged__cell">
                    <div className="table-two-staged__cell-first">{acc_name_orig}</div>
                    <div className="table-two-staged__cell-second">Rate: {rate_orig}</div>
                </div>
            )
        },
        flexGrow: 1,
        minWidth: 120,
        title: true,
        sortable: true,
    },
    {
        label: "A-number",
        dataKey: "a_number",
        value: ({ a_number, a_subdestination_name }) => (
            <div className="table-two-staged__cell">
                <div className="table-two-staged__cell-first">{a_number}</div>
                <div className="table-two-staged__cell-second">{a_subdestination_name}</div>
            </div>
        ),
        flexGrow: 1,
        minWidth: 120,
        sortable: true,
    },
    {
        label: "B-number",
        dataKey: "b_number",
        value: ({ b_number, b_subdestination_name }) => (
            <div className="table-two-staged__cell">
                <div className="table-two-staged__cell-first">{b_number}</div>
                <div className="table-two-staged__cell-second">{b_subdestination_name}</div>
            </div>
        ),
        flexGrow: 1,
        minWidth: 120,
        sortable: true,
    },
    {
        label: "Range",
        dataKey: "range",
        value: ({ range, rate_term }) => (
            <div className="table-two-staged__cell">
                <div className="table-two-staged__cell-first">{range}</div>
                <div className="table-two-staged__cell-second">Rate: {rate_term}</div>
            </div>
        ),
        flexGrow: 1,
        minWidth: 100,
        title: true,
        sortable: true,
    },
    {
        label: "Dialer",
        dataKey: "acc_name_term",
        value: ({ acc_name_term, termination_point }) => (
            <div className="table-two-staged__cell">
                <div className="table-two-staged__cell-first">{acc_name_term}</div>
                <div className="table-two-staged__cell-second">{termination_point}</div>
            </div>
        ),
        flexGrow: 1,
        minWidth: 100,
        title: true,
        sortable: true,
    },
    {
        label: "Duration",
        dataKey: "duration",
        value: ({ duration }) => (
            <div className="table-two-staged__cell">
                <div className="table-two-staged__cell-single">{duration}</div>
            </div>
        ),
        // flexGrow: 1,
        minWidth: 100,
        sortable: true,
        align: "right",
    },
];


export default ({onStop, data, loading, onDataUpdated }) =>  {
        return (
            <>
                <TableClientSort
                    data={data}
                    rowHeight={50}
                    extraHeight={639}
                    shouldUpdateScroll={false}
                    autoHeight={false}
                    loading={loading}
                    columns = {columns}
                    columnSelectorLSKey="LiveCallsTable"
                    ActionCell = {ActionCell(onStop)}
                    onDataUpdated={onDataUpdated}
                />
                <Spacer size={10} />
                <PaginationTotal
                    style={{textAlign: "right"}}
                    total={data.length}
                />
            </>
        );
}

const ActionCell = (onStop) => (
    <Column align={"center"} width={100}>
        <HeaderCell>Action</HeaderCell>
        <Cell>
            {({call_key}) => <div className="table-two-staged__cell">
                <div className="table-two-staged__cell-single">
                    <StyledCloseButton
                        buttonStyle="error"
                        size="esm"
                        onClick={() =>onStop(call_key)}
                        style={{position:"relative", top:"-3px"}}>
                        Stop
                    </StyledCloseButton>
                </div>
            </div> }
        </Cell>
    </Column>
)


const StyledCloseButton = styled(BaseButton)`
    && {
        padding-top: 4px;
        padding-bottom: 4px;
    }
`;