import React, {useMemo, useState, useEffect} from "react"
import {Alert, Loader, Placeholder} from "rsuite";
import CreateForm from "../../NewAccount/CreateForm";
import {ACCOUNT_CONTACT_CHECK_METHOD} from "../../../const/apiMethods";
import {api} from "../../../api/loginRoutes";
import {CRUDitems, generateId} from "../../../utils";
import {withRouter} from "react-router-dom";

const USER_NAME_ALREADY_USED_CODE = 4;

const {Paragraph} = Placeholder;

function AccountForm(account, currency, payments, users, accountManagerList, accountJoinList) {
    this.name = account.name;
    this.account_manager_id = accountManagerList.find((manager) => manager.name === account.account_manager_name)?.id || null;
    this.call_duration_max = account.call_duration_max;
    this.hf_key = account.hf_key;
    this.cntr_code = account.cntr_code;
    this.cur_key = account?.cur_key || null;
    
    this.pt_key = account.pt_key;
    this.contacts = (account.account_contact_list || []).map(
        contact => ({...contact, key: generateId()})
    );
    this.users = users.map(
        user => ({
            ...user,
            login_disabled: true,
            email_disabled: true,
            key: generateId()
        })
    );

    this.service = account.service;

    account.permission_list = Array.isArray(account.permission_list) ? account.permission_list : [];

    this.allocate_number = account.permission_list.includes("allocate_number");
    this.allocate_pattern = account.permission_list.includes("allocate_pattern");
    this.trunk_notificaton = account.trunk_notificaton;

    this.joined_account_list = account.joined_account_list;
    this.supplier_credit_limit = account.supplier_credit_limit;
    this.report_number_change = account.report_number_change;
    this.block_traffic_credit_excess = account.block_traffic_credit_excess;

    this.reseller_allowed = account.is_reseller || false;
}

const TabGeneral = ({
    disabled = false, account_list = [], currency_list = [], payment_terms_list = [], account_manager_list = [], account,
    accountUsers, account_user_modify_method, account_user_remove_method, getAccount, getDropdownAccounts, getAccountUsers,
    client_role_list, admin_role_list, getAccountManagersDropdownList, onSetRedirectTab, countryList, howFindList, getAccounts,
    permissions, accountId, getAccountJoin, accountJoinList, joinLoading, service, setService, authInfo, currentSupplier, 
    isSupplier, isSupervisorSms, supplierTrunks, setResellerMode, ...props
}) => {

    useEffect(() => {
        // getAccountJoin(accountId);
        getDropdownAccounts();
    }, []);

    const accountData = useMemo(() => {
        return new AccountForm(account, currency_list, payment_terms_list, accountUsers, account_manager_list);
    }, [account, currency_list, payment_terms_list, accountUsers, account_manager_list]);

    const [loading, setLoading] = useState(false);

    if (props.loading)
        return (
            <>
                <Loader backdrop content="loading..." vertical/>
                <Paragraph rows={5}/>
            </>
        );
    const checkDuplicates = async (contact_list) => {
        const result = await api(ACCOUNT_CONTACT_CHECK_METHOD, {
            target: {
                account_id: account.id,
            },
            contact_list
        }, true);
        return result;
    };
    
    const onSubmit = async (data, account_manager_id, users, contacts, reg_key, isSupplier) => {
        const _users = JSON.parse(JSON.stringify(users)).map(
            user => ({
                ...user,
                login_disabled: undefined,
                email_disabled: undefined,
                key: undefined
            })
        );
        const [newAndChangesUsers, deletedUsers] = CRUDitems(accountUsers, _users);

        const _contacts = JSON.parse(JSON.stringify(contacts)).map(
            contact => ({
                ...contact,
                key: undefined
            })
        );
        const [newAndChangesContacts, deletedContacts] = CRUDitems(account.account_contact_list || [], _contacts);

        try {
            setLoading(true);

            const modifyMethod = isSupplier ? "sms.supplier:modify" : "account:modify";
            const currentId = isSupplier ? currentSupplier.supplier_id : account.id;

            const result = await api(modifyMethod, {
                    ...data,
                    cur_key: undefined,
                    target: {
                        [isSupplier ? "sms.supplier_id" : "account_id"]: currentId,
                        account_manager_id
                    }
                },
                true);
            // VOICE_AND_SMS - CHECK
            if (result && (result.account || result["sms.supplier"])) {

                const createMethod = account.is_test
                    ? "account_user:create_in_test_account"
                    : "account_user:create";

                const modifyMethod = account.is_test
                    ? "account_user:modify_in_test_account"
                    : account_user_modify_method;

                const removeMethod = account.is_test
                    ? "account_user:remove_in_test_account"
                    : account_user_remove_method;

                const results = [];

                for (const user of newAndChangesUsers) {
                    if (user.id) {
                        const userResult = await api(modifyMethod, {
                            name: user.name,
                            password: user.password,
                            target: { account_user_id: user.id },
                            role_list: user.role_list
                        });

                        if (userResult.error) {
                            results.push({success: false, error: userResult.error}); 
                        } else {
                            results.push({success: true, data: userResult})
                        }
                    } else {
                        const userResult = await api(createMethod, { ...user, target: { account_id: account.id } });

                        if (userResult.error) {
                            results.push({success: false, error: userResult.error}); 
                        } else {
                            results.push({success: true, data: userResult})
                        }
                    }
                }
    
                for (const user of deletedUsers) {
                    const userResult = await api(removeMethod, {
                        target: {
                            account_id: account.id,
                            account_user_id: user.id
                        }
                    }, true);

                    if (userResult.error) {
                        results.push({success: false, error: userResult.error}); 
                    } else {
                        results.push({success: true, data: userResult})
                    }
                }

                for (const contact of newAndChangesContacts) {
                    if (contact.id) {
                        const { telegram_id_ready: _, ...restContact } = contact;
    
                        const response = await api("account_contact:modify", {
                            ...restContact,
                            phone: contact.phone === null ? "" : contact.phone,
                            telegram_username: contact.telegram_username === "" ? null : contact.telegram_username,
                            target: { account_contact_id: contact.id }
                        });

                        if (response.hasOwnProperty("error")) {
                            if (response["error"]["data"].hasOwnProperty("messager")) {
                                results.push({success: false, error: response.error, duplicate_id: contact.id}); 
                            } else {
                                results.push({success: false, error: response.error}); 
                            }
                        } else {
                            results.push({success: true, data: response})
                        }
                    } else {
                        const response = await api("account_contact:create", {
                            ...contact,
                            phone: contact.phone === null ? "" : contact.phone,
                            telegram_username: contact.telegram_username === "" ? null : contact.telegram_username,
                            target: { account_id: account.id }
                        });

                        if (response.hasOwnProperty("error")) {
                            if (response["error"]["data"].hasOwnProperty("messager")) {
                                results.push({success: false, error: response.error, duplicate_id: contact.id}); 
                            } else {
                                results.push({success: false, error: response.error})
                            }
                        } else {
                            results.push({success: true, data: response})
                        }
                    }
                }
    
                for (const contact of deletedContacts) {
                     const response = await api("account_contact:remove", { target: { account_contact_id: contact.id } }, true);

                     if (response.error) {
                        results.push({success: false, error: response.error}); 
                    } else {
                        results.push({success: true, data: response})
                    }
                }

                const hasErrors = results.some(result => !result.success);

                if (hasErrors) {
                    const existDuplicateError = results.find(r => r.duplicate_id || r.duplicate_id === null);

                    setLoading(false);

                    if (existDuplicateError) {
                        const duplicateList = results.filter(r => r.duplicate_id || r.duplicate_id === null);

                        return {result: {duplicate_list: duplicateList}}
                    }
                } else {
                    getAccount(account.id, false, true);
                    getAccountUsers();
                    props.history.replace("/reload");
                    setTimeout((location, history) => {
                            history.replace({
                                ...location,
                                search: "?tab=general"
                            });
                        },
                        500,
                        props.location, props.history);
                    setLoading(false);
        
                    
                    setLoading(false);
                    return { result: { id: account.id } };
                }
            } else {
                if (result.error.code === USER_NAME_ALREADY_USED_CODE) {
                    Alert.error(result.error.message || result.error.data?.error_type);
                }
                setLoading(false);
                return result.error;
            }
        } catch (e) {
            setLoading(false);
        }
    };

    const onCancel = () => {

        const ganTrunks = supplierTrunks && supplierTrunks.filter(trunk => trunk.type === "gan");
        const isGanTrunks = ganTrunks && ganTrunks.length;
        const ganTab = isGanTrunks ? "?tab=gan_trunks" : "?tab=transit_trunks";

        const serviceTransformed = service ? "voice" : "sms";

        props.history.replace("/reload");
        if (account?.service === serviceTransformed) {
            const defaultAccountTab = !isSupplier ? "?tab=trunks" : ganTab;

            setTimeout((location, history) => {
                    history.replace({
                        ...location,
                        search: defaultAccountTab
                    });
                },
                100,
                props.location, props.history);
        } else {
            setTimeout(() => {
                const defaultAccountsPath = service ? "/accounts" : "/sms-suppliers";

                props.history.replace(defaultAccountsPath);
            },
            100);
        }
    };

    return (
        <CreateForm
            formDefaultValue={accountData}
            allocationBanExpiredDate={account.allocation_ban_expired_at}
            disabledCurrency={!!account?.cur_key}
            accountJoinList={accountJoinList}
            onCancel={onCancel}
            account_id={account.id}
            isTestAccount={account.is_test}
            disabled={disabled || loading}
            active={account.active}
            location={props.location}
            history={props.history}
            {...{
                currentSupplier,
                supplierTrunks,
                isSupplier,
                isSupervisorSms,
                authInfo,
                joinLoading,
                getAccountJoin,
                onSubmit,
                service,
                setService,
                checkDuplicates,
                account_list,
                currency_list,
                payment_terms_list,
                account_manager_list,
                client_role_list,
                admin_role_list,
                onSetRedirectTab,
                getAccountManagersDropdownList,
                countryList,
                howFindList,
                permissions,
                setResellerMode
            }}

        />
    );
};

export default withRouter(TabGeneral);