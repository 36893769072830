import React, {useEffect, useMemo} from "react";
import SelfAllocationRestrictionsTable from "./SelfAllocationRestrictionsTable";

export default (
    {
        account,
        data,
        loading,
        roleList,
        getAccount,
        getAccountServicePlanList,
    }
) => {
    useEffect(() => {
        if (!account || !account.id) {
            return;
        }
        getAccountServicePlanList(account.id);
    }, [account]);

    const dataDefault = useMemo(() => {
        if (!data) {
            return [];
        }

        return data.map(item => ({
            ...item.allocation_limit_default,
            sp_key: item.sp_key
        }));
    }, [data]);

    const dataUpdated = useMemo(() => {
        if (!data) {
            return [];
        }
        return data.map(item => ({
            sp_key: item.sp_key,
            sp_name: item.sp_name,
            allowed: item.allowed,
            allocate: item.allocation_limit && item.allocation_limit.allocate,
            autorevoke: item.allocation_limit && item.allocation_limit.autorevoke,

            once: item.allocation_limit && item.allocation_limit.once,
            daily: item.allocation_limit && item.allocation_limit.daily,
            daily_sde: item.allocation_limit && item.allocation_limit.daily_sde,
            after_last_call: item.allocation_limit && item.allocation_limit.after_last_call,
            after_allocation: item.allocation_limit && item.allocation_limit.after_allocation,
            all: item.allocation_limit && item.allocation_limit.all,
        }));
    }, [data]);



    return (
        <SelfAllocationRestrictionsTable
            getAccount={getAccount}
            data={dataUpdated}
            dataDefault={dataDefault}
            loading={loading}
            account={account}
            roleList={roleList}
            update={() => getAccountServicePlanList(account.id)}
        />
    )
}