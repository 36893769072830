import React, {useMemo, useState} from "react";
import {Table} from 'rsuite';
import {Form, Schema, SelectPicker, FormControl, Input, InputNumber, Button} from "rsuite";
import NumberField from "../../../../../components/Form/NumberField";
import Icon from "rsuite/es/Icon";
import BaseTable from "../../../../../components/base/BaseTable";

const {Column, HeaderCell, Cell} = Table;

const FormAddFilter = React.memo( (props) => {

    const {defaultFilter, service, showCreateButton} = props;

    const form = React.createRef();
    const account_id = props.accountId;

    const defaultValues = {rate_min: 0};
    const [fields, setFields] = useState(defaultValues);

    const {StringType, NumberType} = Schema.Types;
    const formModel = useMemo(() => {
        return Schema.Model({
            a_sde_key: NumberType(),
            ...(service ? {
                a_prefix: StringType()
                    .pattern(/^[0-9][\d]*$/, 'The number must contain only digits')
                    .minLength(6, 'The minimum is 6 characters.')
                    .maxLength(15, "The minimum is 15 characters"),
            } : {
                a_prefix: StringType()
                    .maxLength(15, "The minimum is 15 characters"),
            }),
            b_sde_key: NumberType(),
            rate_min: NumberType(),
            rate_min2: NumberType(),
        })
    }, [service]);

    const prefixSenderIdPlaceholder = service ? "Origin Prefix" : "Sender ID";

    const formControlItems = {
        antf_key: service ? <FormControl placement={"autoVerticalStart"} errorPlacement="topEnd" className="tableFilters_field" name="antf_key" accepter={SelectPicker} data={props.numberTypeFamilyList} placeholder="Service Name" valueKey="antf_key" labelKey="family_name" type="text" /> : null,
        a_wz_key: service ? <FormControl disabled={!!fields?.antf_key} placement={"autoVerticalStart"} errorPlacement="topEnd" className="tableFilters_field" name="a_wz_key" accepter={SelectPicker} data={props.worldzoneList} placeholder="Origin zone" valueKey="wz_key" labelKey="name" type="text" /> : null,
        a_de_key: service ? <FormControl disabled={!!fields?.antf_key} placement={"autoVerticalStart"} errorPlacement="topEnd" className="tableFilters_field" name="a_de_key" accepter={SelectPicker} data={props.destinationList} placeholder="Origin country" valueKey="de_key" labelKey="name" type="text" /> : null,
        a_sde_key: service ? <FormControl disabled={!!fields?.antf_key} placement={"autoVerticalStart"} errorPlacement="topEnd" className="tableFilters_field" name="a_sde_key" accepter={SelectPicker} data={props.subdestinationList} placeholder="Origin operator" valueKey="sde_key" labelKey="name" type="text" /> : null,
        a_prefix: <FormControl disabled={!!fields?.antf_key} errorPlacement="topEnd" className="tableFilters_field" name="a_prefix" accepter={Input} placeholder={prefixSenderIdPlaceholder} type="text" />,
        b_wz_key: <FormControl placement={"autoVerticalStart"} errorPlacement="topEnd" className="tableFilters_field" name="b_wz_key" accepter={SelectPicker} data={props.worldzoneList} placeholder="Destination zone" valueKey="wz_key" labelKey="name" type="text" />,
        b_de_key: <FormControl placement={"autoVerticalStart"} errorPlacement="topEnd" className="tableFilters_field" name="b_de_key" accepter={SelectPicker} data={props.destinationList} placeholder="Destination country" valueKey="de_key" labelKey="name" />,
        b_sde_key: <FormControl placement={"autoVerticalStart"} errorPlacement="topEnd" className="tableFilters_field" name="b_sde_key" accepter={SelectPicker} data={props.subdestinationList} placeholder="Destination operator" valueKey="sde_key" labelKey="name" type="text" />,
        rate_min: <FormControl errorPlacement="topEnd" className="tableFilters_field" name="rate_min" accepter={InputNumber} placeholder="Minimal payout" step={0.1} min={0} />,
    };

    const handleChange = (formValues) => {
        setFields(formValues)
    };

    const handleSubmit = async (_, event) => {
        event.preventDefault();

        let validStatus = form.current.check();

        if (!validStatus)
            return;

        let formValues = form.current.getFormValue();
        let data = {
            ...formValues,
            rate_min: +formValues.rate_min
        };

        if (!data.a_prefix && data.a_prefix !== undefined) {
            delete data.a_prefix;
        }

        props.addItem(service, account_id, data);

    };

    const voiceFormControlNames = ["antf_key", "a_wz_key", "a_de_key", "a_sde_key", "a_prefix", "b_wz_key", "b_de_key", "b_sde_key", "rate_min"];
    const smsFormControlNames = ["a_prefix", "b_wz_key", "b_de_key", "b_sde_key", "rate_min"];
    const formControlNames = service ? voiceFormControlNames : smsFormControlNames;
    
    const columns = formControlNames.map( key => {
        return {dataKey: key, label: ''}
    } );

    const tableColumnsElements = formControlNames.reduce((result, name) => {
        result[name] = formControlItems[name];
        return result;
    }, {});

    const columnsElements = formControlNames.map( (item) => {
        if (item !== "antf_key" || service && item === "antf_key") {
            return (
                <Column flexGrow>
                    <HeaderCell></HeaderCell>
                    <Cell dataKey={item}/>
                </Column>
            )
        }

        return null;
    } );

    return(
        <>
            <Form
                onSubmit={handleSubmit}
                ref={form}
                model={formModel}
                onChange={handleChange}
                formDefaultValue={fields}
                style={{margin: '0px', width: '100%'}}
            >

                <BaseTable
                    data={[tableColumnsElements]}
                    loading={false}
                    columns={columns}
                    headerHeight={0}
                    autoHeight
                    rowHeight={46}
                    className="tableFilters tableFilters__form"
                >
                    {columnsElements}
                    <Column width={174}>
                        <HeaderCell></HeaderCell>
                        <Cell>
                            {rowData => (
                                <Button
                                    className="tableFilters_submit"
                                    color="green"
                                    type="submit"
                                    disabled={!showCreateButton}
                                >
                                    <Icon icon="plus"/>
                                </Button>
                            )}
                        </Cell>
                    </Column>
                </BaseTable>

            </Form>
        </>
    )
} );

export default FormAddFilter