import {EOL} from "const";
import {Table, Alert} from "rsuite";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {jsPDF as JSPDF} from "jspdf";
import autoTable from "jspdf-autotable";
import {getFileResponse} from "api/loginRoutes";
import {APP_TYPE_CLIENT, APP_TYPE_DEFAULT} from "const";
import {FieldInput} from "components/Form/FormComponents";


// lodash
import _each from "lodash/each";
import _isNil from "lodash/isNil";
import _isEmpty from "lodash/isEmpty";
import _isError from "lodash/isError";
import _isObject from "lodash/isObject";
import _isString from "lodash/isString";
import _isFunction from "lodash/isFunction";
import _difference from "lodash/difference";
import _filter from "lodash/filter";
import _debounce from "lodash/debounce";


const {Column, HeaderCell, Cell} = Table;


// Checks if value is classified as a Function object.
export const isFunction = (f) => _isFunction(f);

// Checks if value is an Error, EvalError, RangeError, ReferenceError, SyntaxError, TypeError, or URIError object.
export const isError = (error) => _isError(error);

// Checks if value is null or undefined.
export const isNil = (value) => _isNil(value);

// Checks if value is an empty object, collection, map, or set.
export const isEmpty = (value) => _isEmpty(value);

// Checks if value is the language type of Object. (e.g. arrays, functions, objects, regexes, new Number(0), and new String(""))
export const isObject = (value) => _isObject(value);

// Creates an array of array values not included in the other given arrays using SameValueZero for equality comparisons. The order and references of result values are determined by the first array.
export const difference = _difference;

// Iterates over elements of collection, returning an array of all elements predicate returns truthy for. The predicate is invoked with three arguments: (value, index|key, collection).
export const filter = _filter;

// Creates a debounced function that delays invoking func until after wait milliseconds have elapsed since the last time the debounced function was invoked. The debounced function comes with a cancel method to cancel delayed func invocations and a flush method to immediately invoke them. Provide options to indicate whether func should be invoked on the leading and/or trailing edge of the wait timeout. The func is invoked with the last arguments provided to the debounced function. Subsequent calls to the debounced function return the result of the last func invocation.
export const debounce = _debounce;

// Iterates over elements of collection and invokes iteratee for each element. The iteratee is invoked with three arguments: (value, index|key, collection). Iteratee functions may exit iteration early by explicitly returning false.
export const each = _each;



export const pipe = (data, filters, model) => {
    if (!Object.keys(filters).length) {
        return data;
    }

    return data.filter(x => {
        for (let f of Object.keys(filters)) {
            if (Object.keys(filters).includes(f) && (!isBoolean(filters[f]) ? !filters[f] : false)) continue;
            if (!model[f](x, filters[f], filters)) return false;
        }
        return true;
    });
};

export const isNumber = (value) => {
    return typeof value === "number";
};

export const isBoolean = (value) => {
    return typeof value == "boolean";
};

export const isFloatNumber = (value) => {
    return typeof value === "number" && !isNaN(value) && !Number.isInteger(value);
};

export const deleteEmpty = obj => {

    if (typeof (obj) != "object") return obj;

    for (let k of Object.keys(obj)) {
        if (!obj[k] && obj[k] !== false)
            delete obj[k];

        if (Array.isArray(obj[k]) && !obj[k].length)
            delete obj[k];
    }

    return obj;
};


export const sortData = (data, sortColumn, sortType) => {
    return data.sort((a, b) => {
        const aVal = a[sortColumn];
        const bVal = b[sortColumn];

        if (!aVal && aVal !== 0) return 1;
        if (!bVal && bVal !== 0) return -1;

        if ((!aVal && !bVal) || (aVal === bVal)) return 0;

        const aCompVal = typeof aVal === "string" ? aVal.toLowerCase() : aVal;
        const bCompVal = typeof bVal === "string" ? bVal.toLowerCase() : bVal;

        let comparisonResult = 0;
        if (aCompVal > bCompVal) {
            comparisonResult = 1;
        } else if (aCompVal < bCompVal) {
            comparisonResult = -1;
        }

        return sortType === "asc" ? comparisonResult : -comparisonResult;
    });
};


export const generateId = () => {
    return "_" + Math.random().toString(36).substr(2, 9);
}


export const CRUDitems = (old_list, new_list) => {
    const jsonStringOldItems = old_list.map(
        item => JSON.stringify(item)
    );
    const jsonStringNewItems = new_list.map(
        item => JSON.stringify(item)
    );
    const newAndChangesItems = new_list.filter(item => !jsonStringOldItems.includes(JSON.stringify(item)));
    const newAndChangesItemsIds = newAndChangesItems.map(item => item.id);
    const deletedItems = jsonStringOldItems
        .filter(itemStringify => !jsonStringNewItems.includes(itemStringify))
        .map(itemStringify => JSON.parse(itemStringify))
        .filter(item => !newAndChangesItemsIds.includes(item.id));

    return [newAndChangesItems, deletedItems];
};


export const toUTCDateTime = (date) => {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
};


export const toStartDayUTCTime = (date) => {
    return new Date(Date.UTC( date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0 ));
};


export const toStartDayUTCTimeNextDay = (date) => {
    return new Date(Date.UTC( date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0 ));
};


export const toEndDayUTCTime = (date) => {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59));
};

export const toStartDayTime = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
};

export const toEndDayTime = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
};


export const checkShouldUpdateDiffers = (arr1, arr2) => {
    return arr1 && arr1.length && arr2 && arr2.length && !compareTwoArrays(arr1, arr2);
};


export const removeNilValues = (obj) => {
    const respObject = {...obj};

    Object.keys(respObject).forEach(key => {
        //Remove keys with Undefined or Null values
        if (isNil(respObject[key])) {
            delete respObject[key];
        }
    });

    return respObject;
};


export const responseErrorToString = (error, code) => {

    if (code === 409 && error?.data?.error_type) {
        return `Error: ${error.data.error_type}`
    }

    let errorString = "";
    
    if (!!error.message) {
        errorString = error.message;
    } else {
        if (isObject(error.data) && !isEmpty(error.data)) {
            each(error.data, (errMessage, fieldKey) => {
                errorString += `${fieldKey}: ${errMessage}${EOL}`;
            });
        } else {
            if (typeof error.data === "string") {
                errorString = error.data;
            }
        }
    }

    return errorString;
};


export const renderColumn = ({label, dataKey, value = null, headerStyle = null, width = 200, id, ...props}) => (
    <Column key={id} width={width} {...props}>
        <HeaderCell style={headerStyle}>{label}</HeaderCell>
        <Cell dataKey={dataKey}>{value}</Cell>
    </Column>
);

export const renderField = ({name, ...props}) => {
    return <FieldInput
        name={name}
        {...props}
    />
};

export const hasErrorObject = (errorObject) => {
    return errorObject && Object.keys(errorObject).map(key => errorObject[key]).some(value => value.hasError);
};

export const hasError = (error) => {
    return error && Object.keys(error).map(i => error[i]).some(value => value === true);
};


export function canIMoveNumbersToAnotherTrunk(fromTrunk, toTrunk) {
    return fromTrunk.id !== toTrunk.id && !toTrunk.closed && fromTrunk.sp_key === toTrunk.sp_key;
}


export function canIMoveNumbersToAnyTrunk(fromTrunk, toTrunk) {
    if (fromTrunk?.incorporated) {
        return fromTrunk.id !== toTrunk.id && !toTrunk.closed && toTrunk.incorporated
    }
    return fromTrunk.id !== toTrunk.id && !toTrunk.closed && !toTrunk.incorporated;
}

export const isNullOrUndefined = (value) => {
    return value === null || value === undefined;
};


export const replaceUndefinedOrNull = (key, value) => {
    if (isNullOrUndefined(value)) {
        return undefined;
    }

    return value;
};


export const calculateFixedSignsLength = (value) => {
    const formSigns = value.toString().split(".");

    let calculatedFixedSigns = 1;
    if (value && formSigns.length > 1) {
        const fixedSign = formSigns.pop();
        calculatedFixedSigns = 1 / (10 ** fixedSign.length);
    }
    return calculatedFixedSigns;
};


export const randomizeArray = (arr, chunkSize = 100) => {
    const chunksTotal = arr.length / chunkSize;
    const chunks = [];
    for (let i = 0; i < chunksTotal; i++) {
        const startItem = i * chunkSize;
        const endItem = startItem + chunkSize;
        const chunk = arr.slice(startItem, endItem);
        chunk.sort(() => Math.random() - 0.5);
        chunk.sort(() => Math.random() - 0.5);
        chunks.push(chunk);
    }

    return chunks.reduce((sum, current) => {
        return [...sum, ...current];
    }, []);
};


export const compare = (a, b) => {
    let nameA = a.toLowerCase();
    let nameB = b.toLowerCase();

    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
}


export const compareObjects = (object1, object2) => {
    const keys1 = object1 ? Object.keys(object1) : {};
    const keys2 = object2 ? Object.keys(object2) : {};

    if (keys1.length !== keys2.length) {
        return false;
    }

    let comparedValueList = [];
    
    for (let key of keys1) {
        if (Array.isArray(object1[key]) && Array.isArray(object2[key])) {
            const comparison = object1[key].length === object2[key].length && object1[key].every((value, index) => {
                return value === object2[key][index];
            });
            
            comparedValueList.push(comparison);
        } else {
        	comparedValueList.push(object1[key] === object2[key]);
        }
    }
    return comparedValueList.length === keys1.length && comparedValueList.every(comparison => comparison === true);
};

export const compareObjectsDebug = (object1, object2) => {
    const keysList = object1 ? Object.keys(object1) : {};


    const comparedKeyList = [];
    
    for (let key of keysList) {
        if (Array.isArray(object1[key]) && Array.isArray(object2[key])) {
            const comparison = object1[key].length === object2[key].length && object1[key].every((value, index) => {
                return value === object2[key][index];
            });
            
            if (!comparison)
            comparedKeyList.push(key);
        } else {
            if (object1[key] !== object2[key])
            comparedKeyList.push(key);
        }
    }

    const debugResult = comparedKeyList.reduce((result, key) => {
        result[key] = {
            "1": object1[key],
            "2": object2[key]
        }
        return result;
    }, {});

    return debugResult;
};

export const compareObjectsbyServiceAndDefaults = (obj1, obj2, service, defVoice, defSms) => {
    const defaultObject = servicePick(service, defVoice, defSms);

    const actualObjIsNotDefault = compareObjects(obj1, defaultObject);
    const objCompared = compareObjects(obj1, obj2);

    return objCompared && !actualObjIsNotDefault;
};


export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});


export const getFormattedTime = (date_) => {
    const date = new Date(date_);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day < 10 ? "0" + day : day}.${month < 10 ? "0" + month : month}.${year}`;
};


export const getFormattedTimeIOS = (date_) => {
    const arr = date_.split(/[- :]/);
    const date = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day < 10 ? "0" + day : day}.${month < 10 ? "0" + month : month}.${year}`;
}


export const getISOTime = (date_, UTC = false) => {
    const date = !UTC
        ? new Date(date_.getTime() - (date_.getTimezoneOffset() * 60000)).toISOString()
        : date_.toISOString();
    return date;
};


export const removeTZFromDate = (date) => {
    const modifiedDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    return modifiedDate;
};


export const compareTwoArrays = (array1, array2) => {
    return array1.length === array2.length && array1.sort().every((value, index) => {
        const value1 = JSON.stringify(value);
        const value2 = JSON.stringify(array2.sort()[index]);

        return value1 === value2;
    })
};


export const generatePassword = (passwordLength= 12) => {
    const numberChars = "0123456789";
    const specialChars = "#?!_@$%^&*";
    const upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowerChars = "abcdefghijklmnopqrstuvwxyz";

    const allChars = numberChars + upperChars + lowerChars + specialChars;
    let randPasswordArray = Array(passwordLength);
    randPasswordArray[0] = numberChars;
    randPasswordArray[1] = upperChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray[3] = specialChars;
    randPasswordArray = randPasswordArray.fill(allChars, 3);
    return shuffleArray(randPasswordArray.map((x) => { return x[Math.floor(Math.random() * x.length)] })).join("");
};


export const shuffleArray = (array) => {
    const arrayValue = array || [];
    for (let i = arrayValue.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = arrayValue[i];
        arrayValue[i] = arrayValue[j];
        arrayValue[j] = temp;
    }
    return arrayValue;
};


export const getDeepKeys = (object) => {
    let keys = [];
    for (let key in object) {
        if (object.hasOwnProperty(key)) {
            const keyValue = {key, value: key};
            keys.push(keyValue);

            if (typeof object[key] === "object") {
                const subkeys = getDeepKeys(object[key]);
                keys = keys.concat(subkeys.map((subkey) => {
                    return {key: key + "." + subkey.key, value: subkey.key}
                }));
            }
        }
    }
    return keys;
};


export function customDebounce(fn, ms) {
    let timer;
    return _ => {
        clearTimeout(timer);
        timer = setTimeout(_ => {
            timer = null;
            fn.apply(this, arguments);
        }, ms)
    };
};


export const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);
  
    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());
      const b = Number(versionsB.shift());
      // eslint-disable-next-line no-continue
      if (a === b) continue;
      // eslint-disable-next-line no-restricted-globals
      return a > b || isNaN(b);
    }
    return false;
  };


export const daysCount = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    let dayCount = 0;

    while (end > start) {
        dayCount++;
        start.setDate(start.getDate() + 1);
    }

    return dayCount
}


export const fieldSorter = (fields) => {
    return ((a, b) => {
        return fields
            .map((key) => {
                let dir = 1;
                if (key[0] === "-") {
                    dir = -1;
                    key=key.substring(1);
                }
                if (a[key] > b[key]) return dir;
                if (a[key] < b[key]) return -(dir);
                return 0;
            })
            .reduce((item, data) => {
                return item ? item : data;
            }, 0);
    });
};


export const between = (value, min, max) => {
    return value >= min && value <= max;
};


export const getRangeByDigit = (number, digit) => {
    return digit > 0 ? `${number}${"0".repeat(digit)}-${number}${"9".repeat(digit)}` : number
};


export const arrayToObject = (array) => {
    return array.reduce((acc, item) => {
        const key = Object.keys(item)[0];
        acc[key] = item[key];
        return acc
    }, {})
};


export const arrayToObjectSpecialKey = (array, key) => {
    return array.reduce((acc, item) => {
        acc[item[key]] = item;
        return acc
    }, {})
};


export const objectIsEmpty = (obj) => {
    if (!obj) return true;

    for (const prop in obj) {
        if (obj.hasOwnProperty(prop))
            return false;
    }
    return true;
};


export const getAllowedRangesByIntervalMonth = (intervalMonth = 3) => {
    const startDate = new Date();
    const endDate = new Date();
    const dateRange =  [
        new Date( startDate.setMonth(startDate.getMonth() - intervalMonth) + (1000 * 3600 * 24) ),
        new Date( endDate.setMonth(endDate.getMonth() + 12) )
    ];
    return [
        `${dateRange[0].getFullYear()}${("0" + (dateRange[0].getMonth() + 1)).slice(-2)}01`,
        `${dateRange[1].getFullYear()}${("0" + (dateRange[1].getMonth() + 1)).slice(-2)}01`,
    ]
};


export const setBodyClassNames = (userInfo = {}) => {
    const appType = userInfo?.session?.site || APP_TYPE_DEFAULT;
    if (appType === APP_TYPE_CLIENT) {
        document.body.classList.remove("app-admin");
        document.body.classList.add("app-client");
    } else {
        document.body.classList.remove("app-client");
        document.body.classList.add("app-admin");
    }
};


export const countDecimals = (value) => {
    if ((value % 1) !== 0)
        return value.toString().split(".")[1].length;
    return 0;
};




/* CSV */


export const getRangeByNumberDigit = (number, digit) => (
    digit > 0
        ? `${number}${"0".repeat(digit)}-${number}${"9".repeat(digit)}`
        : number
);


export const getRangeByNumber = (number, count) => {
    return getRangeByNumberDigit(number, Math.log10(count));
};


export const getNumbersByDigit = (number, digit) => {
    if (!digit) {
        return [number];
    }

    const count = Math.pow(10, digit);
    const numbers = Array(10 ** digit).fill(number);
    return numbers.map((item, index) => `${number}${(count + index + "").slice(1)}`)
};


export const makeNumbersFromPrefixesToCsv = (csv) => {
    const csvRows = csv
        ? csv.split("\n").filter((value) => !!value)
        : [];

    return csvRows
        .slice(1)
        .reduce((summ, item) => {
            const itemDataArray = item.split(";");
            const currentData = Array(10 ** itemDataArray[1])
                .fill(itemDataArray)
                .map((value, index) => {
                    const digit = itemDataArray[1];
                    const count = Math.pow(10, digit);
                    const chunk = (count + index + "").slice(1);
                    return [`${value[0]}${chunk}`, ...value.slice(1)].join(";");
                });
            return [
                ...summ,
                ...currentData,
            ]       
        }, [csvRows[0]])
        .join("\n");
};


export const makeRangeFromPrefixesToCsv = (csv) => {
    const csvRows = csv
        ? csv.split("\n").filter((value) => !!value)
        : [];

    const csvBodyPrefixes = csvRows
        .slice(1)
        .map(item => {
            const currentData = item.split(";");

            return [
                getRangeByNumberDigit(currentData[0], currentData[1]), 
                ...currentData.slice(1)
            ].join(";")

        });

    return [csvRows[0], ...csvBodyPrefixes].join("\n");
};


export const makeCsvNumbers = (csv) => {
    const csvRows = csv
        ? csv.split("\n").filter((value) => !!value)
        : [];

    const csvHeaders = csvRows[0];
    const csvBodyPrefixes = csvRows
        .slice(1)
        .map(value => {
            const prefixValue = value.split(";");

            if ( +prefixValue[1] > 0 ) {
                return Array(10 ** prefixValue[1])
                    .fill(prefixValue)
                    .map((value, index) => {
                        const digit = prefixValue[1];
                        const count = Math.pow(10, digit);
                        const chunk = (count + index + "").slice(1);
                        return [`${value[0]}${chunk}`, ...value.slice(1)].join(";")
                    });
            }
            return prefixValue[0];

        });
    return [csvHeaders, ...csvBodyPrefixes].join("\n");
};



//

export const getCsvDataWithHeaders = (csvData) => {
    const csvHeaderList = csvData && csvData.length ? csvData[0].split(";") : [];
    const csvBody = csvData && csvData.length ? csvData.slice(1) : [];
    const csvResult = csvBody && csvBody.length ? csvBody.filter(value => !!value).map( line => {
        const item = line.split(";");
        return `${item[0]};${item.slice(2).join(";")}`
    }) : [];
    const csvHeaders = csvHeaderList && csvHeaderList.length ? [csvHeaderList[0], ...csvHeaderList.slice(2)].join(";") : "";
    csvResult.unshift(csvHeaders);

    return csvResult;
};






/* FS */


export const getBlobContent = (file) => (
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })
);


export const copyToClipboard = (text) => {
    const temp = document.createElement("textarea");
    document.body.appendChild(temp);
    temp.value = text;
    temp.select();
    document.execCommand("copy");
    temp.remove();
};


const createDownloadUrl = (data, type = "") => {
    return window.URL.createObjectURL(new Blob([data], {type: type}));
};


export const downloadFileByUrl = (url, name) => {
    if (!url) {
        return;
    }

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();

    setTimeout(function () {
        URL.revokeObjectURL(url);
    }, 0);
};


export const downloadDataAsFile = (data, name = "file.txt", type = "") => {
    if (data === undefined) {
        return;
    }

    downloadFileByUrl( createDownloadUrl(data, type), name );
};


export const clearLocalStorage = () => {
    const keysToSave = [
        "api_key", 
        "rtl", 
        "lang", 
        "userInfo", 
        "service",
        "LAST_SERVICE_BEFORE_SESSION_EXPIRED",
        "LAST_PAGE_BEFORE_SESSION_EXPIRED"
    ];
    const rulePerPage = new RegExp("PerPage$")

    for (const key in localStorage) {
        if (localStorage.hasOwnProperty(key) && !keysToSave.includes(key) && !rulePerPage.test(key)) {
            delete localStorage[key];
        }
    }
};


export const onDownloadCompletePrefixesCSV = async (trunkId, setUploadingLoading, service = true, filter = null) => {
    const method = getServiceSmsPrefix(service, "trunk_number__get_list");

    await getFileResponse(method, {
        target: (service ? {
            trunk_id: trunkId
        } : {
            "sms.trunk_id": trunkId
        }),
        filter: {...(filter ? filter : {})}
    })
        .then((response) => {
            const csvText = response.text();
            csvText.then((text) => {

                const csvPreparedData = text ? makeRangeFromPrefixesToCsv(text).split("\n") : [];
                const csvContentList = getCsvDataWithHeaders(csvPreparedData);
                const csvHeaderList = csvContentList[0].split(";");
                const csvDataList = csvContentList.slice(1).map(line => {
                    const items = line.split(";");
                    return [items[0], ...items.slice(1)].join(";")
                });
                const csvHeaders = [csvHeaderList[0], ...csvHeaderList.slice(1)].join(";");
                csvDataList.unshift(csvHeaders);

                const csvData = csvDataList.join("\n");
                downloadDataAsFile(csvData, "allocated_numbers.csv");
                setUploadingLoading(trunkId, false);

            });
        });
};


export const onDownloadCompleteNumbersCSV = async (trunkId, setUploadingLoading, service = true, filter = null) => {
    const method = getServiceSmsPrefix(service, "trunk_number__get_list");
    
    await getFileResponse(method, {
        target: (service ? {
            trunk_id: trunkId
        } : {
            "sms.trunk_id": trunkId
        }),
        filter: {...(filter ? filter : {})}
    })
        .then((response) => {
            const csvText = response.text();
            csvText.then((text) => {

                const csvContent = makeNumbersFromPrefixesToCsv(text);
                const csvHeaderList = csvContent.split("\n")[0].split(";");
                const csvDataList = csvContent.split("\n").slice(1).map(line => {
                    const items = line.split(";");
                    return [items[0], ...items.slice(2)].join(";")
                });
                const csvHeaders = [csvHeaderList[0], ...csvHeaderList.slice(2)].join(";");
                csvDataList.unshift(csvHeaders);

                const csvData = csvDataList.join("\n");
                downloadDataAsFile(csvData, "allocated_numbers.csv");
                setUploadingLoading(trunkId, false);

            });
        });
};


export const onDownloadCompleteOnlyNumbersCSV = async (trunkId, setUploadingLoading, service = true, filter = null) => {
    const method = getServiceSmsPrefix(service, "trunk_number__get_list");
    
    await getFileResponse(method, {
        target: (service ? {
            trunk_id: trunkId
        } : {
            "sms.trunk_id": trunkId
        }),
        filter: {...(filter ? filter : {})}
    })
        .then((response) => {
            const csvText = response.text();
            csvText.then((text) => {

                const csvContent = makeNumbersFromPrefixesToCsv(text);
                const csvDataList = csvContent.split("\n").slice(1).map(data => {
                    const line = data.split(";");
                    return line[0]
                });

                const csvData = csvDataList.join("\n");
                downloadDataAsFile(csvData, "allocated_numbers.csv");
                setUploadingLoading(trunkId, false);
                
            });
        });
};


export const downloadNumbersFile = (csvStringsArray, allocateByPrefixesStatus = false, name = "numbers.csv") => {
    csvStringsArray.pop();
    const csvDataArray = csvStringsArray.map((item) => item.split(";"));

    const numbersOnlyArray = [];

    for (let rowIndex = 0; rowIndex < csvDataArray.length; rowIndex++) {
        const [prefix, ratio] = csvDataArray[rowIndex];

        // get header
        if (rowIndex === 0) {
            continue;
        }

        const count = Math.pow(10, ratio);

        if (count > 1) {

            // push numbers
            for (let numberIndex = 0; numberIndex < count; numberIndex++) {
                const chunk = ratio > 1 ? (count + numberIndex + "").slice(1) : numberIndex;
                const number = prefix + chunk;
                numbersOnlyArray.push(number);
            }

            continue;

        } else {
            numbersOnlyArray.push(prefix);
        }
    }

    const url = createDownloadUrl(numbersOnlyArray.join("\r\n"), "text/csv;charset=utf-8;");
    downloadFileByUrl(url, name);
};


export const downloadNumbersRangeFile = (csvStringsArray, allocateByPrefixesStatus = false, name = "numbers.csv") => {
    csvStringsArray.pop();
    const csvDataArray = csvStringsArray.map((item) => item.split(";"));
    const numbersFullArray = [];

    for (let rowIndex = 0; rowIndex < csvDataArray.length; rowIndex++) {
        const [prefix, ratio, ...rest] = csvDataArray[rowIndex];

        // get header
        if (rowIndex === 0) {
            numbersFullArray.push(["Numbers", ...rest].join(";"));
            continue;
        }

        const count = Math.pow(10, ratio);

        if (count > 1) {

            // push numbers
            for (let numberIndex = 0; numberIndex < count; numberIndex++) {
                const chunk = ratio > 1 ? (count + numberIndex + "").slice(1) : numberIndex;
                const number = prefix + chunk;
                numbersFullArray.push([number, ...rest].join(";"));
            }

        } else {
            numbersFullArray.push([prefix, ...rest].join(";"));
        }
    }

    const url = createDownloadUrl(numbersFullArray.join("\r\n"), "text/csv;charset=utf-8;");
    downloadFileByUrl(url, name);
};


export const downloadPrefixesRangeFile = (csvStringsArray, allocateByPrefixesStatus = false, name = "numbers.csv") => {
    csvStringsArray.pop();
    const csvDataArray = csvStringsArray.map((item) => item.split(";"));
    const prefixesArray = [];

    for (let rowIndex = 0; rowIndex < csvDataArray.length; rowIndex++) {
        const [prefix, ratio, ...rest] = csvDataArray[rowIndex];

        // get header
        if (rowIndex === 0) {
            prefixesArray.push([prefix, ...rest].join(";"));
            continue;
        }

        const count = Math.pow(10, ratio);
        // push prefix
        const firstNumber = prefix + ("0".repeat(ratio));
        const lastNumber = ratio > 0 ? prefix + (count - 1) : firstNumber;
        const prefixNumbers = `${firstNumber}-${lastNumber}`;
        prefixesArray.push([prefixNumbers, ...rest].join(";"));
    }

    const url = createDownloadUrl(prefixesArray.join("\r\n"), "text/csv;charset=utf-8;");
    downloadFileByUrl(url, name);
};



/* PDF */


export const savePdfByTemplate = (data, headers, name, template, summary, account = {}) => {
    const doc = new JSPDF();
    const textToParse = template && template.text ? template.text.text : "";
    const objectForParseText = summary;

    const emptyKeysObject = {};
    objectForParseText && Object.keys(objectForParseText).map(key => {
        emptyKeysObject[`%${key}%`] = objectForParseText[key];
    });
    const parsedText = textToParse.replace(/%(\w+)%/g, (_, key) => {
        return emptyKeysObject[`%${key}%`];
    });
    try {
        doc.setFontSize(template.setFontSize.size);
        doc.addImage(template.addImage.imageData, "base64", template.addImage.x, template.addImage.y, template.addImage.w, template.addImage.h);
        doc.text(parsedText, template.text.x, template.text.y, template.text.options);
        autoTable(doc, {
            ...template.autoTable.options,
            body: data,
            head: headers
        });
        doc.save(`${name}.pdf`);
    } catch (e) {
        Alert.error("No template for you")
    }
};

export const replaceAllNullsInObject = (obj) => {
    return obj && Object.keys(obj).reduce((res, key) => {
        console.log("REDUCE::", obj, res, key, res[key], obj[key])
        // res[key] = obj[key] || ""
        return res
    }, obj) || {}
};

export const useStateCallback = (initialState) => {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null); // init mutable ref container for callbacks

    const setStateCallback = useCallback((state, cb) => {
        cbRef.current = cb; // store current, passed callback in ref
        setState(state);
    }, []); // keep object reference stable, exactly like `useState`

    useEffect(() => {
        // cb.current is `null` on initial render,
        // so we only invoke callback on state *updates*
        if (cbRef.current) {
            cbRef.current(state);
            cbRef.current = null; // reset callback after execution
        }
    }, [state]);

    return [state, setStateCallback];
};

export const checkIfArrayIsUnique = (array) => {
    return array.length === new Set(array).size;
};

export const getFormattedDatetime = (string) => {
    let d = new Date(string);

    d = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);

    return d;
};

export const getAccountInfoFromSession = (session, service) => {
    const accountList = session?.account_list || [];

    const serviceTransformed = service ? "voice" : "sms";
    const accountInfo = accountList.length > 1 ? accountList.find(item => item.service === serviceTransformed) 
        : accountList.length === 1 ? accountList[0] 
        : {};
    return accountInfo;
};

export const getTargetByService = (target, service) => {
    return {
        ...(!service ? {
            "sms.trunk_id": target?.trunk_id,
            ...(({trunk_id, ...t}) => t)(target)
        } : {
            ...target
        })
    }
};


export const getServiceBySearchParams = (params, bool=false) => {
    if (!params) {
        return
    }

    const currentParamList = params.replace("?", "").split("&");
    const splitedParamsList = currentParamList.map(paramString => paramString.split("="));
    const serviceParam = splitedParamsList.find(splitedParam => splitedParam.length && splitedParam[0] === "service");

    if (!serviceParam) {
        return null;
    }

    const pathService = serviceParam.length > 1 && ["sms", "voice"].includes(serviceParam[1]) ? serviceParam[1] : null;

    const transformedService = {
        "voice": true,
        "sms": false
    };
    
    return  pathService ? bool ? transformedService[pathService] : pathService : null;
};

export const getServiceByLocation = (location) => {
    if (!location && !(location?.pathname)) {
        return
    }

    const pathPartList = location.pathname.split("-");
    const lastPathPart = pathPartList.length ? pathPartList[pathPartList.length-1] : null;
    const pathService = lastPathPart && ["sms", "voice"].includes(lastPathPart) ? lastPathPart : null;

    const transformedService = {
        "voice": true,
        "sms": false
    };
    
    return pathService ? transformedService[pathService] : null;
    // return false
};

export const clearFormRef = (formRef, validation = false) => {
    if (formRef && formRef?.current) {
        if ("_reactInternalFiber" in formRef.current && "child" in formRef.current._reactInternalFiber) {
            formRef.current._reactInternalFiber.child.stateNode.reset();
        }

        if (validation) {
            formRef.current.state.formValue = null;
        }
    }
}

export const servicePick = (service, str1, str2) => {
    return service ? str1 : str2;
};

export const servicePickWithNull = (service, str1, str2) => {
    if (service === null) {
        return str1;
    }

    return service ? str1 : str2;
};

export const getServiceSmsPrefix = (service, keyword) => {
    return service ? keyword : `sms.${keyword}`;
};

export const getManagedServices = (authInfo) => {
    const accountSession = authInfo?.session;
    const accountUser = accountSession && accountSession?.account_user;
    const managedServiceList = accountUser?.managed_service_list || [];

    return managedServiceList;
};

export const deleteAllDuplicates = (array, param) => {
    return array.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t[param] === value[param]
        ))
    );
};

export const copyArrayWithoutReference = (array = []) => {
    const stringifyArray = JSON.parse(JSON.stringify(array));

    return stringifyArray.map(object => ({ ...object }));
};

export const capitalizeString = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const pickObjectExist = (obj1, obj2) => {
    return Object.keys(obj1) ? obj1 : obj2;
};

    
export const formatPercentage = (value, numbersAfterPeriod=null) => {
    let multipliedNumber = parseFloat(value) * 100;
    
    if (Number.isInteger(multipliedNumber) || !numbersAfterPeriod) {
        return parseInt(multipliedNumber.toFixed(0));
    } else {
        return parseFloat(multipliedNumber.toFixed(numbersAfterPeriod));
    }
};

export const onCopyList = (alert, text) => {
    copyToClipboard(text);
    alert.success("Copied!")
};

    
export const downloadList = (list, name="list", type="csv", callback) => {
    const encodedUri = encodeURI(list);
    const link = document.createElement("a");

    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${name}.${type}`);
    document.body.appendChild(link);
    link.click();

    if (callback) {
        callback();
    }
};

export const range = (length) => {
    return [...Array(length).keys()];
};

export const fixedFloat = (value, fraction=1) => {
    return parseFloat(parseFloat(value).toFixed(fraction));
};


export const fixedNumber = (value, fraction=1, roundInteger=false, parse=true) => {
    if (roundInteger && Number.isInteger(value)) {
        if (parse) {
            return value;
        }

        return value.toString();
    }

    const floatValue = parseFloat(value).toFixed(fraction);

    if (parse) {
        return parseFloat(floatValue);
    }

    return floatValue;
};

export const appendIf = (flag, obj) => {
    return flag ? [obj] : [];
};