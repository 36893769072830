import {ACCESS_LIST as PREFIX, DEFAULT_PER_PAGE_MEDIUM, DESC_SORT, SET_LOADING_ACCESS_EXCLUSION} from '../const';
import {api} from '../api/loginRoutes';
import {createAction} from './defaults';
import { 
    ACCESS_LIST_GET_LIST_API, SMS_ACCESS_LIST_GET_LIST_API, 
    ACCESS_EXCLUSION_LIST_API, ACCESS_EXCLUSION_CREATE_API,
    ACCESS_EXCLUSION_MODIFY_API, ACCESS_EXCLUSION_REMOVE_API,
    SMS_ACCESS_EXCLUSION_LIST_API, SMS_ACCESS_EXCLUSION_CREATE_API,
    SMS_ACCESS_EXCLUSION_MODIFY_API, SMS_ACCESS_EXCLUSION_REMOVE_API,
    ACCESS_LIST_EXTENDED_GET_LIST_API, SMS_ACCESS_LIST_EXTENDED_GET_LIST_API
 } from "const/apiMethods";
// cancellation
import axios from 'axios';
import { servicePick } from 'utils';

let cancelToken = axios.CancelToken.source();

const setAccessList = createAction('SET_ITEMS_' + PREFIX);
const setLoading = createAction('SET_LOADING_' + PREFIX);
const setAccessExclusionList = createAction(ACCESS_EXCLUSION_LIST_API);
const setAccessExclusionLoading = createAction(SET_LOADING_ACCESS_EXCLUSION);


export const getAccessList = (filter, service, page = 1, per_page = DEFAULT_PER_PAGE_MEDIUM, sort = {}, spAuth = false, extended=false) => (dispatch) => {
    dispatch(setLoading(true));

    let sort1, sort1_desc;

    if (sort.column) {
        sort1 = sort.column;
        sort1_desc = sort.type && sort.type === DESC_SORT;
    }

    const {no_number, sp_key, min_numbers, min_atx_numbers, min_dialer_numbers, rate, ...remainingFilter} = filter;

    const spKeyList = !remainingFilter.sp_key_list || !remainingFilter.sp_key_list.length ? null : remainingFilter.sp_key_list;

    const params = {
        ...remainingFilter,
        ...(sp_key ? {sp_key} : {}),
        ...(!extended ? {sp_key_list: spKeyList} : {}),
        ...(extended ? {
            ...(rate ? {rate: parseFloat(rate)} : {}), 
            ...(min_numbers ? {min_numbers: parseInt(min_numbers)} : {}), 
            ...(min_atx_numbers ? {min_atx_numbers: parseInt(min_atx_numbers)} : {}), 
            ...(min_dialer_numbers ? {min_dialer_numbers: parseInt(min_dialer_numbers)} : {})
        } : {})
    };

    const method = !extended 
        ? spAuth 
            ? "access_list_otp__get_list" 
            : servicePick(service, ACCESS_LIST_GET_LIST_API, SMS_ACCESS_LIST_GET_LIST_API)
        : servicePick(service, ACCESS_LIST_EXTENDED_GET_LIST_API, SMS_ACCESS_LIST_EXTENDED_GET_LIST_API);

    if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
    }

    cancelToken = axios.CancelToken.source();

    api(method, {filter: params, no_number, page, per_page, sort1, sort1_desc}, false, cancelToken)
        .then((response) => {
            
            if (axios.isCancel(response)) {
                return;
            }

            if (response === undefined) {
                dispatch(setLoading(false));
                return;
            }

            const {access_list_count = 0, access_list_list = []} = response;

            localStorage.setItem(PREFIX + '_per_page', per_page);

            dispatch(setAccessList({
                count: access_list_count,
                access_list_list,
                page,
                per_page
            }));

            dispatch(setLoading(false))
        });
};

export const getAccessExclusionList = (service) => (dispatch) => {
    const method = service ? ACCESS_EXCLUSION_LIST_API : SMS_ACCESS_EXCLUSION_LIST_API;

    api(method, {target: {}})
        .then(({access_list_exclusion_list = []}) => {
            dispatch(setAccessExclusionList(access_list_exclusion_list));
        })
        .finally(() => dispatch(setAccessExclusionLoading(false)));
};
export const onAddAccessExclusionItem = (service, data) => async (dispatch) => {
    const method = service ? ACCESS_EXCLUSION_CREATE_API : SMS_ACCESS_EXCLUSION_CREATE_API;

    return await api(
        method,
        {
            target: {},
            ...data,
        }
    );
};
export const onEditAccessExclusionItem = (service, alx_key, data) => async (dispatch) => {
    const method = service ? ACCESS_EXCLUSION_MODIFY_API : SMS_ACCESS_EXCLUSION_MODIFY_API;

    return await api(
        method,
        {
            target: {
                ...(service ? {alx_key} : {'sms.alx_key': alx_key})
            },
            ...data,
        }
    );
};
export const onRemoveAccessExclusionItem = (service, alx_key) => async (dispatch) => {
    const method = service ? ACCESS_EXCLUSION_REMOVE_API : SMS_ACCESS_EXCLUSION_REMOVE_API;
    
    return await api(
        method,
        {
            target: {
                ...(service ? {alx_key} : {'sms.alx_key': alx_key})
            }
        }
    );
};