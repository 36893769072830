import React, {useState, useEffect, useMemo, useRef} from "react";
import BaseTable from "components/base/BaseTable";
import {Form, FormControl, InputNumber, Table, Schema, Checkbox, Alert} from "rsuite";
import styled from "styled-components";
import {ButtonDefault, ButtonPrimary} from "components/base/BaseButton";
import {Spacer} from "components/base/Spacer";
import {api} from "api/loginRoutes";
import ModalApprove from "./ModalApprove";
import { checkPermissionsFor } from "store/storeHelpers";

import {ACCOUNT_SERVICE_PLAN_DEACTIVATE_TRUNK_LIST, ACCOUNT_SERVICE_PLAN_SET_LIST_API} from "const/apiMethods";

const {Column, HeaderCell, Cell} = Table;

const {NumberType} = Schema.Types;


export default (
    {
        data,
        dataDefault,
        loading,
        update,
        account,
        roleList,
        getAccount,
    }
) => {

    const formRef = useRef(null);
    const currentParams = useRef(null);
    const [valueForm, setValueForm] = useState({});
    const [isChanged, setIsChanged] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [modalApproveShow, setModalApproveShow] = useState(false);
    const [modalApproveLoading, setModalApproveLoading] = useState(false);
    const [modalApproveData, setModalApproveData] = useState([]);


    useEffect(() => {
        setValueForm( normalizeFormData(data) );
    }, [data]);


    const spList = useMemo(() => data.map(item => item.sp_key), [data]);


    const formModel = useMemo(() => {

        const models = data.reduce((summ, item) => {

            const onceValue = +valueForm[`once__${item.sp_key}`];
            const dailyValue = +valueForm[`daily__${item.sp_key}`];
            const dailySdeValue = +valueForm[`daily_sde__${item.sp_key}`];

            const onceLimit = dailyValue < dailySdeValue ? dailyValue : dailySdeValue;

            return [
                ...summ,
                Schema.Model({

                    [`after_last_call__${item.sp_key}`]: NumberType()
                        .addRule((value, data) => {
                            if (!data[`allowed__${item.sp_key}`] || !data[`autorevoke__${item.sp_key}`]) {
                                return true;
                            }
                            return value >= 0.1
                        }, "Min value 0.1", true),

                    [`after_allocation__${item.sp_key}`]: NumberType()
                        .addRule((value, data) => {
                            if (!data[`allowed__${item.sp_key}`] || !data[`autorevoke__${item.sp_key}`]) {
                                return true;
                            }
                            return value >= 0.1
                        }, "Min value 0.1", true),

                    [`once__${item.sp_key}`]: NumberType()
                        .max(onceLimit, `Must be less than Daily and Destination`),

                    [`daily__${item.sp_key}`]: NumberType()
                        .min(onceValue, `Must be greater than One-time`),

                    [`daily_sde__${item.sp_key}`]: NumberType()
                        .min(onceValue, `Must be greater than One-time`),

                })
            ];
        }, []);

        return Schema.Model.combine(...models);

    }, [data, valueForm]);


    useEffect(() => {
        if (formRef?.current)
            formRef.current.check();
    }, [formRef, formModel]);


    const columns = useMemo(() => [
        {
            name: "Product",
            dataKey: "sp_name",
            value: ({sp_name}) => <span title={sp_name}>{sp_name}</span>,
            flexGrow: 2,
            minWidth: 192,
        },
        {
            name: "Allowed",
            dataKey: "allowed",
            fieldProps: {
                accepter: Checkbox,
                name: "allowed",
            }
        },
        {
            name: "Self-allocation allowed",
            dataKey: "allocate",
            flexGrow: 2,
            fieldProps: {
                accepter: Checkbox,
                name: "allocate",
            }
        },
        {
            name: "One-time",
            dataKey: "once",
            flexGrow: 2,
            minWidth: 192,
            fieldProps: {
                accepter: InputNumber,
                name: "once",
                min: 0,
            }
        },
        {
            name: "Daily",
            dataKey: "daily",
            flexGrow: 2,
            minWidth: 192,
            fieldProps: {
                accepter: InputNumber,
                name: "daily",
                min: 0,
            }
        },
        {
            name: "Destination",
            dataKey: "daily_sde",
            flexGrow: 2,
            minWidth: 192,
            fieldProps: {
                accepter: InputNumber,
                name: "daily_sde",
                min: 0,
            }
        },
        {
            name: "Autorevoke",
            dataKey: "autorevoke",
            fieldProps: {
                accepter: Checkbox,
                name: "autorevoke",
            }
        },
        {
            name: "From last call (hours)",
            dataKey: "after_last_call",
            flexGrow: 2,
            fieldProps: {
                accepter: InputNumber,
                name: "after_last_call",
                min: 0,
            }
        },
        {
            name: "After allocation (hours)",
            dataKey: "after_allocation",
            flexGrow: 2,
            fieldProps: {
                accepter: InputNumber,
                name: "after_allocation",
                min: 0,
            }
        },
        {
            name: "Max allocated numbers",
            dataKey: "all",
            flexGrow: 2,
            fieldProps: {
                accepter: InputNumber,
                name: "all",
                min: 0,
            }
        },
    ], []);


    const onSubmit = () => {
        if (!formRef.current.check()) {
            return;
        }

        setSubmitLoading(true);

        const paramsList = spList.reduce((summ, spKey) => {
            const permissionList = ["allocate", "autorevoke"].filter(item => valueForm[`${item}__${spKey}`]);

            return [
                ...summ,
                {
                    sp_key: spKey,
                    allowed: valueForm[`allowed__${spKey}`],
                    permission_list: permissionList,
                    allocation_limit: {
                        once: +valueForm[`once__${spKey}`],
                        daily: +valueForm[`daily__${spKey}`],
                        daily_sde: +valueForm[`daily_sde__${spKey}`],
                        after_last_call: +valueForm[`after_last_call__${spKey}`],
                        after_allocation: +valueForm[`after_allocation__${spKey}`],
                        all: +valueForm[`all__${spKey}`],
                        allowed: valueForm[`allowed__${spKey}`],
                        allocate:  valueForm[`allocate__${spKey}`],
                        autorevoke:  valueForm[`autorevoke__${spKey}`],
                    }
                }
            ]
        }, []);

        currentParams.current = {
            account_service_plan_list: paramsList,
            target: {account_id: account.id}
        };

        api(ACCOUNT_SERVICE_PLAN_DEACTIVATE_TRUNK_LIST, currentParams.current)
            .then(r => {
                if (!r) {
                    setSubmitLoading(false);
                    return;
                }

                if (!r.deactivate_trunk_list.length) {
                    submitData();
                    return;
                }

                setModalApproveShow(true);
                setModalApproveData(r.deactivate_trunk_list);
            });
    };

    const submitData = () => {
        setModalApproveLoading(true);
        api(ACCOUNT_SERVICE_PLAN_SET_LIST_API, currentParams.current)
            .then(r => {
                if (!r) {
                    return;
                }

                Alert.success("Self-allocation settings are successfully updated");
                setIsChanged(false);
                setSubmitLoading(false);
                setModalApproveShow(false);
                update();
            })
            .finally(() => {
                setModalApproveLoading(false);
            });
    };

    const normalizeFormData = (data) => {
        return data.reduce((summ, item) => {
            return {
                ...summ,
                [`allowed__${item.sp_key}`]: item.allowed,
                [`allocate__${item.sp_key}`]: item.allocate,
                [`autorevoke__${item.sp_key}`]: item.autorevoke,

                [`once__${item.sp_key}`]: item.once,
                [`daily__${item.sp_key}`]: item.daily,
                [`daily_sde__${item.sp_key}`]: item.daily_sde,
                [`after_last_call__${item.sp_key}`]: item.after_last_call,
                [`after_allocation__${item.sp_key}`]: item.after_allocation,
                [`all__${item.sp_key}`]: item.all,
            }
        }, {});
    };

    const handleCheckboxChange = (name, value) => {
        setValueForm(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const restoreFormValue = () => {
        setValueForm( normalizeFormData(data) );
        setIsChanged(false);
        formRef.current.cleanErrors();
        Alert.info("Form values has been restored to the previously saved settings");
    };

    const handleChangeFormValue = (value) => {
        setIsChanged(true);
        setValueForm(value)
    };

    
    const supervisorSmsRole = roleList.includes("Supervisor (sms)");
    const isSupervisorSms = roleList && roleList.length ? supervisorSmsRole : null;

    const showSaveButton = !isSupervisorSms || !!isSupervisorSms && checkPermissionsFor(ACCOUNT_SERVICE_PLAN_DEACTIVATE_TRUNK_LIST);

    return (
        <>
            <Form
                ref={formRef}
                model={formModel}
                onChange={handleChangeFormValue}
                formValue={valueForm}
            >
                <StyledBaseTable
                    data={data}
                    className={"tableFilters"}
                    autoHeight
                    headerHeight={46}
                    rowHeight={46}
                    loading={loading}
                    rowClassName={(rowData) => {
                        return rowData && !valueForm[`allowed__${rowData.sp_key}`] ? "row-disabled" : "";
                    }}
                >
                    {columns.map( ({dataKey, name, value, flexGrow = null, minWidth = 130, fieldProps}) => (

                        <Column {...{flexGrow, minWidth}}>

                            <HeaderCell>
                                <span className="tableFilters__headerText">{name || ""}</span>
                            </HeaderCell>

                            <Cell {...{dataKey}}>
                                {(rowData) => {
                                    if (!fieldProps) {
                                        const cellValue = (value && value(rowData)) || rowData[dataKey];
                                        return <span className="tableFilters__previewText">{cellValue}</span>;
                                    }

                                    const fieldName = `${fieldProps.name}__${rowData.sp_key}`;
                                    const fieldValue = valueForm[fieldName];

                                    if (fieldProps.accepter === Checkbox) {
                                        return <EditCheckbox
                                            {...fieldProps}
                                            checked={fieldValue}
                                            name={fieldName}
                                            onChange={(_, value) => handleCheckboxChange(fieldName, value)}
                                        />
                                    }

                                    const spDataDefault = dataDefault.find(item => item.sp_key === rowData.sp_key);
                                    const isDefault = spDataDefault[fieldProps.name] === +fieldValue;

                                    return <EditField
                                        title={`default: ${spDataDefault[fieldProps.name]}`}
                                        {...fieldProps}
                                        isDefault={isDefault}
                                        name={fieldName}
                                    />
                                }}
                            </Cell>
                        </Column>
                    ))}
                </StyledBaseTable>

                <Spacer/>
                <div style={{textAlign: "right"}}>

                    <ButtonDefault
                        onClick={restoreFormValue}
                        style={{marginRight: 20}}
                        disabled={!isChanged || submitLoading}
                    >
                        Restore
                    </ButtonDefault>

                    <ButtonPrimary
                        onClick={onSubmit}
                        disabled={!showSaveButton}
                        loading={submitLoading}
                    >
                        Save
                    </ButtonPrimary>
                </div>

            </Form>

            <ModalApprove
                show={modalApproveShow}
                loading={modalApproveLoading}
                data={modalApproveData}
                onSuccess={submitData}
                onClose={() => {
                    setModalApproveShow(false);
                    setSubmitLoading(false);
                }}
            />
        </>
    )
};


const EditField = styled(FormControl).attrs(() => ({
    className: "tableFilters_field",
    errorPlacement: "topEnd",
}))`
    .rs-input {
        color: ${props => props.isDefault ? "#8e8e93" : "inherit"};
    }
`;

const EditCheckbox = styled(FormControl).attrs(() => ({
}))``;

const StyledBaseTable = styled(BaseTable).attrs(() => ({
}))`
    .row-disabled .rs-table-cell {
        background: #ececec !important;
    }
`;