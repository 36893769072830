import React, {useMemo} from "react";
import {Table} from "rsuite";
import RenderedColumns from "components/Table/RenderColumns";
import Pagination from "components/base/Pagination"
import BaseTable from "components/base/BaseTable";
import {ButtonRowDelete, ButtonRowEdit} from "components/base/BaseButton";
import styled from "styled-components";
import { checkPermissionsFor } from "store/storeHelpers";
import {
    SMS_SUPPLIER_PRICE_REMOVE_LIST_METHOD,
    SMS_SUPPLIER_PRICE_MODIFY_LIST_METHOD
} from "const/apiMethods";

const { Column, HeaderCell, Cell } = Table;

export default ({
    data = [],
    loading = false,
    total,
    service,
    per_page,
    activePage,
    onChangePage,
    onChangePerPage,
    onClickDelete,
    onClickEdit,
    
    isSupplier,
    isSupervisorSms
}) => {

    const columns = useMemo(() => {
        return isSupplier ? [
            {
                label: "Operator", 
                dataKey: "operator",
                value: ({mccmnc_name}) => mccmnc_name,
                flexGrow: 1
            },
            {
                label: "MCC/MNC",
                dataKey: "mcc",
                value: ({mcc, mnc}) => `${mcc}${mnc}`,
                minWidth: 80
            },
            {
                label: "Rate",
                dataKey: "rate",
                align: "right",
                minWidth: 90
            },
            {
                label: "Start date",
                dataKey: "start_date",
                formatData: "date",
                align: "right",
                flexGrow: 1
            },
            {
                label: "End date",
                dataKey: "end_date",
                formatData: "date",
                align: "right",
                flexGrow: 1
            },
            {
                label: "Modified by",
                dataKey: "modify_user",
                flexGrow: 1
            },
            {
                label: "Modify date",
                dataKey: "modify_date",
                formatData: "datetime",
                align: "right",
                flexGrow: 1
            }
        ] : [
            {
                label: "Subdestination", 
                dataKey: "subdestination_name",
                value: ({subdestination_name, mcc, mnc}) => {
                    if (!service && isSupplier) {
                        return `${subdestination_name} (${mcc}${mnc ? " " : ""}${mnc})`
                    }
    
                    return subdestination_name;
                },
                flexGrow: 1
            },
            {
                label: "Rate",
                dataKey: "rate",
                align: "right",
                flexGrow: 1
            },
            {
                label: "Default rate",
                dataKey: "rate_default",
                align: "right",
                flexGrow: 1
            },
            {
                label: "Start date",
                dataKey: "start_date",
                formatData: "date",
                align: "right",
                flexGrow: 1
            },
            {
                label: "End date",
                dataKey: "end_date",
                formatData: "date",
                align: "right",
                flexGrow: 1
            }
        ];
    }, [isSupplier]);
    const renderedColumns = RenderedColumns(columns, {end_date: true});

    const getData = () =>  {
        const start = per_page * (activePage - 1);
        const end = start + per_page;
        
        return data.filter((v, i) => i >= start && i < end);
    };

    const _data = getData().map(item => ({
        ...item,
        start_date: item?.start_date ? item.start_date.replace(" ", "T") : "",
        end_date: item?.end_date ? item.end_date.replace(" ", "T") : "",
    }));

    const showModifyButton = !isSupervisorSms || isSupervisorSms && checkPermissionsFor(SMS_SUPPLIER_PRICE_MODIFY_LIST_METHOD);
    const showDeleteButton = !isSupervisorSms || isSupervisorSms && checkPermissionsFor(SMS_SUPPLIER_PRICE_REMOVE_LIST_METHOD);

    return (
        <>
            <StyledTable
                data={_data}
                loading={loading}
                rowHeight={30}
                rowClassName={(rowData) => {
                    if (rowData && rowData.rate_default !== null && !isSupplier) {
                        return rowData.rate_default > rowData.rate ? "red" : "green";
                    }
                }}
                // rowClassName={}
                height={ ((_data.length * 30) || 100) + 50}
            >
                {renderedColumns}
                {showModifyButton && <Column width={50}>
                    <HeaderCell/>
                    <Cell style={{overflow: "unset"}}>
                        {(rate) => <ButtonRowEdit onClick = { () => onClickEdit(rate)} style={{top: "-2px"}}/> }
                    </Cell>
                </Column>}
                {showDeleteButton && <Column width={50}>
                    <HeaderCell/>
                    <Cell style={{overflow: "unset"}}>
                        {(body) => <ButtonRowDelete onClick = { () => {
                            const deleteID = isSupplier ? body?.key : body?.id;

                            onClickDelete(deleteID);
                        }} style={{top: "-2px"}}/> }
                    </Cell>
                </Column>}
            </StyledTable>
            <Pagination {...{total,per_page, activePage, onChangePage, onChangePerPage}} />
        </>
    );
};

const StyledTable = styled(BaseTable)`
    .rs-table-row.green .rs-table-cell {
        background: #caface;
    }
    .rs-table-row.red .rs-table-cell {
        background: #ebd1d3;
    }
`;