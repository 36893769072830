import {
    REGISTRATION_APPROVE_API,
    REGISTRATION_CREATE_API, REGISTRATION_GET_COUNT_API,
    REGISTRATION_GET_LIST_API, REGISTRATION_REJECT_API
} from "const/apiMethods"
import {
    REGISTRATION_LIST,
    REGISTRATION_COUNT,
    REGISTRATION_SET_LOADING
} from "const";
import { api } from "api/loginRoutes";
import {createAction} from "./defaults";

const setRegistrationList = createAction(REGISTRATION_LIST);
const setRegistrationCount = createAction(REGISTRATION_COUNT);
const setLoading = createAction(REGISTRATION_SET_LOADING);

export const registerCreate = (data) => async (dispatch) => {
    return await api(REGISTRATION_CREATE_API, data);
};

export const getRegistrationList = (data) => (dispatch) => {
    dispatch(setLoading(true));

    api(REGISTRATION_GET_LIST_API, data).then( (response) => {
        if (response) {
            dispatch(setRegistrationList(response));
        }
    }).finally(() => {
        dispatch(setLoading(false));
    })
};

export const getRegistrationCount = (data) => (dispatch) => {
    api(REGISTRATION_GET_COUNT_API, data).then( (response) => {
        if (response) {
            dispatch(setRegistrationCount(response));
        }
    })
};
export const approveRegistration = (data) => async (dispatch) => {
    return await api(REGISTRATION_APPROVE_API, data);
};

export const rejectRegistration = (data) => async (dispatch) => {
    return await api(REGISTRATION_REJECT_API, data);
};