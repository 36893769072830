
import {
    CONTACTS_DIALER as PREFIX,
} from "const";
import {
    ACCOUNT_CONTACT_GET_LIST_METHOD,
    ACCOUNT_CONTACT_MODIFY_BY_CLIENT_METHOD,
    ACCOUNT_CONTACT_MODIFY_REQUEST_METHOD
} from "const/apiMethods";

import {api} from "api/loginRoutes";
import {_setValue} from "./defaults";

const setList = _setValue("SET_ITEMS_" + PREFIX);
const setLoading = _setValue("SET_LOADING" + PREFIX);


export const setLoadingData = (value) => (dispatch) => {
    dispatch(setLoading(value));
};

export const getAccountContactList = (params) => (dispatch) => {
    dispatch(setLoading(true));
    
    const method = ACCOUNT_CONTACT_GET_LIST_METHOD;
        
    api(method, params).then((result) => {
        if (result?.account_contact_list) {
            dispatch(setList(result?.account_contact_list));
        }
    })
    .finally(() => {
        dispatch(setLoading(false));
    })
};

export const modifyAccountContact = (id, data, requestLetter = false) => async (dispatch) => {
    dispatch(setLoading(true));
    
    const method = requestLetter 
        ? ACCOUNT_CONTACT_MODIFY_REQUEST_METHOD
        : ACCOUNT_CONTACT_MODIFY_BY_CLIENT_METHOD;

    const params = {
        target: {
            account_contact_id: id
        },
        ...data
    };
        
    return await api(method, params);
};