import {connect} from "react-redux";
import PageIvrSpecialCli from "./PageIvrSpecialCli";
import {getIvrCliList, setNewIvrCLI, getTypeFamily} from "actions/ivr_cli";
import {getIvrList} from "actions/ivr";

const mapState = ({ivr_cli, ivr, auth_numbers}) => ({
    data: ivr_cli.type_family_data,
    typeFamilyList: ivr_cli.type_family_dropdown_list,
    ivrList: ivr.list,
});

export default connect(mapState, {
    setNewIvrCLI,

    getIvrCliList,
    getIvrList,
    getTypeFamily
})(PageIvrSpecialCli);