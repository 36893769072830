import React from "react";
import {Icon, IconButton, Table as _Table} from "rsuite";
import AddNewRange from "./AddNewRange";
import Table from "components/Table";
import RangesFilters from "./RangesFilters";
import styled from "styled-components";
import {withRouter} from "react-router";
import queryString from "query-string";
import {ButtonRow} from "components/base/BaseButton";


const Header = styled.div`
    margin-bottom: -20px;
    > div {
        display: inline-block;
        width: 50%
    }
    >div:last-child{
        text-align: right
    }
`;

const {Column, HeaderCell, Cell} = _Table;
const ActionCell = (onClickList) => (
    <Column align="center">
        <HeaderCell>Options</HeaderCell>
        <Cell>
            {({sde_key}) => (
                <div style={{position: "relative", "top": "-2px"}}>
                    <ButtonRow
                        as={IconButton}
                        mr={0}
                        icon={<Icon icon="list"/>}
                        onClick={() => onClickList(sde_key)}
                    />
                </div>
            )}
        </Cell>
    </Column>
);

class Ranges extends React.Component {
    constructor(props) {
        super(props);
        this.filters = {};
        this.filterform = null;

        this.state = {
            columns: [
                {
                    label: "Range name",
                    dataKey: "name",
                    value: ({name, mcc, mnc}) => mcc ? `${name} (${mcc}${mnc || ""})` : name,
                    flexGrow: 1
                },
                {
                    label: "Range prefix",
                    dataKey: "prefix",
                    flexGrow: 1
                },
                {
                    label: "Test numbers",
                    dataKey: "test_number",
                    flexGrow: 1
                },
                {label: "Numbers", dataKey: "all_numbers", align: "left"},
                {label: "Allocated", dataKey: "allocated_numbers", align: "left"},
            ]
        }
    }


    onUpdateRef = (ref) => this.filterform = ref;


    onChangeFilters = (filters) => {
        const {update, onChangeFilters, service} = this.props;
        this.filters = filters;

        update(1, filters);
        onChangeFilters(filters);
    };

    onClickList = () => {
        const {sde_key, history, filters} = this.props;
        const params = {...filters, sde_key};
        const query = queryString.stringify(params);

        history.push(`/ranges-numbers?${query}`);
    };

    render() {
        const {columns} = this.state;
        const {items, loading, count, page, sde_key, service, update, showAddRangesButton} = this.props;

        return (
            <>
                <Header>
                    <div>
                        <RangesFilters
                            service={service}
                            onChange={this.onChangeFilters}
                            onUpdateRef={this.onUpdateRef}/>
                    </div>
                    <div>
                        {showAddRangesButton && <AddNewRange
                            service={service}
                            sde_key={sde_key}
                            disabled={!!sde_key}
                            update={() => update(page)}
                        />}
                    </div>
                </Header>
                <Table
                    data={items}
                    height="50%"
                    width="100%"
                    loading={loading}
                    columns={columns}
                    count={count}
                    page={page}
                    getItems={(page) => update(page)}
                    ispagination
                    hidePerPage
                    ActionCell={ActionCell(this.onClickList)}
                />
            </>
        );

    }
}

export default withRouter(Ranges);