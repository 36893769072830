import {IVR_CLI as PREFIX} from "const";
import {api} from "api/loginRoutes";
import {createAction} from  "./defaults";
import {IVR_CLI_LIST_API, AUTH_AUTH_NUMBER_TYPE_SET_IVR, AUTH_AUTH_NUMBER_TYPE_GET_LIST} from "const/apiMethods";

const setIvrCliList = createAction("SET_ITEMS_" + PREFIX);
const setLoading = createAction("SET_LOADING_" + PREFIX);
const setTypeFamilyData = createAction("SET_TYPE_FAMILY_DATA_" + PREFIX)
const setTypeFamilyDropdownList= createAction("SET_TYPE_FAMILY_DROPDOWN_LIST_" + PREFIX)

export const getIvrCliList = (filter = {}) => (dispatch) => {
    dispatch(setLoading(true));
    api(IVR_CLI_LIST_API, {filter})
        .then(({ivr_cli_list}) => {
            if (ivr_cli_list === undefined) {
                return;
            }
            dispatch(setIvrCliList({
                list: ivr_cli_list.map(item => ({
                    ...item,
                    ivr_id: item.ivr ? item.ivr.id : "",
                    ivr_name: item.ivr ? item.ivr.name : "",
                }))
            }));
        })
        .finally(() => dispatch(setLoading(false)));
};

export const setNewIvrCLI = (data) => async (dispatch) => {
    const params = {
        ...data
    };
    
    return await api(AUTH_AUTH_NUMBER_TYPE_SET_IVR, params);
};

export const getTypeFamily = (filter=null, dropdown=false) => (dispatch) => {
    const method = AUTH_AUTH_NUMBER_TYPE_GET_LIST;

    const params = {
        ...(filter && !dropdown ? {filter} : {}),
        ...(!filter && dropdown ? {filter: {only_with_auth_number: true}} : {}),
        ...(filter && dropdown ? {filter: {...filter, only_with_auth_number: true}} : {})
    };

    api(method, params).then( ({ auth_number_type_family_list = [] }) => {
        if (dropdown) {
            dispatch(setTypeFamilyDropdownList({
                type_family_dropdown_list: auth_number_type_family_list || []
            }))
        } else {
            dispatch(setTypeFamilyData({
                type_family_data: {
                    filtered: !!filter || false,
                    list: auth_number_type_family_list || []
                }
            }));
        };
    })
};