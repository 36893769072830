import React, {useContext, useMemo, useRef, useState} from "react";
import {SelectPicker, Schema, Input, FormControl, Form, Whisper, Tooltip} from "rsuite";
import TableEditCells from "components/Table/TableEditCells/TableEditCellsNew";
import styled from "styled-components";
import IvrListener from "components/IvrListener/IvrListener";
import {PermissionContext} from "../../../App";
import {
    AUTH_AUTH_NUMBER_TYPE_SET_IVR,
    IVR_CLI_MODIFY_API,
    IVR_CLI_REMOVE_API
} from "const/apiMethods";
import CustomField from "components/Form/CustomField";
import { checkPermissionsFor } from "store/storeHelpers";


const {StringType, NumberType} = Schema.Types;

const formModelTypeFamily = Schema.Model({
    antf_key: NumberType()
        .isRequired("Required")
});
const formModelIvr = Schema.Model({
    ivr_id: StringType()
        .isRequired("Required")
});
const formModel = Schema.Model.combine(
    formModelTypeFamily,
    formModelIvr
);


export default ({
    list = [],
    dropdownList = [],
    noIvrDropdownList = [],
    filter,
    loading,
    ivrList,
    handleCreate,
    handleSubmit,
    handleRemove,

    update,
    ...props
}) => {

    const permissions = useContext(PermissionContext);

    const handleUpdate = () => {
        update();
    };

    const [currentIvrPrefix, setCurrentIvrPrefix] = useState(null);
    const cliRefs = useRef(new Map());


    const canCreate = checkPermissionsFor(AUTH_AUTH_NUMBER_TYPE_SET_IVR);
    const canModify = checkPermissionsFor(AUTH_AUTH_NUMBER_TYPE_SET_IVR);
    const canRemove = checkPermissionsFor(AUTH_AUTH_NUMBER_TYPE_SET_IVR);

    const columns = useMemo(() => [
        {
            dataKey: "antf_key",
            dataValue: "antf_key",
            flexGrow: 2,
            name: "Service Name",
            // customField: true,

            value: ({family_name}) => {
                return family_name;
                // const serviceListText = ivr?.service_list.join(", ");
                // return <Whisper 
                //     speaker={<Tooltip>{serviceListText}</Tooltip>}
                //     placement="bottomStart"
                // >
                //     <span>{serviceListText}</span>
                // </Whisper>
            },

            placeholder: "Service Name",
            accepter: SelectPicker,
            model: formModelTypeFamily,
            
            customData: true,
            modifyData: dropdownList,
            createData: noIvrDropdownList,
            valueKey: "id",
            labelKey: "name",
            cleanable: false
        },
        {
            dataKey: "ivr_id",
            dataValue: "ivr_id",
            flexGrow: 2,
            name: "IVR",

            value: ({ivr_name}) => ivr_name,

            placeholder: "IVR",
            accepter: SelectPicker,
            model: formModelIvr,

            data: ivrList,
            valueKey: "id",
            labelKey: "name",
            cleanable: false
        },
        {
            dataKey: "listen",
            customValue: ({ivr_id, prefix}) => (
                <StyledButtonPlay buttonStyle="secondary">
                    <IvrListener
                        onClick={() => {
                            if (currentIvrPrefix && currentIvrPrefix !== prefix) {
                                cliRefs.current.get(currentIvrPrefix).stop();
                            }
                            setCurrentIvrPrefix(prefix);
                        }}
                        ref={ref => cliRefs.current.set(prefix, ref)}
                        ivrId={ivr_id}
                    />
                </StyledButtonPlay>
            )
        }
    ], [list, dropdownList, noIvrDropdownList, ivrList, currentIvrPrefix]);

    return (
        <>
            <TableEditCells
                rowIdKey="antf_key"
                data={list}
                loading={loading}
                columns={columns}
                height={643}
                editable={canCreate || canModify || canRemove}

                formModel={formModel}

                onDataUpdate={handleUpdate}
                onRowCreate={handleCreate}
                onRowSubmit={handleSubmit}
                onRowRemove={handleRemove}

                ivrList={ivrList}

                autoHeight={false}
                canCreate={canCreate}
                canModify={canModify}
                canRemove={canRemove}

                {...props}
            />
        </>
    )
}

const StyledButtonPlay = styled.div`
    && {
        .rs-btn {
            width: 28px;
            height: 28px;
            line-height: 28px;
            border-radius: 28px;
            margin: -3px 0 0;
        }
        .rs-icon {
            line-height: 28px;
            font-size: 13px;
        }
    }
`;
