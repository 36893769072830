import {connect} from "react-redux";
import SelfAllocationRestrictions from "./SelfAllocationRestrictions";
import {getAccountServicePlanList} from "actions/self_allocation_restrictions";

const mapState = ({self_allocation_restrictions, roles}) => ({
    data: self_allocation_restrictions.list,
    loading: self_allocation_restrictions.loading,
    roleList: roles.role_list
});

export default connect(mapState, {
    getAccountServicePlanList
})(SelfAllocationRestrictions);