import {connect} from "react-redux";
import {getSupplierPlanList, createSupplierPlan, modifySupplierPlan, removeSupplierPlan} from "actions/suppliers";
import AccessList from "./AccessList";
import {showReasonHandler} from "actions/reason_handler";

const mapStateToProps = ({suppliers, roles}) => ({
    loading: suppliers.suppliersPlanLoading,
    suppliersPlanList: suppliers.suppliersPlanList,
    roleList: roles.role_list
});

const mapDispatchToProps = {
    getSupplierPlanList,
    createSupplierPlan,
    modifySupplierPlan,
    removeSupplierPlan,
    showReasonHandler
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessList);