import {
    CONTACTS_DIALER as PREFIX
} from "const";

const initialState = {
    items: [],
    loading: false
};

const handlers = {
    ["SET_ITEMS_" + PREFIX]: (state, {payload}) => ({
        ...state,
        items: payload,
        loading: false
    }),
    ["SET_LOADING_" + PREFIX]: (state, {payload}) => ({...state, loading: payload}),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};