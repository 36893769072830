import React, {useEffect, useState, useMemo, useRef} from "react";
import styled from "styled-components";
import {Loader, Panel, Schema, Alert} from "rsuite";

import {api} from "../../../../api/loginRoutes";
import {generateId} from "../../../../utils";
import {IVR_CREATE_API, IVR_MODIFY_API, IVR_REMOVE_API} from "../../../../const/apiMethods";

import {CustomModal} from "../../../../components/base";
import {FlexGrid, FlexGridItem} from "../../../../components/base/FlexGrid";

import ActionParams from "./ActionParams";
import BlockIvrHeader from "./BlockIvrHeader";
import BlockIvrFooter from "./BlockIvrFooter";
import BlockIvrActions from "./BlockIvrActions";


const {StringType, NumberType} = Schema.Types;

const nameFormModel = Schema.Model({
    name: StringType().isRequired("Name is required")
});

const formModelRejectCall = Schema.Model({
    percent: NumberType()
        .isRequired("Is required")
        .min(0, "Min number is 0")
        .max(100, "Max number is 100"),
    sip_480: NumberType()
        .min(0, "Min number is 0")
        .max(100, "Max number is 100"),
    sip_486: NumberType()
        .min(0, "Min number is 0")
        .max(100, "Max number is 100"),
    sip_503: NumberType()
        .min(0, "Min number is 0")
        .max(100, "Max number is 100"),
    sip_403: NumberType()
        .min(0, "Min number is 0")
        .max(100, "Max number is 100"),
    sip_487: NumberType()
        .min(0, "Min number is 0")
        .max(100, "Max number is 100"),
});

const formModelWaitBeforeConnect = Schema.Model({
    before_min: NumberType()
        .isRequired("Is required")
        .min(0, "Min number is 0"),
    before_max: NumberType()
        .isRequired("Is required")
        .min(0, "Min number is 0")
        .addRule((value, data) => (
            !(data.before_min && (+data.before_min > +value))
        ), "Max number must be larger then min number"),
    ringing_min: NumberType()
        .isRequired("Is required")
        .min(0, "Min number is 0"),
    ringing_max: NumberType()
        .isRequired("Is required")
        .min(0, "Min number is 0")
        .addRule((value, data) => (
            !(data.ringing_min && (+data.ringing_min > +value))
        ), "Max number must be larger then min number"),
});

const formModelPlayback = Schema.Model({
    playback_min: NumberType()
        .isRequired("Is required")
        .min(1, "Min number is 1"),
    playback_max: NumberType()
        .isRequired("Is required")
        .min(1, "Min number is 1")
        .addRule((value, data) => (
            !(data.playback_min && (+data.playback_min > +value))
        ), "Max number must be larger then min number"),
    sound: StringType().isRequired("Is required"),
});

const formModelPlaybackRepeat = Schema.Model({
    repetitions: NumberType()
        .isRequired("Is required"),
});

const formModelRadio = Schema.Model({
    radio_min: NumberType()
        .isRequired("Is required")
        .min(1, "Min number is 1"),
    radio_max: NumberType()
        .isRequired("Is required")
        .min(1, "Min number is 1")
        .addRule((value, data) => (
            !(data.radio_min && (+data.radio_min > +value))
        ), "Max number must be larger then min number"),
});

const formModelSendDtmf = Schema.Model({
    digit: StringType()
        .isRequired("Is required")
        .pattern(/^[0-9,#*]*$/, 'Please enter the correct digit')
        .maxLength(1, 'Please enter the correct digit')
});

const formModelSilence = Schema.Model({
    silence_min: NumberType()
        .isRequired("Is required")
        .min(1, "Min number is 1"),
    silence_max: NumberType()
        .isRequired("Is required")
        .min(1, "Min number is 1")
        .addRule((value, data) => (
            !(data.silence_min && (+data.silence_min > +value))
        ), "Max number must be larger then min number"),
});

const formModelRingbacktone = Schema.Model({
    ringbacktone_min: NumberType()
        .isRequired("Is required")
        .min(1, "Min number is 1"),
    ringbacktone_max: NumberType()
        .isRequired("Is required")
        .min(1, "Min number is 1")
        .addRule((value, data) => (
            !(data.ringbacktone_min && (+data.ringbacktone_min > +value))
        ), "Max number must be larger then min number"),
});

const formModelWait = Schema.Model({
    wait_min: NumberType()
        .isRequired("Is required")
        .min(0, "Min number is 0"),
    wait_max: NumberType()
        .isRequired("Is required")
        .min(0, "Min number is 0")
        .addRule((value, data) => (
            !(data.wait_min && (+data.wait_min > +value))
        ), "Max number must be larger then min number"),
});

const models = {
    "RejectCall": formModelRejectCall,
    "WaitBeforeConnect": formModelWaitBeforeConnect,
    "Playback": formModelPlayback,
    "PlaybackLoop": formModelPlaybackRepeat,
    "Radio": formModelRadio,
    "Ringback": formModelRingbacktone,
    "SendDTMF": formModelSendDtmf,
    "Silence": formModelSilence,
    "Wait": formModelWait,
}

const formModel = Schema.Model.combine(
    formModelRejectCall, formModelWaitBeforeConnect, formModelPlayback, 
    formModelPlaybackRepeat, formModelRadio, formModelSendDtmf, 
    formModelSilence, formModelWait, formModelRingbacktone
);


export default (
    {
        loading,
        activeIvr,
        ivrTypes,
        ivrSoundsList,
        getIvrList,
        setActiveTab,
    }
) => {

    const nameFormRef = useRef(null);
    const paramsFormRef = useRef(null);

    const [name, setName] = useState(null);
    const [params, setParams] = useState({});
    const [actions, setActions] = useState(null);
    const [actionId, setActionId] = useState(null);
    const [editState, setEditState] = useState(false);
    const [showNameEdit, setShowNameEdit] = useState(false);

    const [modalRemoveShow, setModalRemoveShow] = useState(false);
    const [modalRemoveLoading, setModalRemoveLoading] = useState(false);
    const [submitIvrDataLoading, setSubmitIvrDataLoading] = useState(false);


    const activeActionType = useMemo(() => {
        if (!actions || !actions.length) {
            return null;
        }
        const activeActionData = actions.find(item => item.id === actionId);
        return activeActionData ? activeActionData.action_id : null;
    }, [actionId, actions]);


    const serviceList = useMemo(() => {
        if (!activeIvr || !activeIvr.service_list || !activeIvr.service_list.length) {
            return null;
        }
        const list = [...activeIvr.service_list];
        if (list.length > 10) {
            list.splice(10);
        }
        return list.map(item => item);
    }, [activeIvr]);


    const accountList = useMemo(() => {
        if (!activeIvr || !activeIvr.account_list || !activeIvr.account_list.length) {
            return null;
        }
        const list = [...activeIvr.account_list];
        if (list.length > 10) {
            list.splice(10);
        }
        return list;
    }, [activeIvr]);

    // disable editing settings on new IVR select
    useEffect(() => {
        setEditState(false);
    }, [activeIvr]);


    // set active IVR actions
    // each of IVR actions must have an ID
    useEffect(() => {

        // if empty
        if (!activeIvr) {
            setName(null);
            setShowNameEdit(false);
            setActionId(null);
            setParams({});
            setActions(null);
            return;
        }

        // if new
        if (activeIvr.isNew) {
            const actionName = "WaitBeforeConnect";
            const actionId = "WaitBeforeConnect";
            const defaultParams = getDefaultParams(actionId, ivrTypes);

            const id = generateId();
            setName("");
            setShowNameEdit(true);
            setActionId(id);
            setParams({[id]: defaultParams});
            setActions([
                {
                    id: id,
                    params: {},
                    action_name: actionName,
                    action_id: actionId,
                    sound_id: null,
                }
            ]);
            return;
        }

        // if regular IVR
        const actions = activeIvr.action_list
            .sort((a, b) => {
                return a.position - b.position
            })
            .map((item) => {
                const defaultParams = getDefaultParams(item.action_type.name, ivrTypes);
                return ({
                    id: generateId(),
                    params: {
                        ...defaultParams,
                        ...item.params
                    },
                    action_name: item.action_type.name,
                    action_id: item.action_type.action_id,
                    sound_id: item.sound && item.sound.id,
                })
            });

        const activeIvrActionId = actions.length
            ? actions[0].id
            : null;

        const params = actions.reduce((res, item) => {
            
            if ('RejectCall' === item.action_id && item.params.hangup_codes) {
                const paramsUpdated = item.params.hangup_codes.reduce((summ, item) => {
                    return {
                        ...summ,
                        [`sip_${item.sip}`]: item.percent,
                    }
                }, {});
                return {
                    ...res,
                    [item.id]: {
                        ...item.params,
                        ...paramsUpdated,
                        hangup_codes: undefined,
                        sound: item.sound_id
                    }
                };
            }

            return {
                ...res,
                [item.id]: {
                    ...item.params,
                    sound: item.sound_id
                }
            };
        }, {});

        setName(activeIvr.name);
        setShowNameEdit(false);
        setActionId(activeIvrActionId);
        setActions(actions);
        setParams(params);

    }, [activeIvr, ivrTypes]);


    useEffect(() => {
        paramsFormRef.current.cleanErrors();
    }, [actionId]);


    const handleSubmitIvr = () => {
        // check name
        // Закоментил ибо  в рефе нет ошибок, но работает он от обратного, говорит что есть ошибка, когда их нет
        // let nameError = false;
        // if (nameFormRef.current && !nameFormRef.current.check()) {
        //     nameError = true
        // }

        
        // Check empty actions
        const emptyActions = actions.some(item => !item.action_id);
        if (emptyActions) {
            const actionsUpdated = actions.map(item => {
                return !item.action_id ? {...item, hasError: true} : item;
            });
            setActions(actionsUpdated);
        }

        // Validate params
        paramsFormRef.current.check();
        const paramsValidity = actions.filter(item => {
            if (!models[item.action_id]) {
                return false;
            }
            if (!item.params) {
                return true;
            }
            const checkedParams = models[item.action_id].check(params[item.id]);
            for (const prop of Object.keys(checkedParams)) {
                if (checkedParams[prop].hasError) {
                    return true;
                }
            }
            return false;
        });
        if (paramsValidity.length) {
            const unvalidatedActionsId = paramsValidity.map(item => item.id);
            const actionsUpdated = actions.map(item => {
                return unvalidatedActionsId.includes(item.id)
                    ? {...item, hasError: true}
                    : item;
            });
            setActions(actionsUpdated);
        }

        for (const action of actions) {
            if ('RejectCall' === action.action_id) {
                const summ = Object.keys(params[action.id]).reduce((summ, item) => {
                    if (item.indexOf('sip_') !== 0) {
                        return summ;
                    }
                    
                    return summ + +params[action.id][item];
                }, 0)

                if (summ !== 100) {
                    Alert.error("The sum of the percentage of Reject Call codes must be 100");
                    setActions(actions.map(item => {
                        return item.id === action.id
                            ? {...item, hasError: true}
                            : item;
                    }));
                    return;
                }
            }
        }
        
        if (name === null || !name) {
            Alert.error("Wrong name");
            return;
        }
        if (emptyActions) {
            Alert.error("Please fill in all action types");
            return;
        }
        if (paramsValidity.length) {
            Alert.error("Please check actions params");
            return;
        }

        const method = activeIvr.isNew ? IVR_CREATE_API : IVR_MODIFY_API;
        const actionList = actions
            .map(({id, ...item}, index) => {
                const {sound, isChanged, ...formParams} = params[id] || {};
                const preparedParams = {};

                if ('RejectCall' === item.action_id) {
                    const {percent} = formParams;
                    delete formParams.percent;
                    delete formParams.hangup_codes;

                    return ({
                        action_name: item.action_name,
                        action_id: item.action_id,
                        position: index + 2,
                        params: {
                            percent: +percent,
                            hangup_codes: Object.keys(formParams).reduce((summ, item) => {
                                return [
                                    ...summ,
                                    {
                                        sip: +item.split('_')[1],
                                        percent: +formParams[item],
                                    },
                                ]
                            }, [])
                        },
                        sound_id: sound || null,
                    })
                }

                for (const key of Object.keys(formParams)) {
                    // to Applesinkin: create new component for numbers and change this exp
                    preparedParams[key] = (!isNaN(formParams[key]) && key !== 'digit') ? +formParams[key] : formParams[key];
                }

                return ({
                    action_name: item.action_name,
                    action_id: item.action_id,
                    position: index + 2,
                    params: {...preparedParams},
                    sound_id: sound || null,
                })
            });

        const data = {
            name: name,
            action_list: [...actionList]
        };

        if (activeIvr && activeIvr.id) {
            data.target = {ivr_id: activeIvr.id}
        }

        setSubmitIvrDataLoading(true);
        api(method, data)
            .then((r) => {
                if (!r) {
                    return;
                }
                Alert.success(`IVR "${name}" succesfully updated`);
                getIvrList();
            })
            .finally(() => {
                setSubmitIvrDataLoading(false);
            });
    };


    const handleChangeType = (currentIndex, type) => {
        const actionsMapped = actions.map((item, index) => (
            currentIndex === index
                ? {
                    ...item,
                    action_id: type,
                    hasError: false
                }
                : item
        ));
        setActions(actionsMapped);
        const defaultParams = getDefaultParams(type, ivrTypes);
        setActionParams(actionId, {...defaultParams});
    };


    const setActionParams = (id = null, value = {}) => {
        if (!id)
            return;

        const paramsUpdated = {...params, [id]: {...value}};
        setParams(paramsUpdated);
    };


    const handleIncreasePosition = (index, e) => {
        if (index >= actions.length - 1) {
            return;
        }

        e.stopPropagation();

        const listCloned = [...actions];
        const currentElement = listCloned[index];
        listCloned[index] = listCloned[index + 1];
        listCloned[index + 1] = currentElement;

        setActions(listCloned);
        setActionId(currentElement.id);
    };


    const handleDecreasePosition = (index, e) => {
        if (index === 0) {
            return;
        }

        e.stopPropagation();

        const listCloned = [...actions];
        const currentElement = listCloned[index];
        listCloned[index] = listCloned[index - 1];
        listCloned[index - 1] = currentElement;

        setActions(listCloned);
        setActionId(currentElement.id);
    };


    const handleAddAction = (index) => {
        const listCloned = [...actions];
        const id = generateId();
        listCloned.splice(index + 1, 0, {
            id: id,
            params: {},
            action_name: null,
            action_id: null,
            sound_id: null,
        });
        setActionParams(id, {});
        setActions(listCloned);
    };


    const handleRemoveAction = (index, e) => {
        e.stopPropagation();

        const listCloned = [...actions];
        listCloned.splice(index, 1);

        const actionId = listCloned.length ? listCloned[0].id : null;

        setActions(listCloned);
        setActionId(actionId);
    };


    const handleRemoveIvr = () => {
        setModalRemoveLoading(true);
        api(IVR_REMOVE_API, {target: {ivr_id: activeIvr.id}})
            .then((r) => {
                if (!r) {
                    setModalRemoveLoading(false);
                    return;
                }
                getIvrList();
                setModalRemoveShow(false);
                setModalRemoveLoading(false);
            });
    };


    return (
        <>
            <FlexGrid hSpace={'-20px'} align={'stretch'} noWrap>
                <FlexGridItem grow={1} hSpace={'20px'}>

                    <StyledPanel shaded>
                        {loading &&
                            <Loader backdrop size="sm" />
                        }

                        <BlockIvrHeader
                            formModel={nameFormModel}
                            forwardedRef={(ref) => nameFormRef.current = ref}
                            {...{
                                name,
                                showNameEdit,
                                setName,
                                activeIvr,
                                actions,
                                editState,
                                setEditState,
                                handleSubmitIvr,
                                submitIvrDataLoading,
                                setModalRemoveShow,
                                serviceList,
                            }}
                        />

                        {activeIvr &&
                            <BlockIvrActions
                                {...{
                                    actions,
                                    actionId,
                                    setActionId,
                                    ivrTypes,
                                    params,
                                    editState,
                                    showNameEdit,
                                    handleChangeType,
                                    handleAddAction,
                                    handleRemoveAction,
                                    handleDecreasePosition,
                                    handleIncreasePosition,
                                }}
                            />
                        }

                        <BlockIvrFooter
                            {...{
                                activeIvr,
                                serviceList,
                                accountList,
                                setActiveTab,
                            }}
                        />

                    </StyledPanel>

                </FlexGridItem>

                <FlexGridItem width="360px" hSpace={'20px'}>
                    <ActionParams
                        forwardedRef={(ref) => paramsFormRef.current = ref}
                        formModel={formModel}
                        formValue={params[actionId]}
                        {...{
                            ivrTypes,
                            editState,
                            showNameEdit,
                            loading,
                            ivrSoundsList,
                            actions,
                            actionId,
                            activeActionType
                        }}
                        setFormValue={(value) => {
                            const paramsUpdated = {
                                ...params,
                                [actionId]: {...value, isChanged: true}
                            };
                            setParams(paramsUpdated);
                        }}
                    />
                </FlexGridItem>
            </FlexGrid>

            <CustomModal
                show={modalRemoveShow}
                onConfirm={handleRemoveIvr}
                title="Remove current IVR"
                loading={modalRemoveLoading}
                showFooter={true}
                confirmButtonText="Yes"
                onClose={() => setModalRemoveShow(false)}
            >
                Are you sure?
            </CustomModal>
        </>
    )
};


const getDefaultParams = (actionName, ivrTypes = []) => {
    if (!actionName)
        return {};

    const type = ivrTypes.find(item => actionName === item.name);

    if (!type) {
        return {}
    }

    if ('RejectCall' === actionName) {
        const codesParams = type.params_default.hangup_codes.reduce((summ, item) => ({
            ...summ,
            [`sip_${item.sip}`]: item.percent,
        }), {});
        return {
            ...codesParams,
            percent: type.params_default.percent,
        };
    }

    return Object.keys(type.params_validation)
        .reduce((sum, item) => {
            const minimumValue = type.params_validation[item].minimum;
            return {
                ...sum,
                [item]: (minimumValue || minimumValue === 0) ? minimumValue : ""};
        }, {});
};


const StyledPanel = styled(Panel)`
    .rs-panel-body {
        min-height: 506px;
        display: flex;
        flex-direction: column;
    }
`;