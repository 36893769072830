import React from "react";
import {
    SelectPicker,
} from "rsuite";
import Modal from "components/Modal";
import {api} from "api/loginRoutes";
import { getServiceSmsPrefix, servicePick } from "utils";
import {SMS_SUPPLIER_TRUNK_REMOVE_METHOD, TRUNK_REMOVE_API, SMS_TRUNK_REMOVE_API} from "const/apiMethods";


export default ({service, onClose, update, account_id, trunk_id, data, trunkList, isSupplierTrunks}) => {
    const [disabled, setDisabled ] = React.useState(false)
    const [trunk1_id, setTrunk1 ] = React.useState(null)
    const onSubmit = async () => {
        setDisabled(true)
        const method = !isSupplierTrunks ? servicePick(service, TRUNK_REMOVE_API, SMS_TRUNK_REMOVE_API) : SMS_SUPPLIER_TRUNK_REMOVE_METHOD;

        const result = await api(method, {
            target:{
                account_id,
                ...(isSupplierTrunks ? {"sms.supplier_trunk_id": data.id} : {
                    [getServiceSmsPrefix(service, "trunk_id")]: trunk_id,
                    [getServiceSmsPrefix(service, "trunk1_id")]: trunk1_id || undefined 
                }),
               
            }
        })
        if(result && service ? result.trunk : result["sms.trunk"] || result["sms.supplier_trunk"] ) {
            onClose();
            update()
        }
        setDisabled(false)
    }

    return (
        <Modal 
            show 
            onClose={onClose}
            footer
            successText = "Yes"
            onSuccess = {onSubmit}
            disabled = {disabled}
            title="Delete trunk">    
                Trunk {data.name} for dialer {data.account_name} will be deleted. {!isSupplierTrunks ? "Do you want to move numbers to another trunk?" : ""}
                {!isSupplierTrunks ? <>
                    <br />
                    Move to trunk
                    <SelectPicker
                        labelKey="name"
                        valueKey="id"
                        data={[{id:null,name: "Delete trunk with all numbers"},...trunkList]}
                        cleanable={false}
                        searchable = {false}
                        defaultValue = {null}
                        onChange = {(id) => setTrunk1(id)}
                        style = {{marginLeft: 10}}
                        // classPrefix="minWidth"
                    />
                </> : <></>}
        </Modal>
    )
};