import React from "react";
import TableEditCellsNew from "components/Table/TableEditCells/TableEditCellsNew";
import {Checkbox, Input, Schema, SelectPicker} from "rsuite";
import CustomInputPicker from "../../components/Form/CustomInputPicker";
import {CustomCheckbox} from "../../components/Form/CustomCheckbox";
// import styled from "styled-components";


const {NumberType, StringType, BooleanType} = Schema.Types;


const numberModel = Schema.Model({
    number: StringType()
        .pattern(/^[1-9][\d]*$/, "The number must not begin with 0 and must contain only digits")
        .isRequired("This is required field")
        .minLength(6, "The minimum of this field is 6 characters")
        .maxLength(15, "The maximum  of this field is 15 characters")
});

const typeFamilyFormModel = Schema.Model({
    type_family_name: StringType()
        .isRequired("This is required field"),
});

const psGroupFormModel = Schema.Model({
    spg_key: NumberType()
        .isRequired("This is required field")
});

const verifiedFormModel = Schema.Model({
    verified: BooleanType()
})

const formModel = Schema.Model.combine(
    numberModel, 
    typeFamilyFormModel, 
    psGroupFormModel,
    verifiedFormModel
);


export default ({
    data,
    spGroupList,
    numberTypeList,
    numberTypeFamilyList,

    getAuthNumbers,
    getAuthNumberType,

    ...props
}) => {

    const createFormDefaultValue = {
        number: "",
        type_family_name: null,
        spg_key: null
    };

    const columns = [
        {
            name: "Number",
            dataKey: "number",
            minWidth: 200,
            flexGrow: 1,

            accepter: Input,
            placeholder: "Number",
            model: numberModel,
        },

        {
            name: "Service name",
            dataKey: "type_family_name",
            minWidth: 300,
            flexGrow: 1,

            accepter: CustomInputPicker,
            data: numberTypeFamilyList,
            valueKey: "family_name",
            labelKey: "family_name",
            placeholder: "Service name",
            model: typeFamilyFormModel,
            defaultValue: null
        },
        {
            name: "Number type",
            dataKey: "spg_key",
            value: (({spg_key}) => spGroupList && spGroupList.find(item => item.spg_key === spg_key)?.name),
            flexGrow: 1,

            accepter: SelectPicker,
            data: spGroupList 
                ? spGroupList.filter(item => item.otp_type || item.otp_type === 0)
                : [],
            valueKey: "spg_key",
            labelKey: "name",
            placeholder: "Number type",
            model: psGroupFormModel,
        },

        // {
        //     name: "Verified",
        //     dataKey: "verified",
        //     value: ({verified}) => <Checkbox 
        //         disabled 
        //         checked={verified}
        //         style={{marginTop: "-7px"}}
        //     />,
        //     width: 70,
        //     accepter: CustomCheckbox,
        //     replaceWithDefault: true,
        //     defaultValue: false,
        //     model: verifiedFormModel
        // }
    ];


    return (
        <TableEditCellsNew
            rowIdKey={"an_key"}
            virtualized
            data={data}
            height={500}
            columns={columns}
            headerHeight={40}
            autoHeight={false}
            shouldUpdateScroll={false}
            formModel={formModel}
            addButtonWidth={104}
            createFormDefaultValue={createFormDefaultValue}
            {...props}
        />
    )
};

// const StyledTable = styled(TableEditCellsNew)`
//     && {
//         .rs-table-row {
//           border-bottom: 1px solid #f2f2f5 !important;
//         }
        
//         .rs-table-body-row-wrapper .rs-table-cell-content {
//           padding: 8px 10px !important;
//         }
        
//         .tableFilters__previewText {
//             display: block;
//             line-height: 28px;
//             //margin-top: 7px;
//             word-break: normal;
//         }
        
//         .tableFilters_buttons {
//             height: 20px;
//             float: right;
//             margin-top: 3px;
//         }
//         .tableFilters_buttons .rs-btn {
//             margin-right: 5px;
//         }

//         .rs-btn-lightblue {
//             background-color: var(--color-main);
//             color: #f2f2f5;
//         }
//     }
//     .rs-table-cell-header .rs-table-cell-content {
//         display: flex;
//         align-items: center;
//     }
// `
