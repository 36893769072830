import React, {useRef, useState, useCallback} from "react";
import {Form, FormControl, SelectPicker, Input, Button, Schema} from "rsuite";
import {FlexGrid, FlexGridItem} from "components/base/FlexGrid";
import { checkPermissionsFor } from "store/storeHelpers";
import {ACCOUNT_NOTE_CREATE_API} from "const/apiMethods";

const {NumberType, StringType} = Schema.Types;

const MAX_LENGTH = 1024;

const formModel = Schema.Model({
    type: NumberType()
        .isRequired("Required"),
    text: StringType()
        .isRequired("Required")
        .maxLength(MAX_LENGTH, `Max length is ${MAX_LENGTH} characters`),
});

export default ({
    commentTypes = [],
    accountId,
    getAccountNoteList,
    createAccountNote,
}) => {
    const formRef = useRef(null);
    const [formLoading, setFormLoading] = useState(false);


    const onSubmit = useCallback( () => {
        if ( !formRef.current.check() )
            return;

        setFormLoading(true);
        const data = formRef.current.getFormValue();
        createAccountNote(accountId, data)
            .then( () => getAccountNoteList(accountId) )
            .finally( () => setFormLoading(false) );
    }, [accountId]);


    const renderValue = useCallback( (value, item) => {
        return <div>
            <span style={{width: 18, display: "inline-block"}}>
                <i style={{color: item.color}} className={`rs-icon rs-icon-${item.icon}`}/>
            </span>
            {" "}{item.label}
        </div>;
    }, []);


    return (
        <Form
            ref={formRef}
            model={formModel}
            onSubmit={onSubmit}
        >
            <FlexGrid>

                <FlexGridItem>
                    <FormControl
                        errorPlacement={"topStart"}
                        accepter={SelectPicker}
                        data={commentTypes}
                        name="type"
                        labelKey="label"
                        valueKey="id"
                        placeholder="Select importance of message"
                        cleanable={false}
                        searchable={false}
                        style={{width: 250}}
                        renderMenuItem={renderValue}
                        renderValue={renderValue}
                    />
                </FlexGridItem>

                <FlexGridItem>
                    <FormControl
                        errorPlacement={"topStart"}
                        accepter={Input}
                        componentClass="textarea"
                        placeholder="Message"
                        name="text"
                        rows={6}
                        style={{maxWidth: "100%", width: 500}}
                    />
                </FlexGridItem>

                <FlexGridItem>
                    <Button
                        appearance="primary"
                        loading={formLoading}
                        disabled={!checkPermissionsFor(ACCOUNT_NOTE_CREATE_API)}
                        type="submit"
                    >
                        Submit note
                    </Button>
                </FlexGridItem>

            </FlexGrid>
        </Form>
    )
};