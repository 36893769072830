import React, {useState, useEffect} from "react";
import {Table as _Table} from "rsuite";
import PanelLayout from "components/base/PanelLayout";
import PageHeader from "components/base/PageHeader";
import PriceFilters from "./PriceFilters";
import Ranges from "./Ranges";
import AddNewSubdestination from "./AddNewSubdestination";
import styled from "styled-components";
import ShowPrice from "./ShowPrice";
import ExportPrice from "./ExportPrice";
import EditPrice from "./EditPrice";
import Table from "components/Table";
import {connect} from "react-redux";
import {getPrices} from "actions/prices";
import {getRanges, clearRanges} from "actions/ranges";
import {compareTwoArrays, servicePick} from "utils";
import {SMS_DEFAULT_PRICE_KEY, DEFAULT_PRICE_KEY, USD_DEFAULT_CURRENCY_KEY, EUR_DEFAULT_CURRENCY_KEY, SP_KEY__GLOBAL_ACCESS} from "const";
import {checkPermissionsFor} from "store/storeHelpers";
import {
    SERVICE_PLAN_PRICE_UPLOAD_CSV_API,
    SP_CHANGE_RATE_METHOD,
    SP_PRICE_NEW_SUBDESTINATION_METHOD,
    SMS_PRICE_RANGE_GENERATE_BY_SDE_API
} from "const/apiMethods"
import {getTemplate} from "actions/numbers";
import {BaseButton, ButtonRowEdit, ButtonWrapper} from "components/base/BaseButton";
import {Spacer} from "components/base/Spacer";
import ModalUploadPrice from "./ModalUploadPrice";
import ModalUploadResult from "./ModalUploadResult";
import {getDropdownAccounts} from "actions/dropdown_accounts";
import {getAccountManagersDropdownList} from "actions/account_managers";
import ModalEditPriceResult from "./ModalEditPriceResult";
import { usePrevious } from "hooks";

const Prices = ({
    page,
    count,
    ranges,
    account,
    service,
    loading,
    roleList,
    rangesPage,
    price_list,
    rangesCount,
    currency_list,
    rangesLoading,
    worldzone_list,
    servicePlanList,
    defaultPriceKey,
    dropdownAccounts,
    paymentTermsList,
    destination_list,
    accountManagerList,
    subdestination_list,
    service_plan_price_list,

    getPrices,
    getRanges,
    clearRanges,
    getTemplate,
    getDropdownAccounts,
    getAccountManagersDropdownList
}) => {

    const [filters, setFilters] = useState({
        cur_key: servicePick(service, USD_DEFAULT_CURRENCY_KEY, EUR_DEFAULT_CURRENCY_KEY),
        p_key: servicePick(service, DEFAULT_PRICE_KEY, SMS_DEFAULT_PRICE_KEY),
    });
    const [rangesFilters, setRangesFilters] = useState({});

    const [sdeKey, setSdeKey] = useState(null);
    const [onUpdateRanges, setOnUpdateRanges] = useState(null);
    const [edit, setEdit] = useState(false);
    const [ptActiveTermRates, setPtActiveTermRates] = useState([]);
    const [otpType, setOtpType] = useState(null);
    const [columns, setColumns] = useState([
        {
            label: "Subdestination",
            dataKey: "subdestination_name",
            value: ({subdestination_name, mcc, mnc}) => {
                return mcc && !service ? `${subdestination_name} (${mcc}${mnc || ""})` : subdestination_name
            },
            flexGrow: 1
        }
    ]);
    const [modalUploadPriceShow, setModalUploadPriceShow] = useState(false);
    const [modalUploadPriceResultShow, setModalUploadPriceResultShow] = useState(false);
    const [modalUploadPriceResultData, setModalUploadPriceResultData] = useState({});
    const [currencyKey, setCurrencyKey] = useState(servicePick(service, USD_DEFAULT_CURRENCY_KEY, EUR_DEFAULT_CURRENCY_KEY));

    const [modalEditPriceResultShow, setModalEditPriceResultShow] = useState(false);
    const [modalEditPriceResultData, setModalEditPriceResultData] = useState({});

    const prevPaymentTermsList = usePrevious(paymentTermsList);
    const prevServicePlanPriceList = usePrevious(service_plan_price_list);
    const prevSdeKey = usePrevious(sdeKey);
    const prevPage = usePrevious(page);

    useEffect(() => {
        if (!sdeKey) {
            selectFirstPrice();
        }
    }, [sdeKey]);

    useEffect(() => {
        setSdeKey(null);
        clearRanges([]);
    }, [service]);

    useEffect(() => {
        getPrices(filters, 1);
    }, [filters]);

    useEffect(() => {
        if (sdeKey && !filters.sde_key || sdeKey && sdeKey === filters.sde_key) {
            getRangesList({filter: rangesFilters, page: 1}, sdeKey);
        }
    }, [sdeKey, filters]);

    useEffect(() => {
        if (paymentTermsList.length) {
            addTermColumns();
        }
    }, [paymentTermsList]);

    useEffect(() => {
        const sdeKeyList = service_plan_price_list.map((servicePlan) => servicePlan.sde_key);
        const prevSdeKeyList = prevServicePlanPriceList.map((servicePlan) => servicePlan.sde_key);

        // if (page === prevPage) {
        if (service_plan_price_list && service_plan_price_list.length 
            && !compareTwoArrays(sdeKeyList, prevSdeKeyList) && !sdeKeyList.includes(sdeKey)
        ) {
            const firstSdeKey = service_plan_price_list[0].sde_key;

            setSdeKey(firstSdeKey);
            setOnUpdateRanges({filters});
        }
        // }
    }, [sdeKey, service_plan_price_list, prevServicePlanPriceList]);

    useEffect(() => {
        if (!compareTwoArrays(paymentTermsList, prevPaymentTermsList)) {
            addTermColumns();
        }
    }, [paymentTermsList, prevPaymentTermsList]);

    const addTermColumns = () => {
        const newColumns = [];

        for (const term of paymentTermsList) {
            const newDataKey = `payment_terms_rate:${term.name}`;
            if (!columns.some(column => column.dataKey === newDataKey)) {
                newColumns.push({
                    label: term.name.replace("_", "-"),
                    dataKey: newDataKey,
                    value: (rowData) => {
                        const currentTerm = rowData[`payment_terms_rate:${term.name}`];
                        const termFuture = rowData[`payment_terms_future:${term.name}`]?.length && rowData[`payment_terms_future:${term.name}`][0];
                        const startDate = termFuture.start_date
                            ? " from " + new Date(termFuture.start_date).toLocaleDateString()
                            : "";
                        if (termFuture) {
                            return `${currentTerm ? "" + currentTerm + " " : ""}(${termFuture.rate}${startDate})`;
                        }
    
                        return rowData[`payment_terms_rate:${term.name}`];
                    },
                    align: "left"
                });
            }
        }

        if (newColumns.length) {
            setColumns([
                ...columns,
                ...newColumns
            ]);
        }
    };

    const onClickPrice = (sde_key) => {
        setSdeKey(sde_key)
        setOnUpdateRanges({filters: rangesFilters, page: 1});
    };

    const onChangeFilters = (filters) => {
        const currentServicePlan = servicePlanList.find(item => item.p_key === filters.p_key);

        if (currentServicePlan) {
            setOtpType(currentServicePlan.otp_type);
        }
        setCurrencyKey(filters.cur_key);
   

        setFilters(filters)
        // getPrices(filters, 1);
        selectFirstPrice();
    };

    const getRangesList = ({filter, page: _page}, sdeKey) => {
        const _filters = {...filter};

        // delete _filters["cur_key"];
        getRanges({..._filters, sde_key: sdeKey}, service, _page);
    };

    const onEditPrice = (ptRates) => {
        setEdit(true)
        setPtActiveTermRates(ptRates);
    };

    const selectFirstPrice = () => {
        if (service_plan_price_list.length && service_plan_price_list[0] && service_plan_price_list[0].sde_key) {
            const first_sde_key = service_plan_price_list[0].sde_key;

            setSdeKey(first_sde_key);
            setOnUpdateRanges({filters: rangesFilters});
        }
    };

    const handleChangeRangesFilters = (filters) => {
        setRangesFilters(filters);
    };

    const handleModalUploadResultShow = (data) => {
        setModalUploadPriceResultShow(true); 
        setModalUploadPriceResultData(data);
    };
    
    const handleModalUploadResultHide = () => {
        setModalUploadPriceResultShow(false);
        setModalUploadPriceResultData({});
    };

    // window.sde_list = service_plan_price_list;


    const currentServicePlan = servicePlanList.find(item => item.p_key === filters.p_key);
    const current_sp_key = currentServicePlan ? currentServicePlan.sp_key : filters.p_key;
    const current_p_key = currentServicePlan ? currentServicePlan.p_key : filters.p_key;

    const uploadPermission = checkPermissionsFor(SERVICE_PLAN_PRICE_UPLOAD_CSV_API);
    const supervisorSmsRole = roleList.includes("Supervisor (sms)");
    const isSupervisorSms = roleList && roleList.length ? supervisorSmsRole : null;
    const showAddRangesButton =  !isSupervisorSms || isSupervisorSms && checkPermissionsFor(SMS_PRICE_RANGE_GENERATE_BY_SDE_API);
    
    const filteredPriceList = !service ? price_list.filter(price => price.p_key !== SP_KEY__GLOBAL_ACCESS) : price_list;


    return (
        <PanelLayout>

            {edit &&
            <EditPrice
                sde_key={sdeKey}
                cur_key={filters.cur_key}
                p_key={filters.p_key}
                sp_key={current_sp_key}
                ratesValue={ptActiveTermRates}
                servicePlanList={servicePlanList}
                paymentTermsList={paymentTermsList}
                update={(data) => {
                    getPrices(filters, page);
                    setModalEditPriceResultShow(true);
                    setModalEditPriceResultData(data);
                }}
                updateRanges={() => {
                    getRangesList({filter: rangesFilters, page: 1}, sdeKey);
                }}
                setShow={() => setEdit(false)}
                currency_list={currency_list}

                dialers={dropdownAccounts}
                managers={accountManagerList}
                getDialers={getDropdownAccounts}
                getManagers={getAccountManagersDropdownList}
            />
            }

            <Spacer size={18}/>

            <PageHeader service={service} title={"Prices"}>
                <ButtonWrapper>
                    <StyledButtonUpload
                        disabled={!uploadPermission}
                        onClick={() => setModalUploadPriceShow(true)}
                    >
                        Upload Price
                    </StyledButtonUpload>
                    <ShowPrice
                        service={service}
                        currency_list={currency_list}
                        paymentTermsList={paymentTermsList}
                        sp_key={current_sp_key}
                        getTemplate={getTemplate}
                        account={account}
                        otpType={otpType}
                    />
                    <ExportPrice
                        service={service}
                        p_key={current_p_key}
                        sp_key={current_sp_key}
                        account={account}
                        getTemplate={getTemplate}
                        otpType={otpType}
                        cur_key={currencyKey}
                    />
                </ButtonWrapper>
            </PageHeader>

            <Spacer size={13}/>

            <Top>
                <div>
                    <PriceFilters
                        onChange={onChangeFilters}
                        {...{
                            currency_list,
                            worldzone_list,
                            subdestination_list,
                            destination_list,
                            price_list: filteredPriceList,
                            defaultPriceKey,
                            service
                        }}/>
                </div>
                <div>
                    {checkPermissionsFor(SP_PRICE_NEW_SUBDESTINATION_METHOD) &&
                        <AddNewSubdestination
                            sp_key={current_sp_key}
                            p_key={filters.p_key}
                            cur_key={filters.cur_key}
                            update={() => getPrices(filters, page)}
                            currency_list={currency_list}
                            price_list={filteredPriceList}
                            paymentTermsList={paymentTermsList}
                        />
                    }
                </div>

            </Top>
            <Table
                data={service_plan_price_list}
                height="50%"
                width="100%"
                loading={loading}
                columns={columns}
                count={count}
                per_page={10}
                page={page}
                getItems={(page) => {
                    getPrices(filters, page)
                }}
                active_id={sdeKey}
                row_key="sde_key"
                onRowClick={onClickPrice}
                hidePerPage
                ispagination
                ActionCell={checkPermissionsFor(SP_CHANGE_RATE_METHOD) ?
                    ActionCell(onEditPrice, paymentTermsList)
                    : null
                }
            />
            <Ranges
                onChangeFilters={handleChangeRangesFilters}
                update={(page=1, filter=null) => {
                    getRangesList({filter, page}, sdeKey)
                }}
                items={!service_plan_price_list.length  && sdeKey && ranges && ranges.length ? [] : ranges}
                loading={rangesLoading}
                count={rangesCount}
                page={rangesPage}
                sde_key={sdeKey}
                service={service}
                filters={filters}
                subdestination_list={subdestination_list}
                rangesFilters={rangesFilters}
                showAddRangesButton={showAddRangesButton}
            />

            <ModalUploadPrice
                {...{
                    service,
                    price_list: filteredPriceList,
                    currency_list,
                    paymentTermsList
                }}
                show={modalUploadPriceShow}
                onClose={() => setModalUploadPriceShow(false)}
                onSuccess={(r) => {
                    handleModalUploadResultShow(r);
                    getPrices(filters, page);
                }}
            />

            <ModalUploadResult
                show={modalUploadPriceResultShow}
                data={modalUploadPriceResultData}
                onClose={handleModalUploadResultHide}
            />

            <ModalEditPriceResult
                show={modalEditPriceResultShow}
                data={modalEditPriceResultData}
                onClose={() => setModalEditPriceResultShow(false)}
            />

        </PanelLayout>
    )
};


const {Column, HeaderCell, Cell} = _Table;


const ActionCell = (onEdit, termsList) => (
    <Column align="center">
        <HeaderCell>Options</HeaderCell>
        <Cell>
            {rowData => (
                <div style={{position: "relative", "top": "-2px"}}>
                    <ButtonRowEdit
                        mr={0}
                        onClick={() => {
                            const ptRates = termsList.map( rate => ({
                                ...rate,
                                value: rowData[`payment_terms_rate:${rate.name}`]
                            }));
                            onEdit(ptRates);
                        }}
                    />
                </div>
            )}
        </Cell>
    </Column>
);

const mapState = ({references, prices, numbers, accounts, ranges, auth, roles, dropdown_accounts, account_managers}) => ({
    service_plan_price_list: prices.service_plan_price_list,
    loading: prices.loading,

    currency_list: references.currency_list,
    worldzone_list: references.worldzone_list,
    subdestination_list: references.subdestination_list,
    destination_list: references.destination_list,
    defaultSPKey: references.defaultSPKey,
    defaultPriceKey: references.defaultPriceKey,
    servicePlanList: references.service_plan_list,
    paymentTermsList: references.payment_terms_list,
    price_list: references.price_list,
    template: numbers.template,
    account: accounts.accountView,
    roleList: roles.role_list,

    dropdownAccounts: dropdown_accounts.items,
    accountManagerList: account_managers.dropdown_list,

    service: auth.service,

    page: prices.page,
    count: prices.count,
    ranges: ranges.ranges,
    rangesLoading: ranges.loading,
    rangesPage: ranges.page,
    rangesCount: ranges.count,

});

export default connect(mapState, {
    getPrices,
    getRanges,
    clearRanges,
    getTemplate,
    getDropdownAccounts,
    getAccountManagersDropdownList,
})(Prices);


const Top = styled.div`
    margin-bottom: -20px;
    > div:first-child {
        display: inline-block;
        width: 80%
    }
    >div:last-child{
        display: inline-block;
        width: 20%;
        text-align: right
    }
`;

const StyledButtonUpload = styled(BaseButton).attrs(() => ({
    buttonStyle: "primary"
}))`
    padding-bottom: 5px !important;
    padding-top: 5px !important;
`;