import React, {useEffect, useState, useMemo} from "react";
import PageIvrSpecialCliFilter from "./PageIvrSpecialCliFilter";
import PageIvrSpecialCliTable from "./PageIvrSpecialCliTable";
import {Spacer} from "components/base/Spacer";


export default (
    {
        data,
        typeFamilyList,

        loading,
        ivrList,
        // authNumberType,

        setNewIvrCLI,

        getIvrCliList,
        getIvrList,
        getTypeFamily
    }
) => {
    const savedFilter = localStorage.getItem("active-ivr-name");
    const [filter, setFilter] = useState({
        str: savedFilter || "",
    });

    const list = useMemo(() => {
        return data.list.filter(value => !!value.ivr).map((value) => {
            return {
                ...value,
                antf_key: value.antf_key,
                ivr_id: value.ivr.id,
                ivr_name: value.ivr.name
            };
        });
    }, [data.list]);

    const update = () => {
        getIvrList();
        const currentFilter = !savedFilter ? filter : null;
        getTypeFamily(currentFilter);
        getTypeFamily(null, true);
    };

    useEffect(() => {
        if (!filter.str) {
            update();
        } else {
            getTypeFamily(null, true);
        }
    }, []);

    const dropdownList = useMemo(() => {
        return typeFamilyList.map((value) => {
            return {
                id: value.antf_key,
                name: value.family_name
            };
        });
    }, [typeFamilyList]);
    
    const noIvrDropdownList = useMemo(() => {
        return typeFamilyList.filter(value => !value.ivr).map((value) => {
            return {
                id: value.antf_key,
                name: value.family_name
            };
        });
    }, [typeFamilyList]);

    useEffect(() => {
        getTypeFamily(filter);    
        localStorage.removeItem("active-ivr-name");
    }, [filter]);

    const handleCreate = async (formValue, rowData) => {
        const currentTypeFamily = dropdownList.find(({id}) => id === formValue?.antf_key);
        const data = {
            target: {
                ivr_id: formValue.ivr_id
            },
            type_family_name: currentTypeFamily?.name
        };
        const result = await setNewIvrCLI(data);

        return result;
    };

    const handleSubmit = async (formValue, rowData) => {
        const currentTypeFamily = dropdownList.find(({id}) => id === formValue?.antf_key);
        const data = {
            target: {
                ivr_id: formValue.ivr_id
            },
            type_family_name: currentTypeFamily.name
        };
        const result = await setNewIvrCLI(data);

        return result;
    };

    const handleRemove = async (key) => {
        const currentTypeFamily = dropdownList.find(({id}) => id === key);
        const data = {
            target: {
                ivr_id: null
            },
            type_family_name: currentTypeFamily.name
        };
        const result = await setNewIvrCLI(data);
        return result;
    };

    return (
        <>
            <Spacer size={30}/>
            
            <PageIvrSpecialCliFilter
                style={{flexShrink: 0}}
                {...{
                    filter,
                    setFilter
                }}
            />

            <Spacer size={30}/>

            <PageIvrSpecialCliTable
                data={list}
                dropdownList={dropdownList}
                noIvrDropdownList={noIvrDropdownList}
                filter={filter}
                loading={loading}
                getIvrCliList={getIvrCliList}
                ivrList={ivrList}
                handleCreate={handleCreate}
                handleSubmit={handleSubmit}
                handleRemove={handleRemove}

                update={update}
            />
        </>
    )
};