import {IVR as PREFIX} from "const";
import {api} from "api/loginRoutes";
import {createAction} from  "./defaults";
import {IVR_LIST_API, IVR_ACTION_TYPE_LIST_API} from "const/apiMethods";

const setIvrList = createAction("SET_ITEMS_" + PREFIX);
const setIvrTypes = createAction("SET_TYPES_" + PREFIX);
const setLoading = createAction("SET_LOADING_" + PREFIX);

export const getIvrList = (filter = {}) => (dispatch) => {
    dispatch(setLoading(true));
    api(IVR_LIST_API, {filter})
        .then(({ivr_list}) => {
            if (ivr_list === undefined) {
                return;
            }
            dispatch(setIvrList({
                list: ivr_list
            }));
        })
        .finally(() => dispatch(setLoading(false)));
};


export const getIvrTypes = () => (dispatch) => {
    dispatch(setLoading(true));
    api(IVR_ACTION_TYPE_LIST_API)
        .then(({ivr_action_type_list}) => {
            if (ivr_action_type_list === undefined) {
                return;
            }
            dispatch(setIvrTypes({
                types: ivr_action_type_list
            }));
        })
        .finally(() => dispatch(setLoading(false)));
};

