import {Table} from "rsuite";
import styled, {css} from "styled-components";
import {APP_TYPE_CLIENT} from "../../const";

export const {Column, HeaderCell, Cell} = Table;

export default styled(Table)`
    && {
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.16);
        border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
        
        .rs-table-row {
            border-bottom: 1px solid #939191;
            
            &.rs-table-row-header {
            
                &,
                & .rs-table-cell {
                    background: #939191 !important;
                    color: #fff !important;
                }
            }
            
            &.row-active {
                color: var(--color-main);
                background: var(--color-row-active);

                .rs-table-cell {
                    background: var(--color-row-active);
                }        
                
                &:hover {
                    background: var(--color-row-active);
                }
            }
            
            &.row-closed {
                color: #6a686a;
                
                .rs-table-cell {
                    background: #d4d4d4;
                }            
            }
            
            &.row-disabled {
            
                .rs-input,
                .rs-input-number {
                    background: transparent;
                }
                
                .rs-table-cell {
                    background: #d6d6db;
                }
                
                &:hover .rs-table-cell {
                    background: #d6d6db !important;
                }
            }
        }
    }
    
    .rs-table-cell-header.text-center .rs-table-cell-content {
        text-align: center !important;
    }
    
    .rs-table-hover .rs-table-row-header:hover .rs-table-cell-group,
    .rs-table-hover .rs-table-row-header:hover .rs-table-cell {
        background: #939191 !important;
        color: #fff !important;
    }
    
    i.rs-table-cell-header-icon-sort,
    i.rs-table-cell-header-icon-sort-desc::after,
    i.rs-table-cell-header-icon-sort-asc::after {
        color: var(--color-brand1)
    }
    
    .rs-table-row-header .rs-table-cell-content {
        line-height: 30px;
    }
    
    .rs-table-cell-content {
        padding: 5px 10px !important;
    }
    

    ${props => props.columnSelector ? css`
        .rs-table-cell-header .rs-table-cell.rs-table-cell-first .rs-table-cell-content {
            padding-left: 30px !important;
        }
    ` : css`
        .rs-table-cell-header-with-icon .rs-table-cell-content {
            padding-left: 30px !important;
        }
    `}


    .table-row-account---link {
        cursor: pointer;
    }
    
    .table-row-account--disabled {
        cursor: default;
    }
    
    .table-row-account__closed .rs-table-cell-first {
        color: #fb2d2d;
    }
    
    .table-row-account__closed::after {
        content: '';
        position: absolute;
        opacity: 0.2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #4c4c59;
        pointer-events: none;
    }

    && .table-row-prefix--disabled {
        cursor: default;

        .rs-table-cell {
            background-color: #d6d6db !important;
        }
    }

    ${props => props.theme.appType === APP_TYPE_CLIENT && css`
        && {
            margin-top: 0;
            margin-bottom: 0;
            
            .rs-table-row-header .rs-table-cell-content {
                line-height: 26px;
            }
            
            .table-two-staged__cell {
                // line-height: 1.42857143;
                
                .table-two-staged__cell-second {
                    font-size: 13px;
                    color: var(--color-brand2);
                    opacity: 1;
                }
                
                .table-two-staged__cell-single {
                    padding-top: 7px;
                }
            }
        }
    `}
    
    .columnCheckbox {
        position: relative;
        top: -5px;
    }

    &&& .rs-table-cell-header .rs-table-cell:hover ~ .rs-table-column-resize-spanner {
        background-color: var(--color-green-light);
    }
    &&& .rs-table-column-resize-spanner::before {
        border-color: transparent var(--color-green-light) transparent transparent;
    }

    &&& .rs-table-column-resize-spanner::after {
        border-color: transparent transparent transparent var(--color-green-light);
    }

    && .rs-table-column-resize-spanner {
        border-left: 2px dotted #595959;

    }
    
`;
