import React, {useRef, useState} from "react";
import { Alert, Form, Schema, FlexboxGrid, FormControl } from "rsuite";
import ModalEdit from "../Modal/ModalEdit";
import m from "definedMessages";
import { withTranslation } from "hoc/WithTranslation";

const {StringType} = Schema.Types;

const MAX_CHARACTERS = 40;

const ModalEditProfileConctactsLetter = ({show, defaultValue, setLoading, onClose, onSuccess, formatMessage}) => {
    const formRef = useRef(null);

    const formModel = Schema.Model({
        person: StringType().isRequired(formatMessage(m.required)).maxLength(MAX_CHARACTERS, formatMessage(m.maxLength,  {count: MAX_CHARACTERS})),
        //email: StringType().isRequired(formatMessage(m.required)).pattern(/^([a-zA-Z0-9_+.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/, "Incorrect email addresses"),
        email: StringType().isRequired(formatMessage(m.required)).addRule((value) => {
            const emailList = value.replace(/\s\s+/g, " ").replace(/(,\s)|,/g, "|-&^#").split("|-&^#");
            const correctEmails = emailList.map(email => {
                const emailModel = new RegExp(/^([a-zA-Z0-9_+.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/);
    
                return emailModel.test(email);
            });
    
            return emailList.length === correctEmails.filter(email => !!email).length;
        }, formatMessage(m.incorrectEmailAddresses)),
    });
    

    const [formLetterData, setFormLetterData] = useState({
        id: defaultValue.id,
        person: defaultValue.person,
        email: defaultValue.email
    });

    const [disabledButton, setDisabledButton] = useState(false);

    const onSubmit = (form) => {
        if (!form.current.check() && !formLetterData.id) {
            return false;
        }

        const data = {
            person: formLetterData.person,
            email: formLetterData.email
        };

        onSuccess(formLetterData.id, data)
            .then(() => {
                Alert.success(formatMessage(m.requestSent));

                setLoading(false);
            });

        return true;
    };

    return <ModalEdit
        show={show}
        onClose={() => onClose()}
        onSuccess={() => {
            onSubmit(formRef);
        }}
        successButtonProps={{
            disabled: disabledButton
        }}
        title={formatMessage(m.changeNameAndEmail)}
        successButton={formatMessage(m.sendRequest)}
    >
        <Form
            model={formModel}
            ref={formRef}
            onChange={(data) => {
                const checkedData = formModel.check(data);

                const checker = Object.keys(checkedData).map(i => checkedData[i].hasError).every(value => value === false);

                setDisabledButton(!checker);

                setFormLetterData({
                    ...defaultValue,
                    ...data
                });
            }}
            formDefaultValue={defaultValue}
        >
            <FlexboxGrid align="middle" style={{width: "100%", marginBottom: "20px"}}>
                {
                    <FlexboxGrid.Item style={{width: "100%", maxWidth: "100px"}}>
                        {formatMessage(m.contactName)}
                    </FlexboxGrid.Item>
                }
                <FlexboxGrid.Item
                    style={{
                        width: "100%",
                        maxWidth: "300px",
                        minWidth: "140px"
                    }}>
                    <FormControl
                        name="person"
                        placeholder={formatMessage(m.contactName)}
                        style={{
                            maxWidth: "300px",
                            minWidth: "140px"
                        }}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid align="middle" style={{width: "100%", marginBottom: "20px"}}>
                {
                    <FlexboxGrid.Item style={{width: "100%", maxWidth: "100px"}}>
                        {formatMessage(m.contactEmail)}
                    </FlexboxGrid.Item>
                }
                <FlexboxGrid.Item
                    style={{
                        width: "100%",
                        maxWidth: "400px",
                        minWidth: "140px"
                    }}>
                    <FormControl
                        name="email"
                        placeholder={formatMessage(m.contactEmail)}
                        style={{
                            maxWidth: "400px",
                            minWidth: "140px"
                        }}
                        componentClass="textarea"

                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Form>
    </ModalEdit>
};

export default withTranslation(ModalEditProfileConctactsLetter);