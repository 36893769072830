import {
    IVR_CLI as PREFIX,
} from "const";

const type_family_default_data = { filtered: false, list: [] };

const initialState = {
    list: [],
    loading: true,
    type_family_data: type_family_default_data,
    type_family_dropdown_list: []
};

const handlers = {
    ["SET_ITEMS_" + PREFIX]: (state, {payload: {list}}) => ({...state, list: list}),
    ["SET_LOADING_" + PREFIX]: (state, {payload}) => ({...state, loading: payload}),
    ["SET_TYPE_FAMILY_DATA_" + PREFIX]:  (state, {payload: {type_family_data}}) => {
        return {...state, type_family_data: type_family_data}
    },
    ["SET_TYPE_FAMILY_DROPDOWN_LIST_" + PREFIX]:  (state, {payload: {type_family_dropdown_list}}) => {
        return {...state, type_family_dropdown_list: type_family_dropdown_list}
    },
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}