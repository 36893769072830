import React, {useState, useContext} from "react";
import {Checkbox, Form, FormControl, Input, Tooltip, Whisper} from "rsuite";
import IvrListener from "../../../../components/IvrListener/IvrListener";
import {ButtonClose, ButtonPrimary} from "../../../../components/base/BaseButton";
import styled from "styled-components";
import {PermissionContext} from "../../../../App";
import {IVR_CREATE_API, IVR_REMOVE_API} from "../../../../const/apiMethods";

const messagesRemove = {
    is_default: "This IVR is default",
    used_in_cli: "This IVR is used in some CLI",
};

export default (
    {
        name,
        showNameEdit,
        setName,
        activeIvr,
        actions,
        editState,
        setEditState,
        handleSubmitIvr,
        submitIvrDataLoading,
        setModalRemoveShow,
        serviceList,

        forwardedRef,
        formModel
    }
) => {

    const permissions = useContext(PermissionContext);


    const onChangeIvr = () => {
        // if (showNameEdit || editState) {
        if (showNameEdit) {
            handleSubmitIvr();
            setEditState(false);
        } else {
            if (editState) {
                setEditState(false);
                handleSubmitIvr();
            } else {
                setEditState(true);
            }
        }



    }


    const ivrDeleteButtonDisabled = activeIvr && (
        activeIvr.isNew || 
        activeIvr.default || 
        serviceList || 
        activeIvr.account_list && activeIvr.account_list.length
    );

    return (
        <StyledHeader>
            <StyledHeaderItem>
                <BlockHeader>
                    {name === null
                        ? "Choose IVR"
                        : (showNameEdit || editState)
                            ? <Form
                                ref={(ref) => forwardedRef(ref)}
                                model={formModel}
                            >
                                <FormControl
                                    accepter={Input}
                                    name="name"
                                    value={name}
                                    onChange={(value) => {
                                        setName(value);
                                    }}
                                    placeholder="Enter IVR name"
                                />
                            </Form>
                            : name
                    }
                </BlockHeader>
            </StyledHeaderItem>

            {actions &&
                <StyledHeaderItem>
                    <IvrListener
                        disabled={!activeIvr || activeIvr.isNew || (!actions || !actions.length)}
                        ivrId={activeIvr ? activeIvr.id : null}
                    />


                    {permissions.includes(IVR_CREATE_API) &&
                        <ButtonPrimary
                            disabled={!actions || !actions.length}
                            onClick={onChangeIvr} //}
                            loading={submitIvrDataLoading}
                        >
                            {!showNameEdit && !editState ? 'Edit' : 'Save'}
                        </ButtonPrimary>
                    }

                    {permissions.includes(IVR_REMOVE_API) &&
                        <>
                            {ivrDeleteButtonDisabled
                                ? <Whisper
                                    placement="left"
                                    speaker={
                                        <Tooltip>
                                            {activeIvr.default
                                                ? messagesRemove.is_default
                                                : serviceList
                                                    ? messagesRemove.used_in_cli
                                                    : "You can't remove this IVR"}
                                        </Tooltip>
                                    }
                                >
                                    <ButtonClose
                                        disabled={true}
                                        onClick={() => setModalRemoveShow(true)}
                                    >
                                        Delete
                                    </ButtonClose>
                                </Whisper>
                                : <ButtonClose
                                    disabled={false}
                                    onClick={() => setModalRemoveShow(true)}
                                >
                                    Delete
                                </ButtonClose>
                            }
                        </>
                    }
                </StyledHeaderItem>
            }
        </StyledHeader>
    )
};

const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledHeaderItem = styled.div`
    .rs-btn {
        margin-left: 20px;
    }
`;

const BlockHeader = styled.h5`
    font-family: inherit;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-brand1);
`;