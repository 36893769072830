/* eslint-disable default-case */
import React, {useRef, useMemo, useState, useEffect} from "react";
import {Alert, Radio, RadioGroup, Whisper, Tooltip, Table} from "rsuite";

import {api} from "api/loginRoutes";
import {FooterActions} from "../styled";
import {DEFAULT_EQUAL_SIGN, DEFAULT_PER_PAGE_SMALL, DEFAULT_PER_PAGE_MAX} from "const";
import {Header} from "../styled";
import {
    canIMoveNumbersToAnyTrunk, downloadTrunkNumbersCsv, downloadNumbersTxtCommon,
    convertDataInColumn, getRangeByNumber, getServiceSmsPrefix, formatDateApi,
    copyArrayWithoutReference,
    appendIf
} from "utils";
import {
    ButtonRowDelete, ButtonRowUpload, ButtonRowDownload, ButtonRowEdit
} from "components/base/BaseButton";

import TableServerSort from "components/Table/TableServerSort";

import MoveNumbersModal from "../MoveNumbersModal";
import RevokeNumbersModal from "../RevokeNumbersModal";
import ChangeBillingTypeModal from "../ChangeBillingTypeModal";

import Filters from "./NumbersFilters";
import NumbersActions from "./NumbersActions";
import ChangeRateModal from "./AllocateNumbersModal/ChangeRateModal";
import ChangeSupplierRateModal from "./AllocateNumbersModal/ChangeSupplierRateModal";
import AllocatedNumbersModal from "./AllocateNumbersModal/TrunksAllocation";
import AddNewPrefixesModal from "./AddNewPrefixesModal/AddNewPrefixesButton";
import ModifyGroupModal from "./ModifyGroupModal";
import NumbersDownloadDropdown from "./NumbersDownloadDropdown";
import * as S from "./styled";
import SetIvrModal from "../SetIvrModal";
import SetActiveModal from "../SetActiveModal";
import SetLimitModal from "../SetLimitModal";
import DeletePrefixesModal from "../DeletePrefixesModal";
import GanNumbersActionList from "./GanNumbersActionList/GanNumbersActionList";
import UploadGanNumbersModal from "../UploadGanNumbersModal/UploadGanNumbersModal";
import RemoveGroupModal from "./RemoveGroupModal";
import styled from "styled-components";
import { usePrevious, useWindowWidth } from "hooks";
import { checkPermissionsFor } from "store/storeHelpers";
import {
    SMS_SUPPLIER_PREFIX_CREATE_METHOD,
    SMS_SUPPLIER_PREFIX_REMOVE_LIST_METHOD,
    SMS_SUPPLIER_PREFIX_MODIFY_LIST_METHOD,
    SMS_SUPPLIER_PREFIX_SET_ACTIVE_METHOD,
    SMS_ALLOCATION_TEMPLATE_API,
    SMS_TRUNK_NUMBER_CHANGE_RATE_API,
    SMS_TRUNK_NUMBER_REVOKE_API,
    SMS_TRUNK_NUMBER_MOVE_TO_TRUNK_API
} from "const/apiMethods";

// "sms.supplier_prefix:modify_list"
// "sms.supplier_prefix:set_active_list"

const {Column, HeaderCell, Cell} = Table;

const NUMBER_KEY = "trn_key";
const RANGE_KEY = "pr_key";
const SUBDEST_KEY = "sde_key";

const AllocatedNumbers = ({
    sp_key,
    trunks,
    sp_auth,
    acc_key,
    account,
    service,
    trunk_id,
    ivr_list,
    readonly,
    spOtpType,
    permissions,
    isGanTrunks,
    raterc_list,
    trunksInited,
    account_name,
    currencyList,
    trunksLoading,
    currentSupplier,
    isTransitTrunks,
    isSupervisorSms,
    allocatedNumbers,
    paymentTermsList,
    showReasonHandler,
    customRangesTrunk,
    suppliersGroupList,
    currentRangeNumber,
    suppliersPrefixList,
    allocatedNumbersPage,
    groupDownloadNumbers,
    allocatedNumbersCount,
    suppliersPrefixLoading,
    allocatedNumbersPerPage,
    allocatedNumbersLoading,
    suppliersPrefixListPage,
    suppliersPrefixListСount,
    showAllocatedNumbersModal,
    suppliersPrefixListPerPage,
    allocatedNumbersTotalCount,
    suppliersPrefixDropdownList,
    
    getTrunks,
    getNumbers,
    createTrafficLimit,
    modifyTrafficLimit,
    removeTrafficLimit,
    getSupplierPrefixes,
    trunkDownloadNumbers,
    getTrunkNumberCsvById,
    closeAllocatedNumbersModal,
    getSupplierPrefixesDropdown
    
}) => {
    const filterRef = useRef(null);
    const tableRef = useRef(null);
    const _selectedList = new Set();

    const defaultColumns = useMemo(() => [
        {
            label: "Number",
            dataKey: "number",
            sortable: true,
            value: (({number, numbers_count}) => {
                return getRangeByNumber(number, numbers_count)
            }),
            displayWhen: ["trunk_number"],
            flexGrow: 1,
            minWidth: 196,
            minWidthMobile: 225,
        },
        {
            label: "Range", 
            dataKey: "name", 
            sortable: true, 
            displayWhen: ["trunk_number", "price_range"],
            flexGrow: 2,
            // width: 400,
            minWidth: 196,
            minWidthMobile: 300,
        },
        {
            label: "Subdestination", 
            dataKey: "subdestination_name", 
            sortable: true, 
            displayWhen: ["subdestination"],
            flexGrow: 2,
            minWidth: 196,
            minWidthMobile: 300,
        },
        // Only for Numbers
        {
            label: "Rate", 
            dataKey: "rate", 
            sortable: true, 
            displayWhen: ["trunk_number"], 
            // flexGrow: 1,
            width: 100, 
            align: "right"
        },
        // For Ranges & Subdestinations
        {
            label: "Rate", 
            dataKey: "rate", 
            sortable: true, 
            displayWhen: ["price_range", "subdestination"], 
            flexGrow: 1,
            // width: 100, 
            align: "right"
        },
        ...(service ? [{
            label: "Billing increment",
            dataKey: "rrc_name",
            displayWhen: ["trunk_number"],
            width: 150,
            align: "right"
        }] : []),
        ...(service ? [{
            label: "IVR",
            dataKey: "ivr_name",
            displayWhen: ["trunk_number"],
            value: ({ivr_name}) => (ivr_name),
            flexGrow: 1,
            minWidth: 140,
            minWidthMobile: 200,
        }] : []),
        {
            label: "User",
            dataKey: "modify_user_name",
            displayWhen: ["trunk_number"],
            flexGrow: 1,
            minWidth: 196,
            minWidthMobile: 300,
        },
        {
            label: "Created Date",
            dataKey: "start_date",
            displayWhen: ["trunk_number"],
            formatData: "datetime",
            sortable: true,
            align: "right",
            width: 190,
            minWidth: 160,
        },
        {
            label: "Allocated numbers", 
            dataKey: "trunk_numbers", 
            sortable: true, 
            displayWhen: ["price_range"], 
            align: "right",
            flexGrow: 1,
            minWidth: 196,
        },
        {
            label: "Allocated numbers and ranges", 
            dataKey: "allocated_numbers_and_ranges", 
            displayWhen: ["subdestination"], 
            align: "right",
            flexGrow: 1,
            minWidth: 196,
        },
    ], [service]);

    const defaultGanColumns = [
        {
            label: "Number",
            dataKey: "number",
            displayWhen: ["numbers"],
            sortable: true,
            minWidth: 160,
            minWidthMobile: 180,
        },
        {
            label: "Group name",
            dataKey: "gan_group_name",
            displayWhen: ["numbers"],
            sortable: true,
            flexGrow: 1,
            minWidth: 196,
            minWidthMobile: 225,
        },
        {
            label: "Group name",
            dataKey: "name",
            displayWhen: ["groups"],
            sortable: true,
            minWidth: 196,
            minWidthMobile: 225,
        },
        {
            label: "Subdestination (mcc mnc)",
            dataKey: "subdestination_name",
            displayWhen: ["numbers"],
            sortable: true,
            value: ({subdestination_name, mcc, mnc}) => `${subdestination_name} (${mcc}${mnc})`,
            flexGrow: 1,
            minWidth: 120,
            minWidthMobile: 140,
        },
        {
            label: "Test numbers",
            dataKey: "test_number_list",
            displayWhen: ["groups"],
            // sortable: true,
            value: ({test_number_list}) => {
                const data = test_number_list && test_number_list.join(", ");
                return (
                    <Whisper
                        enterable
                        placement="bottomStart"
                        speaker={
                            <Tooltip>{data}</Tooltip>
                        }
                    >
                        <span>{data}</span>
                    </Whisper>
                )
            },
            flexGrow: 1,
            minWidth: 196,
            minWidthMobile: 225,
        },
        {
            label: "Allocated numbers",
            dataKey: "allocated_numbers",
            displayWhen: ["groups"],
            // sortable: true,
            value: ({allocated_numbers, all_numbers}) =>  `${allocated_numbers}/${all_numbers}`,
            flexGrow: 1,
            minWidth: 120,
            minWidthMobile: 140,
        },
        {
            label: "Payout",
            dataKey: "supplier_rate",
            displayWhen: ["numbers", "groups"],
            sortable: true,
            value: ({supplier_rate=0, supplier_cur_name}) => {
                return `${supplier_rate} ${supplier_cur_name}`;
            },
            minWidth: 110,
            minWidthMobile: 120,
        },
        {
            label: "Payout for dialers",
            dataKey: "rate_list",
            displayWhen: ["groups"],
            // sortable: true,
            value: ({rate_list=[]}) => {
                const payoutGroups = rate_list.reduce((summ, item) => {
                    return {
                        ...summ,
                        [item.cur_name]: summ[item.cur_name]
                            ? summ[item.cur_name] + `, ${item.payment_terms_name}: ${item.rate} ${item.cur_name}`
                            : `${item.payment_terms_name}: ${item.rate} ${item.cur_name}`
                    }
                }, {});

                return (
                    <div style={{display: "flex", justifyContent: "space-between", marginTop: -4}}>
                        <div style={{fontSize: 12, lineHeight: "1.2em", flexShrink: 0, flexGrow: 1}}>
                            {Object.keys(payoutGroups).map((item) => <span>{payoutGroups[item]}<br/></span>, "")}
                        </div>
                    </div>
                )
            },
            flexGrow: 1,
            minWidth: 120,
            minWidthMobile: 140,
        },
        {
            label: "Allocated to",
            dataKey: "payout",
            displayWhen: ["numbers"],
            // sortable: true,
            value: ({test_number, allocated_account_name, allocated_rate, allocated_cur_name, blocked}) =>  {
                const isAllocated = !!allocated_account_name;
                let allocatedText = "not allocated";

                if (blocked)  {
                    allocatedText = "blocked"; 
                } else {
                    if (test_number) {
                        allocatedText = allocated_account_name;
                    } else if (isAllocated) {
                        allocatedText = `${allocated_account_name} ${allocated_rate || 0} ${allocated_cur_name}`;
                    } 
                }

                return allocatedText;
            },
            width: 220,
            minWidth: 120,
            minWidthMobile: 140,
        },
        {
            label: "Modified by",
            dataKey: "modify_user",
            flexGrow: 1,
            minWidth: 250
        },
        {
            label: "Modify date",
            dataKey: "modify_date",
            formatData: "datetime",
            // align: "right",
            minWidth: 180
        },
        {
            label: "Limits",
            dataKey: "trafic_limit_list",
            displayWhen: ["numbers", "groups"],
            // sortable: true,
            value: ({trafic_limit_list}) => {
                const sortedList = copyArrayWithoutReference(trafic_limit_list).sort((a, b) => {
                    if (a.senderid < b.senderid) {
                        return -1;
                    } else if (a.senderid >= b.senderid) {
                        return 1;
                    }
                    return 0;
                });

                const firstElement = sortedList.length ? sortedList[0] : {};
                const remainingElementsList = sortedList.splice(1, sortedList.length);

                const stringElementsList = remainingElementsList.map(value => {
                    
                    const limitDay = value.day ? `${value.day} SMS per day` : "";
                    const limitHour = value.hour ? `${value.hour} SMS per hour` : "";
                    const bothLimitsSeparator = value.day && value.hour ? "/" : "";

                    const hourDayValue = `${limitDay}${bothLimitsSeparator}${limitHour}`;
                    
                    if (hourDayValue) {
                        return `${value?.senderid || "<empty>"} — ${hourDayValue}`;
                    }

                    return "No limits";
                });

                const newLinedString = stringElementsList.length ? stringElementsList.map(element => <p>{element}</p>) : "No more limits";

                const hour = firstElement.hour;
                const day = firstElement.day;
                const limitDay = day ? `${day} SMS per day` : "";
                const limitHour = hour ? `${hour} SMS per hour` : "";
                const bothLimitsSeparator = day && hour ? "/" : "";

                const finalValue = limitDay && limitHour ? <>
                    <>{firstElement.senderid || "Any senderId"}</> — <SecondStagedCell>
                        <span className="first">{limitDay}</span>
                        <span className="second">{limitHour}</span>
                    </SecondStagedCell>
                </> : "No limits";
                
                const hourDayValue = `${limitDay}${bothLimitsSeparator}${limitHour}`;
                const finalValueTooltip = hourDayValue ? `${firstElement.senderid || "Any senderId"} — ${hourDayValue}${stringElementsList.length ? "..." : ""}` : "No limits";
                

                return (
                    <Whisper
                        enterable
                        placement="auto"
                        speaker={
                            // Back later
                            <Tooltip>{hourDayValue && !stringElementsList.length ? finalValueTooltip : newLinedString}</Tooltip>
                        }
                    >
                        <span style={{fontSize: 13, display: "flex"}}>{finalValue}</span>
                    </Whisper>
                )
            },
            flexGrow: 1,
            // width: 220,
            minWidth: 230,
            minWidthMobile: 380
        }
    ];

    const defaultTransitColumns = useMemo(() => [
        {
            label: "Prefix",
            dataKey: "prefix",
            sortable: true,
            // flexGrow: 1,
            minWidth: 160,
            minWidthMobile: 180,
        },
        {
            label: "Name (mcc_mnc)",
            dataKey: "name",
            value: ({name, mcc, mnc}) => `${name || ""} (${mcc}${mnc})`, 
            sortable: true,
            flexGrow: 2,
            minWidth: 196,
            minWidthMobile: 225,
        },
        {
            label: "Payout",
            dataKey: "rate",
            sortable: true,
            value: ({rate, currency_name}) => `${rate} ${currency_name}`,
            // flexGrow: 1,
            minWidth: 110,
            minWidthMobile: 120,
        },
        {
            label: "Date",
            dataKey: "start_date",
            sortable: true,
            value: ({start_date, end_date}) =>  `${formatDateApi(start_date || "")}${end_date ? ` - ${formatDateApi(end_date || "")}`: ""}`,
            flexGrow: 1,
            minWidth: 120,
            minWidthMobile: 140,
        },
        {
            label: "Created at",
            dataKey: "created_at",
            sortable: true,
            // value: ({created_at}) =>  formatDateApi(created_at || ""),
            formatData: "datetime",
            // align: "right",
            // flexGrow: 1,
            minWidth: 180,
            minWidthMobile: 200,
        },
        {
            label: "Modified by",
            dataKey: "modify_user",
            flexGrow: 1,
            minWidth: 250
        },
        {
            label: "Modify date",
            dataKey: "modify_date",
            formatData: "datetime",
            // align: "right",
            minWidth: 180
        }
    ], [isTransitTrunks]);
    
    const defaultSelected = {all: false, list: []};
    const defaultFilter = {
        show_allocated_numbers: true,
        show_unallocated_numbers: true,
        show_test_numbers: true,
        show_block_allocation_numbers: true
    };

    const [footerActionsDisabled, setFooterActionsDisabled] = useState(false);
    const [footerActionsLoading, setFooterActionsLoading] = useState(false);

    const [showChangeSupplierRateModal, setShowChangeSupplierRateModal] = useState(false);

    const [selectedGanNumbers, setSelectedGanNumbers] = useState([]);
    const [selected, setSelected] = useState(defaultSelected);
    const [selectedGroupData, setSelectedGroupData] = useState({});
    const [filterState, setFilterState] = useState(null);
    const [currentDisplay, setCurrentDisplay] = useState(!isGanTrunks ? "trunk_number" : "numbers");
    const [currentSubDestinationsList, setCurrentSubDestinationsList] = useState([]);
    
    const [showChangeRateModal, setShowChangeRateModal] = useState(false);
    const [showRevokeModal, setShowRevokeModal] = useState(false);
    const [showMoveModal, setShowMoveModal] = useState(false);
    const [showChangeBTModal, setShowChangeBTModal] = useState(false);
    const [showIvrModal, setShowIvrModal] = useState(false);
    // const [showAllocatedNumbersModal, setShowAllocatedNumbersModal] = useState(false);
    const [showSetLimitModal, setShowSetLimitModal] = useState(false);
    const [showSetActiveModal, setShowSetActiveModal] = useState(false);
    const [showDeletePrefixesModal, setShowDeletePrefixesModal] = useState(false);
    const [showModifyGroupModal, setShowModifyGroupModal] = useState(false);
    const [showUploadGanNumbersModal, setShowUploadGanNumbersModal] = useState(false);
    const [showRemoveGroupModal, setShowRemoveGroupModal] = useState(false);

    const [activeStatus, setActiveStatus] = useState(true);

    const [searchLoading, setSearchLoading] = useState(false);
    const [sort, setSort] = useState({});

    const [numberPageState, setNumberPageState] = useState(1);

    const [disabledActions, setDisabledActions] = useState([]);
    const [actualColumns,  setActualColumns] = useState([]);

    const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
    const [selectedItems, setSelectedItems] = useState(new Map());

    const resizedWidth = useWindowWidth();

    const [columns, setColumns] = useState(defaultColumns);
    const [ganColumns, setGanColumns] = useState(defaultGanColumns);
    const [transitColumns, setTransitColumns] = useState();

    const preTrunkId = usePrevious(trunk_id);

    const columnsActualize = (props) => {
        const actualizedColumnList = props?.actual ? [{
            label: "Revoked Date",
            dataKey: "end_date",
            // displayWhen: ["trunk_number"],
            formatData: "date",
            sortable: true,
            align: "right",
            minWidth: 196,
        }] : [];
        const actualizedDefaultColumns = [...defaultColumns];

        actualizedColumnList.forEach((actualizedColumn, idx) => {
            actualizedDefaultColumns.splice(7+idx, 0, actualizedColumn);
        });

        setColumns(actualizedDefaultColumns);
        setGanColumns(defaultGanColumns);
        setTransitColumns(defaultTransitColumns);
    };


    useEffect(() => {
        columnsActualize(
            {
                isTransitTrunks: isTransitTrunks,
                isGanTrunks: isGanTrunks,
                service: service,
                actual: filterState && filterState.hasOwnProperty("actual") ? !filterState?.actual : false
            }
        )
    }, [filterState, service, isGanTrunks, isTransitTrunks]);

    useEffect(() => {
        if (currentDisplay === "numbers" && filterState === null) {
            setFilterState(defaultFilter);
        }

        const filters = {
            start_date_inequation: 0,
            ...defaultFilter,
            ...(currentRangeNumber ? {str: currentRangeNumber} : {}),
            ...(filterState || filterRef.current.getValues())
        };

        if (trunks.length && trunk_id !== preTrunkId) {
            getNumbers(currentDisplay, filters, 1, allocatedNumbersPerPage);
        }

        tableRef.current.clear();
        _selectedList.clear();
        clearSelectedNumbers();
    }, [trunk_id, trunksInited]);

    useEffect(() => {
        const currentFilterState = filterState || {};

        if (!!currentRangeNumber) {
            const existNumberFilter = {
                ...currentFilterState,
                str: currentRangeNumber
            };

            onChangeFilters(existNumberFilter);

        }
    }, [currentRangeNumber]);

    const updateCurrentPrefixes = () => {
        const filters = {...(filterState || filterRef.current.getValues())};

        getNumbers(currentDisplay, filters, numberPageState, allocatedNumbersPerPage);
    };

    // const curAndNextParamsNotMatched = (names, curParams, nextParams) => {
    //     return names.map((name) => curParams[name] !== nextParams[name]).some(value => value === true);
    // };


    // const getFilters = () => ({...filterRef.current.getValues()});


    // const getSelectedList = () => ({
    //     selected, 
    //     countAll: allocatedNumbersCount
    // });


    const getNumberList = (page, per_page, sort) => {
        getNumbers(currentDisplay, filterState || filterRef.current.getValues(), page, per_page, sort);
    };


    const setFocusOnInput = (ref) => {
        if (
            ref.current && 
            Object.keys(ref.current).includes("_reactInternalFiber") &&
            Object.keys(ref.current._reactInternalFiber).includes("firstEffect") &&
            !!ref.current._reactInternalFiber.firstEffect &&
            ref.current._reactInternalFiber.firstEffect.stateNode &&
            ref.current._reactInternalFiber.firstEffect.stateNode.hasAttribute("focus")
        ) {
            ref.current._reactInternalFiber.firstEffect.stateNode.focus();
        }
    };

    // refactor after ATX-2853
    const onChangeFilters = (filters) => {
        if (!searchLoading) setSearchLoading(true);

        const filterStateObject = filterState || {};

        const existFilters = Object.keys(filterStateObject).reduce((result, value) => {
            if (!(value in defaultFilter)) {
                if (value in filters)
                    result[value] = filterStateObject[value];
            } else if (value in defaultFilter) {
                if (filterStateObject[value] !== defaultFilter[value])
                    result[value] = filterStateObject[value];
            }
          
            return result;
        }, defaultFilter);

        const filterObject = {...existFilters,...filters,  start_date_inequation: filters.start_date_inequation ? Number.parseInt(filters.start_date_inequation) : DEFAULT_EQUAL_SIGN};
        Object.keys(filterObject).forEach(key => filterObject[key] === undefined  || filterObject[key] === null ? delete filterObject[key] : {});

        const intervalTimer = () => {
            setTimeout(() => {
                setSearchLoading(false);
                setFilterState(filterObject);
                setFocusOnInput(filterRef);
            }, 1000);
        };

        intervalTimer();
        
        getNumbers(currentDisplay, filterObject, 1, allocatedNumbersPerPage, sort);
        // ref>current>_reactInternalFiber>firstEffect>stateNode
    };


    const onChangeGroup = (currentDisplay) => {
        tableRef.current.clear();
        _selectedList.clear();
        clearSelectedNumbers();
        setCurrentDisplay(currentDisplay);

        if (["numbers", "groups"].includes(currentDisplay)) {
            setSelected(defaultSelected);
        }

        getNumbers(currentDisplay, filterState || filterRef.current.getValues(), 1, allocatedNumbersPerPage, sort);
    };


    const getInitialValues = () => {
        let list = null;

        if (selected.all) {
            return {filter: filterState || filterRef.current.getValues(), filterAll: true};
        }

        switch (currentDisplay) {
            case "trunk_number":
                list = {trn_key_list: selected.list};
                break;
            case "price_range":
                list = {pr_key_list: selected.list};
                break;
            case "subdestination":
                list = {sde_key_list: selected.list};
                break;

        }

        return {...list};
    };


    // const getCurrentEntityName = () => {
    //     return {
    //         "trunk_number": "number",
    //         "price_range": "range",
    //         "subdestination": "subdestination"
    //     }[currentDisplay];
    // };


    const updateItems = () => {
        tableRef && tableRef.current && tableRef.current.clear();
        _selectedList.clear();
        clearSelectedNumbers();

        getTrunks();
        getNumbers(currentDisplay, filterState || filterRef.current.getValues(), allocatedNumbersPage, allocatedNumbersPerPage, sort);
    };


    const proxyFilters = (filters) => {
        return {
            ...filters,
            actual: !filters.actual
        };
    };


    const getRowKey = () => {
        let fixedKey;

        if (isTransitTrunks) {
            fixedKey = "key";
        } else if (isGanTrunks) {
            if (currentDisplay === "numbers")
                fixedKey = "key";
            else if (currentDisplay === "groups")
                fixedKey = "id";
        }

        if (!fixedKey) {
            switch (currentDisplay) {
                case "trunk_number":
                    return NUMBER_KEY;
                case "price_range":
                    return RANGE_KEY;
                case "subdestination":
                    return SUBDEST_KEY;
            }
        } else {
            return fixedKey;
        }
    };


    const updateSelectedList = (selected) => {
        const isSupplierTrunks = isTransitTrunks || isGanTrunks;
        const data = isSupplierTrunks ? suppliersPrefixList || [] : allocatedNumbers || [];

        const rowKey = getRowKey();
    
        for (let item of _selectedList) {
            if (!selected.list.find(key => key === item[rowKey]))
                _selectedList.delete(item);
        }

        selected.list.map(key => {
            const value = data.find(item => item[rowKey] === key);
            if (value)
                _selectedList.add(value);
        });

        if (selected.list.length === 0) {
            clearSelectedNumbers();
        }

        if (isSupplierTrunks) {
            checkTrafficAllTheSame(selected);
        }

        if (isGanTrunks) {
            const filteredNumbers = selectedGanNumbers.filter(item => selected.list.includes(item.inn_key));
            const currentPageNumbers = data.filter(item => selected.list.includes(item.inn_key));
            const numbersUpdated = currentPageNumbers.reduce((summ, item) => {
                if (summ.find(_item => _item.inn_key === item.inn_key)) {
                    return summ;
                }
                return [...summ, item];
            }, [...filteredNumbers]);

    
            setSelectedGanNumbers([...numbersUpdated]);
        }

        setSelected(selected);
    };

    const toggleDisabledAction = (toDisable, name) => {
        if (toDisable) {
            setDisabledActions([name, ...disabledActions]);
        } else {
            setDisabledActions(disabledActions.filter(action => action !== name));
        }
    };

    const checkTrafficAllTheSame = () => {
        const selectedValues = Array.from(_selectedList.values()).map(value => value?.trafic_limit_list || []);
        const firstTrafficLimitValue = selectedValues[0];
        const trafficsAllTheSame = firstTrafficLimitValue ? selectedValues.every(trafficList => {
            if (firstTrafficLimitValue.length === trafficList.length)
                return trafficList.every((value, idx) => {
                    if (firstTrafficLimitValue[idx]) 
                        return value.day === firstTrafficLimitValue[idx].day &&
                            value.hour === firstTrafficLimitValue[idx].hour
                })
        }) : false;

        toggleDisabledAction(!trafficsAllTheSame, "Set limit");
        // setTrafficsAllTheSame(trafficsAllTheSame);
    };


    const getDataForChangeRate = () => {
        for (let item of _selectedList) {
            return {
                sde_key: item.sde_key,
                sde_name: item.subdestination_name,
                account_name: account_name,
                sp_key: sp_key
            };
        }
    };

    const getDataForChangeSupplierRate = () => {
        const data = [];

        for (let item of _selectedList) {
            data.push(
                {
                    sde_key: item.sde_key,
                    de_key: item.de_key,
                    key: item.key,
                    rate: item.rate,
                    account_name: account_name
                }
            );
        }

        return data;
    };

    const handleDownloadNumbersCsv = async () => {
        const filter = filterState || filterRef.current.getValues();
        const plan_type = isGanTrunks ? "gan" : isTransitTrunks ? "transit" : null;

        setIsDownloadInProgress(true);
        await trunkDownloadNumbers(trunk_id, service, plan_type);
        setIsDownloadInProgress(false);
    };


    const handleDownloadOnlyNumbers = () => {
        const filter = filterState || filterRef.current.getValues();
        const plan_type = isGanTrunks ? "gan" : isTransitTrunks ? "transit" : null;
        const fileNames = {
            transit: "transit_prefixes.txt",
            gan: "gan_prefixes.txt",
            default: "allocated_numbers.txt"
        };

        setIsDownloadInProgress(true);

        const isSupplier = isTransitTrunks || isGanTrunks;

        // not finished
        downloadNumbersTxtCommon(
            trunk_id, 
            filter, 
            () => setIsDownloadInProgress(false),
            service, 
            (fileNames[plan_type] || fileNames["default"]),
            plan_type,
            isSupplier
        );
    };


    const keepSelectedItems = (data, checked) => {
        const updatedCollection = new Map(selectedItems);

        const rowKey = getRowKey();

        for (const item of data) {
            if (checked) {
                updatedCollection.set(item[rowKey], item);
            } else {
                updatedCollection.delete(item[rowKey]);
            }
        }

        const selectedValueList = Array.from(updatedCollection.values());
        const allTrafficLimitSameHash = selectedValueList.every(selectedValue => {
            return selectedValue.trafic_limit_list_hash === selectedValueList[0].trafic_limit_list_hash;
        });

        setSelectedItems(updatedCollection);
        // setDisabledSetLimit(!allTrafficLimitSameHash);
    };

    const getSelectedNumbersCount = () => {
        const {list, all} = selected;

        if (all)
            return allocatedNumbersTotalCount;

        const rowKey = getRowKey();

        let key = "id";
        if (!isTransitTrunks && !isGanTrunks) {
            switch (rowKey) {
                case NUMBER_KEY:
                    key = "numbers_count";
                    break;
                case RANGE_KEY:
                    key = "price_range_trunk_numbers";
                    break;
                case SUBDEST_KEY:
                    key = "subdestination_trunk_numbers";
                    break;
            }

            let count = 0;
            for (const item of selectedItems) {
                count += item[1][key];
            }
    
            return count;
        } else {
            if (isTransitTrunks) {
                key = "key";
            } else if (isGanTrunks) {
                key = "key";
            }
    
            return Array.from(selectedItems.keys()).length;
        }

    };


    const clearSelectedNumbers = () => {
        setSelectedItems(new Map());
    };

    const handleOpenSetLimitModal = () => {
        setFooterActionsDisabled(true);
        setFooterActionsLoading(true);
        setShowSetLimitModal(true);
    };

    const handleOpenSetActiveModal = (status) => {
        setFooterActionsDisabled(true);
        setFooterActionsLoading(true);
        setActiveStatus(status);
        setShowSetActiveModal(true);
    };

    const handleOpenDeletePrefixesModal = () => {
        setFooterActionsDisabled(true);
        setFooterActionsLoading(true);
        setShowDeletePrefixesModal(true);
    };

    const handleOpenChangeRateModal = () => {
        setFooterActionsDisabled(true);
        setFooterActionsLoading(true);

        getSubDestinations()
            .then(response => {
                if (!response || !response.subdestination_list)
                    return;

                const isUniqueDest = response.subdestination_list
                    .map(item => item.de_key)
                    .every( (val, index, arr) => val === arr[0] );

                if (!isUniqueDest) {
                    Alert.error(`You cannot change the rate for different destinations at one time`);
                    return;
                }

                setCurrentSubDestinationsList(response.subdestination_list);
                setShowChangeRateModal(true);
            })
            .finally(() => {
                setFooterActionsDisabled(false);
                setFooterActionsLoading(false);
            })
    };

    const handleOpenChangeSupplierRateModal = () => {
        const data = getDataForChangeSupplierRate();

        const isUniqueSubdest = data
            .map(value => value.de_key)
            .every((val, index, arr) => val === arr[0]);

        if (isUniqueSubdest) {
            setFooterActionsDisabled(true);
            setFooterActionsLoading(true);
            setShowChangeSupplierRateModal(true);
        } else {
            Alert.error(`You cannot change the rate for different destinations at one time`);
            return;
        }
    };


    const getSubDestinations = async () => {
        const initialData = getInitialValues();
        const targetTrunkByService = {[getServiceSmsPrefix(service, "trunk_id")]: trunk_id};

        const getSubdestMethod = getServiceSmsPrefix(service, "trunk_number__filter:get_subdestination_list");

        return await api(getSubdestMethod, {target: targetTrunkByService, ...initialData});
    };


    const getTableData = (data) => {
        const ganData = {
            "numbers": copyArrayWithoutReference(suppliersPrefixList || []),
            "groups": copyArrayWithoutReference(suppliersGroupList || [])
        };

        return isTransitTrunks ? copyArrayWithoutReference(suppliersPrefixList)
            : isGanTrunks ? ganData[currentDisplay] : data;
    };

    
    const ganNumberSuccess = () => {
        getTrunks(true);
        updateItems();
    };

    const onEditGroup = (rowData) => {
        setShowModifyGroupModal(true);
        setSelectedGroupData({...rowData});
    };


    const onDownloadNumbers = (id) => {
        groupDownloadNumbers(id);
    };

    const onRemoveGroup = (rowData) => {
        setShowRemoveGroupModal(true);
        setSelectedGroupData({...rowData});
    };

    const onUploadNumbers = (rowData) => {
        setShowUploadGanNumbersModal(true);
        setSelectedGroupData({...rowData});
    };


    const isMobile = resizedWidth < 1200;
    const loading = trunksLoading || allocatedNumbersLoading;
    const currentTrunk = useMemo(() => trunks.find((trunk) => trunk.id === trunk_id), [trunks, trunk_id]);

    const isselected = filterRef &&  filterRef.current && filterRef.current.formValue ? !filterRef.current.formValue.actual : true;

    const formattedData = allocatedNumbers && allocatedNumbers.length ? allocatedNumbers.map(item => ({
        ...item,
        start_date: item.start_date ? item.start_date.replace(" ", "T") : "",
        end_date: item.end_date ? item.end_date.replace(" ", "T") : "",
    })) : [];
    
    const data = convertDataInColumn(formattedData, "end_date", (value, column, key) => {
        return {
            ...column, 
            [key]: value 
                ? new Date( new Date( new Date(value).setSeconds(new Date(value).getSeconds() - 1) ) ) 
                : null
            }
    });

    const isSupplierTrunks = isTransitTrunks || isGanTrunks;
    const dataList = getTableData(data);
    const dataCount = isSupplierTrunks ? suppliersPrefixListСount : allocatedNumbersCount;
    const dataPage = isSupplierTrunks ? suppliersPrefixListPage : allocatedNumbersPage;
    const dataPerPage = isSupplierTrunks ? suppliersPrefixListPerPage : allocatedNumbersPerPage;
    const dataLoading = searchLoading || isSupplierTrunks ? suppliersPrefixLoading : loading;

    const tableColumns = actualColumns.length ? actualColumns : isTransitTrunks ? transitColumns : isGanTrunks ? ganColumns : columns;

    const selectedGan = Array.from(selectedItems.values());
    const hasGanBlocked = selectedGan.length && selectedGan.some(number => number.blocked);

    const hasSelected = (selected.list && selected.list.length) || selected.all;

    const responsiveTarget = {[getServiceSmsPrefix(service, "trunk_id")]: trunk_id};

    const showAddPrefixButton = !isSupervisorSms || isSupervisorSms && checkPermissionsFor(SMS_SUPPLIER_PREFIX_CREATE_METHOD);
    const showDeletePrefixesButton = !isSupervisorSms || isSupervisorSms && checkPermissionsFor(SMS_SUPPLIER_PREFIX_REMOVE_LIST_METHOD);
    const showModifyPrefixesButton = !isSupervisorSms || isSupervisorSms && checkPermissionsFor(SMS_SUPPLIER_PREFIX_MODIFY_LIST_METHOD);
    const showActivePrefixesButton = !isSupervisorSms || isSupervisorSms && checkPermissionsFor(SMS_SUPPLIER_PREFIX_SET_ACTIVE_METHOD);
    const showAllocateTrunkButton = !isSupervisorSms || isSupervisorSms && checkPermissionsFor(SMS_ALLOCATION_TEMPLATE_API);

    const showChangeRateNumbersButton = !isSupervisorSms || isSupervisorSms && checkPermissionsFor(SMS_TRUNK_NUMBER_CHANGE_RATE_API);
    const showRevokeNumbersButton = !isSupervisorSms || isSupervisorSms && checkPermissionsFor(SMS_TRUNK_NUMBER_REVOKE_API);
    const showMoveToOtherTrunkButton = !isSupervisorSms || isSupervisorSms && checkPermissionsFor(SMS_TRUNK_NUMBER_MOVE_TO_TRUNK_API);
    

    const numberActions = [
        ...appendIf(showChangeRateNumbersButton, {
            label: "Change rate",
            handler: () => handleOpenChangeRateModal()
        }),
        ...appendIf(showRevokeNumbersButton,{
            label: "Revoke numbers",
            handler: () => setShowRevokeModal(true),
            // hidden: true,
        }),
        ...appendIf(showMoveToOtherTrunkButton,{
            label: "Move to another trunk",
            handler: () => setShowMoveModal(true)
        }),
        ...(service ? [{
            label: "Change billing type",
            handler: () => setShowChangeBTModal(true)
        }, {
            label: "Set IVR",
            handler: () => setShowIvrModal(true)
        }
        ] : []),
    ];

    const prefixActions = [
        ...appendIf(!isTransitTrunks, {
            label: "Set limit",
            handler: () => handleOpenSetLimitModal()
        }),
        ...appendIf(showModifyPrefixesButton, {
            label: "Change rate",
            handler: () => handleOpenChangeSupplierRateModal()
        }),
        ...appendIf(showActivePrefixesButton, {
            label: "Activate",
            handler: () => handleOpenSetActiveModal(true),
        }),
        ...appendIf(showActivePrefixesButton, {
            label: "Deactivate",
            handler: () => handleOpenSetActiveModal(false),
        }),
        ...appendIf(showDeletePrefixesButton, {
            label: "Delete prefixes",
            handler: () => handleOpenDeletePrefixesModal()
        }),
    ];
    // refactor after ATX-2853
    const filterNode = (
        <Filters
            ref={filterRef}
            onChange={onChangeFilters}
            proxy={proxyFilters}
            disabled={searchLoading || loading}
            filters={filterState}
            isMobile={isMobile}
            currentDisplay={currentDisplay}
            currentRangeNumber={currentRangeNumber}
            onChangeGroup={onChangeGroup}

            isGanTrunks={isGanTrunks}
            isTransitTrunks={isTransitTrunks}

            downloadLoading={isDownloadInProgress}
            downloadDisabled={currentDisplay !== "trunk_number" || !trunk_id || isDownloadInProgress}
            onDownloadNumbersCsv={handleDownloadNumbersCsv}
            onDownloadOnlyNumbersCsv={handleDownloadOnlyNumbers}
        />
    );

    const currentActions = isTransitTrunks ? prefixActions : numberActions;

    const ganNumbersExtraParams = {
        target: {
            "sms.supplier_trunk_id": trunk_id
        },
        "sms.supplier_gan_key_list": !selected.all ? selected.list : undefined
    };

    const currentGroupData = {};

    const groupActionCell = ActionCell(
        onEditGroup,
        onDownloadNumbers,
        onRemoveGroup,
        onUploadNumbers
    );

    const filteredTableColumns = tableColumns && tableColumns.length ? tableColumns.filter(el => "displayWhen" in el 
        ? el.displayWhen.indexOf(currentDisplay) !== -1
        : true) : [];

    return (<div>
        {isGanTrunks ? 
            <RadioGroup
                inline
                onChange={onChangeGroup}
                defaultValue={"numbers"}
                name="radioList"
            >
                <Radio value="numbers" disabled={loading}>Numbers</Radio>
                <Radio value="groups" disabled={loading}>Groups</Radio>
            </RadioGroup>
            : <></>
        }

        {!isTransitTrunks && !isMobile ? filterNode : <></>}

        <S.Grid align={"middle"} justify={"space-between"}>

            {!isMobile && !isTransitTrunks ?
                !isGanTrunks && <S.GridItem>
                    <RadioGroup
                        inline
                        onChange={onChangeGroup}
                        defaultValue={"trunk_number"}
                        name="radioList"
                    >
                        <Radio value="trunk_number" disabled={loading}>Numbers</Radio>
                        <Radio value="price_range" disabled={loading}>Ranges</Radio>
                        <Radio value="subdestination" disabled={loading}>Subdestinations</Radio>
                    </RadioGroup>
                </S.GridItem>
            : <S.GridItem>
                {filterNode}
            </S.GridItem>}

            {
                ["numbers", "groups"].includes(currentDisplay)
                    ? <Header className="ml-2">{currentDisplay === "numbers" ? "GAN Numbers" : "GAN Groups"}</Header>
                    : <></>
            }

            <S.GridItem>
                <S.Grid>
                    {!isMobile && ((isTransitTrunks && customRangesTrunk) || !isTransitTrunks && !isGanTrunks) &&
                        <S.GridItem>
                            <NumbersDownloadDropdown
                                // isGanTrunks={isGanTrunks}
                                isTransitTrunks={isTransitTrunks}
                                account={account}
                                loading={isDownloadInProgress}
                                disabled={currentDisplay !== "trunk_number" || !trunk_id || isDownloadInProgress}
                                onDownloadNumbersCsv={handleDownloadNumbersCsv}
                                onDownloadOnlyNumbersCsv={handleDownloadOnlyNumbers}
                            />
                        </S.GridItem>
                    }
                    
                    {!isMobile && isGanTrunks &&
                    <S.GridItem>
                        <GanNumbersActionList
                            onSuccess={ganNumberSuccess}
                            {...{
                                currentDisplay,
                                selectedItems,
                                selected,
                                count: dataCount,
                                service,
                                extraParams: ganNumbersExtraParams,
                                currentGroupData,
                                hasSelected,
                                hasBlocked: hasGanBlocked,
                                handleSuccess: ganNumberSuccess,
                                permissions,
                                isSupervisorSms,
                                showReasonHandler,
                            }}

                            setShowLimit={() => {
                                setShowSetLimitModal(true)
                            }}
                        />
                    </S.GridItem>
                    }

                    {!isTransitTrunks && !isGanTrunks && showAllocateTrunkButton && 
                        <S.GridItem>
                            <AllocatedNumbersModal
                                hideBtn={isMobile}
                                account={account}
                                forsed_open={showAllocatedNumbersModal}
                                onClose={closeAllocatedNumbersModal}
                                activeTrunk={currentTrunk}
                                trunk_id={trunk_id}
                                service={service}
                                sp_key={sp_key}
                                sp_auth={sp_auth}
                                spOtpType={spOtpType}
                                update={updateItems}
                                account_id={acc_key}
                                disabled={readonly || currentTrunk && currentTrunk?.incorporated}
                                // disabled={true}
                                {...{
                                    getTrunkNumberCsvById
                                }}
                            />
                        </S.GridItem>
                    }

                    {customRangesTrunk && isTransitTrunks && showAddPrefixButton && <S.GridItem>
                        <AddNewPrefixesModal
                            // forsed_open={showAllocatedNumbersModal}
                            // onClose={() => this.setState({showRevokeModal: false})}
                            currentTrunk={currentTrunk}
                            account_id={acc_key}
                            trunk_id={trunk_id}
                            update={() => {
                                getTrunks(true);
                                updateItems();
                            }}
                            disabled={readonly || !currentTrunk}
                            suppliersPrefixDropdownList={suppliersPrefixDropdownList}
                            getSupplierPrefixesDropdown={getSupplierPrefixesDropdown}
                        />
                    </S.GridItem>}

                </S.Grid>
            </S.GridItem>

        </S.Grid>

        <TableServerSort
            shouldUpdateScroll={false}
            ref={tableRef}
            data={dataList}
            height="50%"
            loading={dataLoading}
            columns={filteredTableColumns}
            originalColumns={filteredTableColumns}
            rowClassName={(rowData) => {
                if (rowData) {
                    const {active} = rowData;

                    if (Object.keys(rowData).includes("active") && !active) {
                        return "table-row-prefix--disabled";
                    }
                }
            }}
            count={dataCount}
            per_page={dataPerPage}
            perPageMaximum={DEFAULT_PER_PAGE_MAX}
            page={dataPage}
        
            getItems={getNumberList}
            row_key={getRowKey()}
            
            disabled={!trunk_id}
            ispagination={currentDisplay !== "groups"}

            onChangePage={(value) => {
                setNumberPageState(value);
            }}

            // onChangePerPage={(value) => {
            //     setNumberPerPageState(value);
            // }}
    
            onSort={(column, type) => setSort({column, type})}
            isMobile={isMobile}
            
            {...(currentDisplay === "groups" ? {
                ActionCell: groupActionCell
            } : {
                isselected: isselected,
                isSelectedAll: isselected,
                useSelectedData: keepSelectedItems,
                setSelected: updateSelectedList
            })}
            
        />

        {!isGanTrunks && <FooterActions>
            <NumbersActions
                selected={selected}
                actions={currentActions}
                disabled={!hasSelected || footerActionsDisabled}
                loading={footerActionsLoading}
                disabledItemValues={disabledActions}
            />
        </FooterActions>}

        {showChangeRateModal &&
            <ChangeRateModal
                service={service}
                subDestinationsList={currentSubDestinationsList}
                target={responsiveTarget}
                targetPrice={{account_id: acc_key}}
                initialValues={getInitialValues()}
                sp_key={sp_key}
                account_name={account_name}
                update={() => {
                    getTrunks(true);
                    updateItems()
                }}
                activeTrunk={currentTrunk}
                getData={getDataForChangeRate}
                onClose={() => {
                    setShowChangeRateModal(false);
                    setCurrentSubDestinationsList([])
                }}
            />
        }

        {showChangeSupplierRateModal &&
            <ChangeSupplierRateModal
                // target={responsiveTarget}
                trunk_id={trunk_id}
                targetPrice={{account_id: acc_key}}
                initialValues={getInitialValues()}
                prefixKeyList={selectedItems}
                sp_key={sp_key}
                account_name={account_name}
                update={() => {
                    getTrunks(true);
                    updateItems()
                }}
                getData={getDataForChangeSupplierRate}
                onClose={() => {
                    setShowChangeSupplierRateModal(false);
                    setFooterActionsDisabled(false);
                    setFooterActionsLoading(false);
                }}
            />
        }

        {showRevokeModal &&
            <RevokeNumbersModal
                service={service}
                target={responsiveTarget}
                initialValues={getInitialValues()}
                getCount={getSelectedNumbersCount}
                update={() => {
                    getTrunks(true);
                    updateItems()
                }}
                onClose={() => setShowRevokeModal(false)}
            />
        }

        {showMoveModal &&
            <MoveNumbersModal
                service={service}
                target={responsiveTarget}
                trunks={trunks.filter((trunk) => canIMoveNumbersToAnyTrunk(currentTrunk, trunk))}
                initialValues={getInitialValues()}
                getCount={getSelectedNumbersCount}
                update={() => {
                    getTrunks(true);
                    updateItems();
                }}
                onClose={() => setShowMoveModal(false)}
            />
        }

        {showChangeBTModal &&
            <ChangeBillingTypeModal
                service={service}
                target={{trunk_id}}
                raterc_list={raterc_list}
                initialValues={getInitialValues()}
                getCount={getSelectedNumbersCount}
                update={() => {
                    getTrunks(true);
                    updateItems();
                }}
                onClose={() => setShowChangeBTModal(false)}
            />
        }
        {showIvrModal &&
            <SetIvrModal
                service={service}
                target={responsiveTarget}
                ivr_list={ivr_list}
                initialValues={getInitialValues()}
                allocatedNumbers={allocatedNumbers}
                getCount={getSelectedNumbersCount}
                update={() => {
                    getTrunks(true);
                    updateItems();
                }}
                onClose={() => setShowIvrModal(false)}
            />
        }

        {showDeletePrefixesModal && 
            <DeletePrefixesModal
                show={showDeletePrefixesModal}
                initialValues={getInitialValues()}
                prefixKeyList={selectedItems}
                trunk_id={trunk_id}
                update={() => {
                    getTrunks(true);
                    updateItems();
                }}
                onClose={() => {
                    setFooterActionsDisabled(false);
                    setFooterActionsLoading(false);
                    setShowDeletePrefixesModal(false);
                }}
            />
        }

        {showSetActiveModal && 
            <SetActiveModal
                show={showSetActiveModal}
                initialValues={getInitialValues()}
                prefixKeyList={selectedItems}
                trunk_id={trunk_id}
                update={() => {
                    getTrunks(true);
                    updateItems();
                }}
                onClose={() => {
                    setFooterActionsDisabled(false);
                    setFooterActionsLoading(false);
                    setShowSetActiveModal(false);
                }}
                status={activeStatus}
            />
        }

        {showSetLimitModal && 
            <SetLimitModal
                prefixList={suppliersPrefixList}
                createTrafficLimit={createTrafficLimit}
                modifyTrafficLimit={modifyTrafficLimit}
                removeTrafficLimit={removeTrafficLimit}
                initialValues={getInitialValues()}
                getCount={getSelectedNumbersCount}
                prefixKeyList={selectedItems}
                supplierId={currentSupplier?.supplier_id}
                getSupplierPrefixes={getSupplierPrefixes}
                trunkId={trunk_id}
                update={() => {
                    // getTrunks(true);
                    updateItems();
                }}
                updateCurrentPrefixes={updateCurrentPrefixes}
                
                isGanNumbers={isGanTrunks && currentDisplay==="numbers"}

                onClose={() => {
                    setFooterActionsDisabled(false);
                    setFooterActionsLoading(false);
                    setShowSetLimitModal(false);
                    // setTrafficsAllTheSame(false);

                    tableRef.current.clear();
                    _selectedList.clear();
                    clearSelectedNumbers();
                }}
            />
        }

        {showModifyGroupModal && 
            <ModifyGroupModal
                show={showModifyGroupModal}
                selectedItems={selectedGroupData}
                currentGroupData={selectedGroupData}
                service={service}
                currencyList={currencyList}
                paymentTermsList={paymentTermsList}
                onSuccess={() => {
                    Alert.success(`GAN Group ${selectedGroupData?.name || ""} was modified`);

                    updateItems();
                    setShowModifyGroupModal(false);
                }}
                onClose={() => {
                    setShowModifyGroupModal(false);
                }}
            />
        }
        {showUploadGanNumbersModal &&
            <UploadGanNumbersModal
                show={showUploadGanNumbersModal}
                service={service}
                isUploadToGroup
                selectedItems={selectedGroupData}
                onSuccess={() => {
                    updateItems();
                    setShowUploadGanNumbersModal(false);
                }}
                onClose={() => {
                    setShowUploadGanNumbersModal(false);
                }}
            />
        }

        {showRemoveGroupModal &&
            <RemoveGroupModal
                show={showRemoveGroupModal}
                selectedItems={selectedGroupData}
                onClose={() => {
                    setShowRemoveGroupModal(false);
                }}
                onSuccess={() => {
                    updateItems();
                    setShowRemoveGroupModal(false);
                    
                    Alert.success("The GAN group was deleted");
                }}
                showReasonHandler={showReasonHandler}
            />
        }

    </div>);
};

export default AllocatedNumbers;


const ActionCell = (edit, download, remove, uploadPrefix) => (
    <Column width={138}>
        <HeaderCell>Options</HeaderCell>
        <Cell>
            {rowData => (
                <StyledActionCell>
                    
                    {edit && <ButtonRowEdit 
                        mr={8}
                        onClick={() => edit(rowData)}
                    />}
                    
                    {download && <ButtonRowDownload 
                        mr={8}
                        loading={rowData.downloadLoading} 
                        onClick={() => download(rowData.id)}
                    />}
                    
                    {uploadPrefix && <ButtonRowUpload
                        mr={8}
                        onClick={() => uploadPrefix(rowData)}
                    />}

                    {remove && <ButtonRowDelete 
                        mr={0}
                        onClick={() => remove(rowData)}
                    />}

                </StyledActionCell>
            )}
        </Cell>
    </Column>
);

const StyledActionCell = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
`;

const SecondStagedCell = styled.div`
    && {
        display: flex;
        height: 40px;
        flex-direction: column;
        font-size: 12px;
        margin-top: -4px;
        line-height: 1.2;
        margin-left: 4px;
    }
`;