import React, {useEffect, useCallback, useMemo} from "react";
import ModalSendApi from "components/Modal/ModalSendApi";
import ApiRequest  from "components/ApiRequest";
import {Icon, Tag, Tree, Alert} from "rsuite";
import {api} from "api/loginRoutes";
import Loader from "components/Loader/Loader";
import ModalResult from "./ModalResult";
import {
    PRICE_RANGE_DELETE_API,
    PRICE_RANGE_DELETE_NOT_ALLOCATED_API,
    PRICE_RANGE_GET_LIST_WITH_ACCOUNT_TRUNK_NUMBER_LIST_API,
    SMS_PRICE_RANGE_GET_LIST_WITH_ACCOUNT_TRUNK_NUMBER_LIST_API
} from "const/apiMethods";
import {ButtonClose as Delete} from "../../components/base/BaseButton";
import { getServiceSmsPrefix, servicePick } from "utils";

export default ({
    service,
    getList,
    disabled,
    permissionMethods,
    update
}) => {

    const [show, setShow] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [rangesToDelete, setRangesToDelete] = React.useState([]);

    const [showModalFaultResult, setShowModalFaultResult] = React.useState(false);
    const [modalFaultResultData, setModalFaultResultData] = React.useState({});

    const apiMethod = useMemo(() => (
        permissionMethods.length && permissionMethods.includes(PRICE_RANGE_DELETE_API)
            ? getServiceSmsPrefix(service, PRICE_RANGE_DELETE_API)
            : PRICE_RANGE_DELETE_NOT_ALLOCATED_API
    ), [permissionMethods]);

    useEffect(() => {
        if (show) {
            const keys = getList().list
                .map((range) => range.pr_key);

            setLoading(true);

            api(servicePick(service, PRICE_RANGE_GET_LIST_WITH_ACCOUNT_TRUNK_NUMBER_LIST_API, SMS_PRICE_RANGE_GET_LIST_WITH_ACCOUNT_TRUNK_NUMBER_LIST_API), {[getServiceSmsPrefix(service, "pr_key_list")]: keys})
                .then(({price_range_list}) => {

                    const tree = price_range_list.map((range) => {

                        const trunks = range.dialer_allocated_numbers_list.map((dialer) => ({
                            value: dialer.trunk_name,
                            label: <div>
                                <div className="float-left">
                                    Dialer: {dialer.account_name}
                                </div>
                                <div className="float-right">
                                    <Tag color="green">{dialer.numbers} numbers</Tag>
                                </div>
                            </div>
                        }));

                        return {
                            children: trunks.length ? trunks : null,
                            value: range.id,
                            label: <div
                                data-range-id={range.id}
                                onClick={() => {
                                    changeExpandTree(range.id);
                                }}
                            >
                                <div className="float-left">
                                    {/*{range.inc_allocated_numbers*/}
                                    {/*    ? <Icon style={{color: "#f44336", marginRight: 5}} icon="exclamation-triangle"/>*/}
                                    {/*    : null*/}
                                    {/*}*/}
                                    Range: {range.name}
                                </div>
                                <div className="float-right">
                                    <Tag color="orange">{range.dialer_allocated_numbers_list.length} dialers</Tag>
                                    <Tag color="green">{range.all_numbers} numbers</Tag>
                                </div>
                            </div>,
                        }

                    });

                    setRangesToDelete(tree);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }, [show]);

    const changeExpandTree = (rangeId) => {
        const node =  document.getElementsByClassName("rs-tree-node-children");
        if (node && node.length) {
            for (const treeNode of node) {
                const icon = treeNode.getElementsByClassName("rs-tree-node-expand-icon");
                const iconNode = icon && icon.length && icon[0];
                const nodeByRangeId = treeNode.querySelector(`[data-range-id="${rangeId}"]`);
                if (treeNode && nodeByRangeId) {
                    if (treeNode.classList.contains("rs-tree-open")) {
                        treeNode.classList.remove("rs-tree-open");
                        iconNode.classList.remove("rs-tree-node-expanded");
                    } else {
                        treeNode.classList.add("rs-tree-open");
                        iconNode.classList.add("rs-tree-node-expanded");
                    }
                }
            }
        }
    };

    const onFetchResult = useCallback( (result) => {
        setShow(false);

        if (!result?.error) {
            setModalFaultResultData(result);
            setShowModalFaultResult(true);
        }
    }, [] );

    return (
        <>
            <Delete
                disabled={disabled}
                onClick={() => setShow(true)}
            >
                - Delete ranges
            </Delete>

            {show &&

                <ModalSendApi
                    title="Delete Range"
                    successText="Confirm"
                    onClose={() => setShow(false)}
                    checkBeforeSubmit={() => {
                        if (getList().list.find(item => item.inc_allocated_numbers)) {
                            Alert.error("Some ranges has allocated global access numbers, and can not be removed");
                            return false;
                        }
                        return true;
                    }}
                    update={update}
                >

                    <ApiRequest
                        method={apiMethod}
                        checkResultKey = "ranges"
                        onFaultResult={(result) => {
                            onFetchResult(result);
                            update();
                        }}
                        update={onFetchResult}
                        data={{[getServiceSmsPrefix(service, "pr_key_list")]: getList().list.map(x => x.pr_key)}}
                        // style={{maxHeight: "200px"}}
                    >
                        <Loader show={loading} isLocal/>
                        <h6 className="text-center"> Selected range allocated to following dialers</h6>
                        <Tree data={rangesToDelete} />
                        <h6 className="text-center">All numbers will be revoked.</h6>
                    </ApiRequest>

                </ModalSendApi>
            }

            <ModalResult
                show={showModalFaultResult}
                data={modalFaultResultData}
                onClose={() => setShowModalFaultResult(false)}
            />

        </>

    );
}