/* 
Component name: Table Edit Cells
Product: ATX
Author: Applesinkin

Please, do not change it. You can fork it to make some changes...
*/


import React, {useState, useEffect, useRef} from "react";
import {Button, FormControl, Icon, Table, Form} from "rsuite";
import styled from "styled-components";
import BaseTable from "../../../components/base/BaseTable";
import ModalSubmitRowApprove from "./ModalSubmitRowApprove";
import ModalRemoveRow from "./ModalRemoveRow";
import FormCreateRowNew from "./FormCreateRowNew";
import {Spacer} from "../../base/Spacer";
import {isNumber} from "utils/helpers";

const {Cell, Column, HeaderCell} = Table;


export default (
    {
        rowIdKey = "id",
        defaultRowIdKey = null,
        data = [],
        loading,
        columns,
        editable = true,
        removeExtended = false,
        showFormCreate = true,
        modalRemoveTitle = "Remove item",

        canRemove = true,
        canCreate = true,
        canModify = true,

        customButton,

        onRowSubmit,
        onRowCreate,
        onRowRemove,
        onDataUpdate,
        checkParamsBeforeModify,

        formModel,
        mobile = false,

        setEditKeys = null,
        extraData = {},
        changeExtraData = null,
        createFormDefaultValue = null,

        createExtraData = {},

        addButtonWidth,
        onChangeServiceName,

        defaultDisableKey = '',

        createPermission = true,
        modifyPermission = true,
        removePermission = true,
        formStyle = {},

        ...props
    }
) => {
    const refs = useRef( new Map() );

    const [editableRows, setEditableRows] = useState( new Map() );
    const [loadableRows, setLoadableRows] = useState([]);

    const [defaultKey, setDefaultKey] = useState(rowIdKey);

    // modal remove
    const [modalRemoveData, setModalRemoveData] = useState(null);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [removeDataLoading, setRemoveDataLoading] = useState(null);

    // modal approve
    const [modalApproveData, setModalApproveData] = useState(false);
    const [showModalApprove, setShowModalApprove] = useState(false);
    const [modalApproveLoading, setModalApproveLoading] = useState(null);

    const [formStateValue, setFormStateValue] = useState({});

    // on data updated
    useEffect(() => {
        if (!editable) {
            return;
        }

        const editableRowsCopy = new Map(editableRows);
        const loadableRowsCopy = new Set(loadableRows);
        for (const editedRowData of editableRows) {
            const rowData = data.find(item => {
                return item[defaultKey] === editedRowData[0];
            });
            
            if (rowData && JSON.stringify(rowData) !== JSON.stringify(editedRowData[1])) {
                editableRowsCopy.delete(rowData[defaultKey]);
                loadableRowsCopy.delete(rowData[defaultKey]);
            }
        }
        setEditableRows(editableRowsCopy);
        setLoadableRows(Array.from(loadableRowsCopy));

        const dataKeyList = data.map(({[defaultKey]: key}) => key);

        if (dataKeyList.some((key) => !Object.keys(formStateValue).includes(key))) {
            setFormStateValue(data.reduce((result, current) => {
                if (current[defaultKey]) {
                    const {[defaultKey]: id, ...value} = current;
                    result[id] = {...value, [defaultKey]: id};
                }

                return result;
            }, {}));
        }
    }, [data, editable]);

    
    useEffect(() => {
        const specialColumns = columns.filter((column) => !!column.fixTextareaValue)
            .map(({dataKey}) => dataKey);

        Array.from(refs.current).forEach(([id, value]) => {
            const stringId = isNumber(id) ? id.toString() : id;
            const currentValues = formStateValue[stringId];

            if (currentValues) {
                Object.keys(currentValues).forEach((key) => {
                    if (specialColumns.includes(key)) {
                        const currentRef = value[key];

                        currentRef.check();
                    }
                })
            }
    
        })
    }, [refs, formStateValue])


    // on row submit
    const handleSubmitRow = async (rowData) => {
        // check form
        const rowForms = refs.current.get(rowData[defaultKey]);
        if (!rowForms) {
            setEditableRow(rowData, false);
            return;
        }
        for ( const form of Object.keys(rowForms) ) {
            if (!rowForms[form].check()) {
                return;
            }
        }

        // get params
        const params = Object.keys(rowForms)
            .reduce( (sum, current) => (
                {
                    ...sum,
                    [current]: rowForms[current].getFormValue()[current]
                }
            ), {[defaultKey]: rowData[defaultKey]} );

        // check differences
        const isChanged = Object.keys(params)
            .reduce( (sum, current) => {
                if (sum === true)
                    return sum;

                const value = ( rowData[current] !== null && !isNaN(rowData[current]) )
                    ? +params[current]
                    : params[current];
                return rowData[current] !== value;
            }, false );

        if (!isChanged) {
            setEditableRow(rowData, false);
            setLoadableRow(rowData, false);
            return;
        }

        // Approve modal
        if (checkParamsBeforeModify) {
            const notApproved = checkParamsBeforeModify(params);
  
            if (notApproved) {
                setShowModalApprove(true);
                setModalApproveData(params);
                return;
            }
        }

        if (!onRowSubmit) {
            return;
        }

        // approve modal with text and condition and on submit, remove loading when open. Modal is hear
        // optimize refs array. share callbacks (row edit loading) in function except calss component
        setLoadableRow(rowData, true);
        const data = {
            ...params,
            ...extraData[rowData.id]
        };

        const res = await onRowSubmit(data, rowData);
        setLoadableRow(rowData, false);

        if (!res) {
            return;
        }

        setEditableRow(rowData, false);

        if (onDataUpdate) {
            onDataUpdate();
        }
    };


    // check before submit
    const handleApprovedSubmit = async () => {
        if (!onRowSubmit) {
            return;
        }
        setModalApproveLoading(true);
        await onRowSubmit(modalApproveData);
        setModalApproveLoading(false);
        setShowModalApprove(false);
        
        if (onDataUpdate) {
            onDataUpdate();
        }
    };


    // on row delete
    const handleDeleteRow = async () => {
        if (!onRowRemove) {
            return;
        }
        setRemoveDataLoading(true);

        let removeData = modalRemoveData;

        let defaultKeyValue = rowIdKey;
        if (removeData && defaultDisableKey && !!removeData[defaultDisableKey]) {
            defaultKeyValue = defaultRowIdKey;
            setDefaultKey(defaultKeyValue);
        } else {
            setDefaultKey(rowIdKey);
        }

        if (!removeExtended) {
            removeData = modalRemoveData[defaultKeyValue];
        }

        const res = await onRowRemove(removeData);

        if (res) {
            setRemoveDataLoading(false);
            setShowRemoveModal(false);
            setModalRemoveData(null);
            
            if (onDataUpdate) {
                onDataUpdate();
            }
        }
    };


    const setEditableRow = (rowData, status) => {
        if (rowData.default && rowData.onRowSubmitDefault) {
            rowData.onRowSubmitDefault();
            return;
        }

        let defaultKeyValue = rowIdKey;

        if (rowData && defaultDisableKey && !!rowData[defaultDisableKey]) {
            defaultKeyValue = defaultRowIdKey;
            setDefaultKey(defaultKeyValue);
        } else {
            setDefaultKey(rowIdKey);
        }
        const editableRowsCopy = new Map(editableRows);

        if ( editableRows.has(rowData[defaultKeyValue]) && !status) {
            editableRowsCopy.delete(rowData[defaultKeyValue]);
        } else if (!editableRows.has(rowData[defaultKeyValue]) && status) {
            editableRowsCopy.set(rowData[defaultKeyValue], rowData)
        }
        setEditableRows(editableRowsCopy);
        
        if (setEditKeys)
            setEditKeys(Array.from(editableRowsCopy.keys()));
    };


    const setLoadableRow = (rowData, status) => {
        if (!status) {
            setLoadableRows( loadableRows.filter(item => item !== rowData[defaultKey]) );
            return;
        }
        setLoadableRows([...loadableRows, rowData[defaultKey]]);
    };

    return (
        <>
            <StyledTable
                data={data}
                loading={loading}
                className={'tableFilters'}
                shouldUpdateScroll={false}
                headerHeight={46}
                rowHeight={46}
                autoHeight
                {...props}
            >


                {/* Row Cells */}

                {columns.map( ({
                    dataKey, fieldName, name = "", value, defaultValue, model, title = null,
                    flexGrow = null, minWidth = 130, width, disabled, fixTextareaValue, 
                    customData = false, modifyData = [], ...formControlProps
                }) => {
                    return (<Column
                        {...{
                            flexGrow, 
                            minWidth, 
                            width
                        }}>

                        <HeaderCell>{name}</HeaderCell>

                        <Cell {...{dataKey}}>
                            {(rowData) => {

                                const id = rowData[defaultKey];
                                const stringId = isNumber(id) ? id.toString() : id;
                                const disabledBeforeChangeValue = !editableRows.has(id) && formControlProps?.disabledBeforeChange;

                                // plane value
                                if ( !editableRows.has(id) || !formControlProps.accepter ) {
                                    const cellValue = (value && value(rowData)) || rowData[dataKey];

                                    return (
                                        <span
                                            className="tableFilters__previewText"
                                            title={title ? cellValue : null}
                                        >
                                            {cellValue}
                                        </span>
                                    )
                                }

                                // form value
                                const formControlName = fieldName || dataKey;

                                const formDefaultValue = defaultValue
                                    ? {[formControlName]: defaultValue(rowData)}
                                    : {[formControlName]: rowData[dataKey]};

                                const currentFormValue = Object.keys(formStateValue).includes(stringId) ? formStateValue[stringId] : formDefaultValue;

                                return (
                                    <Form
                                        model={model}
                                        formDefaultValue={formDefaultValue}
                                        formValue={currentFormValue}
                                        ref={ref => {

                                            if (!ref) {
                                                return;
                                            }

                                            if (refs.current.has(id)) {
                                                const rowRefs = refs.current.get(id);
                                                refs.current.set(id, {
                                                    ...rowRefs,
                                                    [formControlName]: ref
                                                });
                                                return;
                                            }

                                            refs.current.set(id, {[formControlName]: ref});
                                        }}
                                        onChange={(formValue) => {
                                            const formFormattedValue = {
                                                ...(fixTextareaValue ? {
                                                    [formControlName]: formValue[formControlName].target.value
                                                } : {
                                                    [formControlName]: formValue[formControlName]
                                                })
                                            };

                                            setFormStateValue((currentState) => {
                                                return {
                                                    ...currentState,
                                                    [id]: {
                                                        ...currentState[id],
                                                        ...formFormattedValue
                                                    }
                                                };
                                            });
                                        }}
                                        disabled={disabledBeforeChangeValue}
                                    >
                                        {!formControlProps?.customField ?
                                            <EditField
                                                name={formControlName}
                                                defaultValue={formDefaultValue}
                                                
                                                {...formControlProps}
                                                {...(customData ? {
                                                    data: modifyData
                                                } : {})}

                                                disabled={formControlProps?.disabled || formControlProps?.disabledOnEdit}
                                            />
                                        : (value && value(rowData)) || rowData[dataKey]}
                                    </Form>
                                );
                            }}
                        </Cell>
                    </Column>)
                })}



                {/* Row Actions */}
                {editable &&
                    <Column width={addButtonWidth ? addButtonWidth : 174}>
                        <HeaderCell></HeaderCell>
                        <Cell>
                            {rowData => {


                                const showCustomButton = rowData?.showCustomButton;
                                const rowLoading = loadableRows.includes(rowData[defaultKey]);
                                const disabledByDefault = defaultDisableKey && defaultDisableKey in rowData && !!rowData[defaultDisableKey];

                                return <div className="tableFilters_buttons">
                                    {canModify && !showCustomButton &&
                                        <>
                                            {!editableRows.has(rowData[defaultKey])
                                                ? <Button
                                                    size="sm"
                                                    color="blue"
                                                    disabled={rowLoading}
                                                    onClick={() => setEditableRow(rowData, true)}
                                                >
                                                    <Icon icon="edit2"/>
                                                </Button>
                                                : <>
                                                    <Button
                                                        size="sm"
                                                        color="green"
                                                        disabled={rowLoading || !modifyPermission}
                                                        onClick={() => handleSubmitRow(rowData)}
                                                    >
                                                        <Icon icon="check-circle"/>
                                                    </Button>

                                                    <Button
                                                        size="sm"
                                                        color="red"
                                                        disabled={rowLoading}
                                                        onClick={() => {
                                                            if (changeExtraData) {
                                                                changeExtraData((prev) => {
                                                                    const prevClone = {...prev};
                                                                    prevClone[rowData.id] = {...rowData};
                                                                    return prevClone;
                                                                });
                                                            }
                                                            setEditableRow(rowData, false);
                                                        }}
                                                    >
                                                        <Icon icon="close-circle"/>
                                                    </Button>
                                                </>
                                            }
                                        </>}
                                    {showCustomButton && <>
                                           {customButton(rowData?.customButtonText)}
                                        </>
                                    }

                                    {canRemove && !showCustomButton &&
                                        <Button
                                            size="sm"
                                            color="red"
                                            disabled={rowLoading || disabledByDefault || !removePermission || rowData.default || rowData.undeletable}
                                            onClick={() => {
                                                setModalRemoveData(rowData);
                                                setShowRemoveModal(true);
                                            }}
                                        >
                                            <Icon icon="trash2"/>
                                        </Button>
                                    }
                                </div>
                            }}
                        </Cell>
                    </Column>
                }

            </StyledTable>

            {showFormCreate && canCreate &&
                <>
                    <Spacer />
                    <FormCreateRowNew
                        columns={columns}
                        formModel={formModel}
                        onDataUpdate={onDataUpdate}
                        onSubmit={(params, defaultData) => {
                            const data = {
                                ...params,
                                ...createExtraData
                            };
                            return onRowCreate(data, defaultData);
                        }}
                        mobile={mobile}
                        addButtonWidth={addButtonWidth}
                        onChangeServiceName={onChangeServiceName}
                        formDefaultValue={createFormDefaultValue}
                        disabled={!createPermission}
                        formStyle={formStyle}
                    />
                </>
            }

            <ModalRemoveRow
                show={showRemoveModal}
                title={modalRemoveTitle}
                onSubmit={handleDeleteRow}
                onClose={() => setShowRemoveModal(false)}
                disabled={removeDataLoading}
            />

            <ModalSubmitRowApprove
                show={showModalApprove}
                onSubmit={handleApprovedSubmit}
                onClose={() => setShowModalApprove(false)}
                disabled={modalApproveLoading}
            />

        </>
    )
}

const StyledTable = styled(BaseTable)`
    && {
    
        .tableFilters__previewText {
            display: block;
            line-height: 20px;
            margin-top: 7px;
            word-break: normal;
        }
        
        .tableFilters_buttons {
            height: 20px;
            margin-top: 7px;
        }
    }

    .rs-table-cell-header .rs-table-cell-content {
        display: flex;
        align-items: center;
    }
`;

const EditField = styled(FormControl).attrs(() => ({
    className: "tableFilters_field",
    errorPlacement: "topEnd",
}))`
`;

