import React from "react";

import { FlexGrid, FlexGridItem } from "components/base/FlexGrid";
import {
    SMS_ALLOCATION_GAN_BY_MANAGER_API,
    SMS_SUPPLIER_GAN_REVOKE_LIST_METHOD,
    SMS_SUPPLIER_GAN_REMOVE_LIST_METHOD,
    SMS_SUPPLIER_GAN_REMOVE_LIST_ALLOCATED_METHOD,
    SMS_SUPPLIER_GAN_SET_TEST_NUMBER_LIST_METHOD,
    SMS_SUPPLIER_GAN_UNSET_TEST_NUMBER_LIST_METHOD,
    SMS_SUPPLIER_GAN_SET_BLOCK_ALLOCATION_LIST_METHOD,
    SMS_SUPPLIER_GAN_UNSET_BLOCK_ALLOCATION_LIST_METHOD
} from "const/apiMethods";
import Allocate from "../../../../IncorporatedNumbers/IncorporatedNumbersList/Allocate/Allocate";
import Block from "../../../../IncorporatedNumbers/IncorporatedNumbersList/Block";
import Remove from "../../../../IncorporatedNumbers/IncorporatedNumbersList/Remove";
import Revoke from "../../../../IncorporatedNumbers/IncorporatedNumbersList/Revoke";
import SetOrUnsetTest from "../../../../IncorporatedNumbers/IncorporatedNumbersList/SetOrUnsetTest";
import Unblock from "../../../../IncorporatedNumbers/IncorporatedNumbersList/Unblock";
import { checkPermissionsFor } from "store/storeHelpers";


export default (
    {
        selected,
        count,
        service,
        extraParams,
        onSuccess,
        hasSelected,
        hasBlocked,
        isSupervisorSms,
        permissions,
        currentDisplay,
        currentGroupData,
        showReasonHandler,

        setShowLimit
    }
) => {
    const checkKey = "sms.supplier_gan";

    const filterAllExtraParams = !!selected?.all ? {
        ...extraParams,
        filterAll: selected.all 
    } : extraParams;

    const allocateExtraParams = !!selected?.all ? {
        ...extraParams,
        numbers: null
    } : extraParams;

    const removeAllocatedMethod = permissions.includes(SMS_SUPPLIER_GAN_REMOVE_LIST_ALLOCATED_METHOD) 
        ? SMS_SUPPLIER_GAN_REMOVE_LIST_ALLOCATED_METHOD 
        : SMS_SUPPLIER_GAN_REMOVE_LIST_METHOD;

    const showRevokeButton = !isSupervisorSms || isSupervisorSms && checkPermissionsFor(SMS_SUPPLIER_GAN_REVOKE_LIST_METHOD);
    const showAllocateButton = !isSupervisorSms || isSupervisorSms && checkPermissionsFor(SMS_ALLOCATION_GAN_BY_MANAGER_API);
    const showSetTestButton = !isSupervisorSms || isSupervisorSms && checkPermissionsFor(SMS_SUPPLIER_GAN_SET_TEST_NUMBER_LIST_METHOD);
    const showUnsetTestButton = !isSupervisorSms || isSupervisorSms && checkPermissionsFor(SMS_SUPPLIER_GAN_UNSET_TEST_NUMBER_LIST_METHOD);
    const showBlockButton = !isSupervisorSms || isSupervisorSms && checkPermissionsFor(SMS_SUPPLIER_GAN_SET_BLOCK_ALLOCATION_LIST_METHOD);
    const showUnblockButton = !isSupervisorSms || isSupervisorSms && checkPermissionsFor(SMS_SUPPLIER_GAN_UNSET_BLOCK_ALLOCATION_LIST_METHOD);
    const showRemoveButton = !isSupervisorSms || isSupervisorSms && checkPermissionsFor(SMS_SUPPLIER_GAN_REMOVE_LIST_ALLOCATED_METHOD);
    
    return (
        <>
            {currentDisplay === "numbers" ?
                <FlexGrid>
                    {showRevokeButton && <FlexGridItem>
                        <Revoke
                            {...{
                                count,
                                checkKey,
                                selected,
                                extraParams: filterAllExtraParams
                            }}
                            disabled={!hasSelected}
                            onSuccess={onSuccess}
                            method={SMS_SUPPLIER_GAN_REVOKE_LIST_METHOD}
                        />
                    </FlexGridItem>}

                    {showAllocateButton && <FlexGridItem>
                        <Allocate
                            {...{
                                checkKey,
                                service,
                                selected,
                                currentGroupData,
                                extraParams: allocateExtraParams,
                                showReasonHandler
                            }}
                            isSupplier
                            disabled={!hasSelected || hasBlocked}
                            onSuccess={onSuccess}
                            method={SMS_ALLOCATION_GAN_BY_MANAGER_API}
                        />
                    </FlexGridItem>}
                
                    {showSetTestButton && <FlexGridItem>
                        <SetOrUnsetTest
                            {...{
                                checkKey,
                                selected,
                                extraParams: filterAllExtraParams
                            }}
                            disabled={!hasSelected || hasBlocked || selected.all}
                            onSuccess={onSuccess}
                            isSetTest={true}
                            methodSet={SMS_SUPPLIER_GAN_SET_TEST_NUMBER_LIST_METHOD}
                        />
                    </FlexGridItem>}
                
                    {showUnsetTestButton && <FlexGridItem>
                        <SetOrUnsetTest
                            {...{
                                checkKey,
                                selected,
                                extraParams: filterAllExtraParams
                            }}
                            disabled={!hasSelected || hasBlocked}
                            onSuccess={onSuccess}
                            isSetTest={false}
                            methodUnset={SMS_SUPPLIER_GAN_UNSET_TEST_NUMBER_LIST_METHOD}
                        />
                    </FlexGridItem>}

                    {showBlockButton && <FlexGridItem>
                        <Block
                            {...{
                                checkKey,
                                extraParams: filterAllExtraParams
                            }}
                            disabled={!hasSelected || hasBlocked || selected.all}
                            onSuccess={onSuccess}
                            method={SMS_SUPPLIER_GAN_SET_BLOCK_ALLOCATION_LIST_METHOD}
                        />
                    </FlexGridItem>}

                    {showUnblockButton && <FlexGridItem>
                        <Unblock
                            {...{
                                checkKey,
                                extraParams: filterAllExtraParams
                            }}
                            disabled={!hasSelected || (selected.list.length && !hasBlocked)}
                            onSuccess={onSuccess}
                            method={SMS_SUPPLIER_GAN_UNSET_BLOCK_ALLOCATION_LIST_METHOD}
                        />
                    </FlexGridItem>}

                    {showRemoveButton && <FlexGridItem>
                        <Remove
                            {...{
                                checkKey,
                                extraParams: filterAllExtraParams,
                                showReasonHandler,
                            }}
                            disabled={!hasSelected}
                            onSuccess={onSuccess}
                            method={removeAllocatedMethod}
                        />
                    </FlexGridItem>}
            </FlexGrid>
            : <></>}
        </>
    )
};